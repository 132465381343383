import { FC, useEffect, useState } from 'react'
import { Accordion, Box, Grid, Typography } from '@ntpkunity/controls'
import { AccordianBox, Stack, SummaryList } from '@components'
import { useAppContext } from '@app/context-provider'
import { calculateAdjustedTradeInAmount } from '../../helpers/methods'
import { PaymentBreakDownPopupWrap } from './payment-breakdown-popup.style'
import { useGetCustomerByEmail } from '@hooks'
import { getPaymentBreakdownText } from '../../helpers/methods'
import { useOrderContext } from 'pages'
import { LoaderPopup } from '@libraries'
import {FeeTypes} from "../../constants/enums";
import {FEE_MONTHLY_PAYMENT_BASED} from "../../constants/fee";
export const PaymentBreakDownGridContent: FC<{
	theme
	order_info
	default_currency_symbol
	sortedOptionsArray
}> = ({ theme, order_info, default_currency_symbol, sortedOptionsArray }) => {
	const { state: appState } = useAppContext()
	const {
		state: { order: orderState },
	} = useOrderContext()
	const PLACEHOLDERS = appState.language.placeholders
	const { data: customerData } = useGetCustomerByEmail(JSON.parse(localStorage.getItem('settings'))?.email)
	const [totalUpfrontFeeSum, setTotalUpfrontFeeSum] = useState(0)
	const [totalDispositionFeeSum, setTotalDispositionFeeSum] = useState(0)
	useEffect(() => {
		if (order_info?.order_fees) {
			const totalFees = order_info.order_fees
				.filter((fee) => fee.fee_handling === 'Upfront')
				.reduce((sum, fee) => sum + (fee.default_amount || fee.applied_price), 0)

			setTotalUpfrontFeeSum(totalFees)
			const totalDispositionFeeSum = order_info.order_fees
					.filter(fee => fee.event === FeeTypes.EOT && fee.is_active)
					.map((fee) => {
						if(fee.calculation_type === FEE_MONTHLY_PAYMENT_BASED) {
							const dealer_fee = order_info?.dealer_fees?.find(dealerFee => dealerFee.id === (fee.order_id ? fee.dealer_fee_id : fee.id))
							const dealerFeeAmount = dealer_fee?.default_amount ?? 0;
							const multipliedValue = (order_info?.estimated_monthly_payment || 0);
							const appliedValue = multipliedValue * fee.multiplication_factor;
							if (appliedValue < dealerFeeAmount) {
								fee.applied_price = appliedValue;
							} else {
								fee.applied_price = dealerFeeAmount;
							}
						}
						return fee
					})
					.reduce((sum, fee) => sum + (fee?.applied_price || 0), 0)
			setTotalDispositionFeeSum(totalDispositionFeeSum)
		}
	}, [order_info?.order_fees,order_info?.estimated_monthly_payment ])

	function addOnTotalPrice(order) {
		const total = order.reduce((sum, item) => {
			if ((item.installation_mode === 'Pre-Installed' && item.is_price_inclusive == false) || item.installation_mode === 'Optional') {
				return sum + item.applied_price
			} else {
				return sum
			}
		}, 0)

		return `${default_currency_symbol}${total.toLocaleString(undefined, {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		})}`
	}
	function addOnPrice(option) {
		const price = option?.applied_price?.toLocaleString(undefined, {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		})
		const formattedPrice = `${default_currency_symbol}${price}`
		if (option?.installation_mode === 'Pre-Installed' && option?.is_price_inclusive === false) {
			return <span>{formattedPrice}</span>
		}
		if (option?.installation_mode === 'Optional') {
			return <span>{formattedPrice}</span>
		}
		return <span>-</span>
	}
	if (!default_currency_symbol) {
		return <LoaderPopup />
	}
	return (
		<Grid theme={theme} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 3.5 }}>
			<Grid item xs={12} theme={theme}>
				<PaymentBreakDownPopupWrap theme={theme} className='payment-breakdown-popup-wrap'>
					<Stack bgLight paddingMd={3} dFlex justifyBetween>
						<Grid theme={theme} item xs={6} className='ellipsis-group'>
							<Box theme={theme} sx={{ textAlign: 'center' }}>
								<Typography theme={theme} variant='caption' className='text-muted text-ellipsis' component={'small'}>
									{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_MONTHLY_PAYMENT}
								</Typography>
								<Typography theme={theme} variant='h4' className='text-ellipsis' component={'h4'}>
									{order_info?.estimated_monthly_payment && order_info?.estimated_monthly_payment > 0 ? (
										<>
											{default_currency_symbol}
											{(order_info?.estimated_monthly_payment || 0).toLocaleString(undefined, {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2,
											})}
											{`/${PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_PER_MONTH}${PLACEHOLDERS.DOT_TEXT}`}
										</>
									) : (
										`${default_currency_symbol}0.00`
									)}
								</Typography>
							</Box>
						</Grid>
						<Box theme={theme} className='line'></Box>
						<Grid theme={theme} item xs={6} className='ellipsis-group'>
							<Box theme={theme} sx={{ textAlign: 'center' }}>
								<Typography theme={theme} variant='caption' className='text-muted text-ellipsis' component={'small'}>
									{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_DUE_AT_SIGNING}
								</Typography>
								<Typography theme={theme} variant='h4' className='text-ellipsis' component={'h4'}>
									<b>
										{order_info?.due_at_signing && order_info?.due_at_signing > 0
											? default_currency_symbol +
											  order_info?.due_at_signing?.toLocaleString(undefined, {
													maximumFractionDigits: 2,
													minimumFractionDigits: 2,
											  })
											: `${default_currency_symbol}0.00`}
									</b>
								</Typography>
							</Box>
						</Grid>
					</Stack>

					<AccordianBox>
						<Box theme={theme} sx={{ mt: 2 }}>
							<Accordion
								theme={theme}
								items={[
									{
										title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_DUE_AT_SIGNING,
										key: 'string2',
										subtitle: order_info?.due_at_signing
											? default_currency_symbol +
											  order_info?.due_at_signing?.toLocaleString(undefined, {
													maximumFractionDigits: 2,
													minimumFractionDigits: 2,
											  })
											: `${default_currency_symbol}0.00`,

										content: (
											<>
												<Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted' mt={2}>
													{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_ADDITIONAL_FEES}
												</Typography>
												<SummaryList
													options={[
														{
															title: PLACEHOLDERS.PAYMENT_DOWN_PAYMENT_FIELD_LABEL,
															subtitle: order_info?.down_payment
																? default_currency_symbol +
																  order_info?.down_payment?.toLocaleString(undefined, {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																  })
																: `${default_currency_symbol}0.00`,
														},
														{
															action: (
																<>
																	<AccordianBox className='child'>
																		{order_info?.finance_type === 'Lease' && (
																			<Accordion
																				theme={theme}
																				items={[
																					{
																						title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_FIRST_MONTH_PAYMENT,
																						key: 'string2',
																						subtitle:
																								order_info?.estimated_monthly_payment || order_info?.estimated_monthly_payment === 0
																										? default_currency_symbol +
																										(order_info?.estimated_monthly_payment || 0).toLocaleString(undefined, {
																											maximumFractionDigits: 2,
																											minimumFractionDigits: 2,
																										})
																										:`${default_currency_symbol}0.00`,
																						content: (
																							<>
																								<SummaryList
																									options={[
																										{
																											title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_BASE_MONTHLY_PAYMENT,
																											subtitle: order_info?.estimated_monthly_payment
																												? default_currency_symbol +
																												  (order_info?.estimated_monthly_payment - order_info?.monthly_sales_use_tax).toLocaleString(undefined, {
																														maximumFractionDigits: 2,
																														minimumFractionDigits: 2,
																												  })
																												: `${default_currency_symbol}0.00`,
																										},
																										{
																											title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_TAXES,
																											subtitle:
																												order_info?.monthly_sales_use_tax || order_info?.monthly_sales_use_tax === 0
																													? default_currency_symbol +
																													  (order_info?.monthly_sales_use_tax).toLocaleString(undefined, {
																															maximumFractionDigits: 2,
																															minimumFractionDigits: 2,
																													  })
																													: `${default_currency_symbol}0.00`,
																										},
																									]}
																								/>

																								<Typography
																									theme={theme}
																									variant='caption'
																									component={'small'}
																									display={'block'}
																									className='text-muted'
																									pl={2}
																									mt={2}
																								>
																									{customerData?.customer_addresses?.length
																										? PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_TAXES_ESTIMATION
																										: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_TAXES_ESTIMATION_DEALER}
																								</Typography>
																							</>
																						),
																					},
																				]}
																			/>
																		)}
																	</AccordianBox>
																</>
															),
														},
														{
															title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_UPFRONT_F_AND_Is,
															subtitle: '$0.00',
														},
														{
															title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_UPFRONT_PAYMENT_BREAKDOWN_POPUP_UPFRONT_ADD_ONS,
															subtitle: '$0.00',
														},
														{
															title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_UPFRONT_PAYMENT_BREAKDOWN_POPUP_UPFRONT_FEES,
															subtitle: order_info.order_fees
																? default_currency_symbol +
																  totalUpfrontFeeSum?.toLocaleString(undefined, {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																  })
																: `${default_currency_symbol}0.00`,
														},
													]}
												/>
											</>
										),
									},
								]}
							/>
						</Box>
					</AccordianBox>
					{order_info?.finance_type === 'Lease' && (
						<AccordianBox>
							<Box theme={theme} sx={{ mt: 2 }}>
								<Accordion
									theme={theme}
									items={[
										{
											title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_ADJUSTED_CAPITALIZED_COST,
											key: 'string2',
											subtitle: order_info?.adjusted_capitalized_cost
												? default_currency_symbol +
												  order_info.adjusted_capitalized_cost.toLocaleString(undefined, {
														maximumFractionDigits: 2,
														minimumFractionDigits: 2,
												  })
												: order_info?.net_finance_amount
												? default_currency_symbol +
												  order_info.net_finance_amount.toLocaleString(undefined, {
														maximumFractionDigits: 2,
														minimumFractionDigits: 2,
												  })
												: default_currency_symbol + '0.00',
											content: (
												<>
													<SummaryList
														options={[
															{
																title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_GROSS_CAPITALIZED_COST,
																subtitle: order_info?.gross_capitalized_cost
																	? default_currency_symbol +
																	  order_info?.gross_capitalized_cost.toLocaleString(undefined, {
																			maximumFractionDigits: 2,
																			minimumFractionDigits: 2,
																	  })
																	: order_info?.finance_amount
																	? `${default_currency_symbol}${order_info.finance_amount.toLocaleString(undefined, {
																			maximumFractionDigits: 2,
																			minimumFractionDigits: 2,
																	  })}`
																	: `${default_currency_symbol}0.00`,
															},
															{
																title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_CAPITALIZED_COST_REDUCTION,
																subtitle: order_info?.capitalized_cost_reduction
																	? `${default_currency_symbol}${order_info.capitalized_cost_reduction.toLocaleString(undefined, {
																			maximumFractionDigits: 2,
																			minimumFractionDigits: 2,
																	  })}`
																	: `${default_currency_symbol}0.00`,
															},
														]}
													/>
												</>
											),
										},
									]}
								/>
							</Box>
						</AccordianBox>
					)}

					{order_info?.finance_type === 'Finance' && (
						<AccordianBox>
							<Box theme={theme} sx={{ mt: 2 }}>
								<Accordion
									theme={theme}
									items={[
										{
											title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_FIRST_MONTH_PAYMENT,
											key: 'string2',
											subtitle:
												order_info?.estimated_monthly_payment || order_info?.estimated_monthly_payment === 0
													? default_currency_symbol +
													  ((order_info?.estimated_monthly_payment) || 0).toLocaleString(undefined, {
															maximumFractionDigits: 2,
															minimumFractionDigits: 2,
													  })
													: `${default_currency_symbol}0.00`,
											content: (
												<>
													<Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted' mt={2}>
														{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_FIRST_MONTH_PAYMENT_DUE}
													</Typography>
													<SummaryList
														options={[
															{
																title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_BASE_MONTHLY_PAYMENT,
																subtitle: order_info?.estimated_monthly_payment
																	? default_currency_symbol +
																	  ((order_info?.estimated_monthly_payment) || 0).toLocaleString(undefined, {
																			maximumFractionDigits: 2,
																			minimumFractionDigits: 2,
																	  })
																	: `${default_currency_symbol}0.00`,
															},
															{
																title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_TAXES,
																subtitle:
																	order_info?.monthly_sales_use_tax || order_info?.monthly_sales_use_tax === 0
																		? default_currency_symbol +
																		  (order_info?.monthly_sales_use_tax).toLocaleString(undefined, {
																				maximumFractionDigits: 2,
																				minimumFractionDigits: 2,
																		  })
																		: `${default_currency_symbol}0.00`,
															},
														]}
													/>
												</>
											),
										},
									]}
								/>
							</Box>
						</AccordianBox>
					)}

					{order_info?.finance_type === 'Finance' && (
						<Stack bgLight paddingMd={3} marginTopXs={2} dFlex justifyBetween dColumn>
							<Box theme={theme} display={'flex'} justifyContent={'space-between'}>
								<Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>
									{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_NET_FINANCED_AMOUNT}
								</Typography>
								<Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>
									{order_info?.net_finance_amount && order_info?.net_finance_amount > 0
										? default_currency_symbol +
										  order_info?.net_finance_amount?.toLocaleString(undefined, {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2,
										  })
										: `${default_currency_symbol}0.00`}
								</Typography>
							</Box>
							<Box theme={theme} display={'flex'} justifyContent={'space-between'} mt={2}>
								<Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>
									{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_RATE_TEXT}
								</Typography>
								<Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>
									{order_info?.apr}%
								</Typography>
							</Box>
						</Stack>
					)}

					<AccordianBox>
						<Box theme={theme} sx={{ mt: 2 }}>
							<Accordion
								theme={theme}
								items={[
									{
										title: PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_ESTIMATED_FEES,
										key: 'feesAccordion',
										subtitle: (() => {
											// Filter fees based on applicable_finance_type
											const filteredFees =
												order_info?.order_fees?.filter((fee: any) => fee?.applicable_finance_type?.includes?.(order_info?.finance_type) && fee?.fee_type != FeeTypes.EOT) || []
											// Calculate the sum of applied prices
											const totalFees = filteredFees
												.reduce((sum: number, fee: any) => sum + (fee.applied_price || fee?.default_amount), 0)
												.toLocaleString(undefined, {
													maximumFractionDigits: 2,
													minimumFractionDigits: 2,
												})

											return filteredFees.length > 0 ? `${default_currency_symbol}${totalFees}` : '$0.00'
										})(),
										content: (() => {
											// Filter fees based on applicable_finance_type
											const filteredFees =
												order_info?.order_fees?.filter((fee: any) => fee?.applicable_finance_type?.includes(order_info?.finance_type) && fee?.fee_type != FeeTypes.EOT) || []

											if (filteredFees.length > 0) {
												// Sort fees by fee_handling to have "Upfront" on top
												const sortedFees = filteredFees.sort((a: any, b: any) => {
													if (a.fee_handling === 'Financed' && b.fee_handling !== 'Financed') return -1
													if (a.fee_handling !== 'Financed' && b.fee_handling === 'Financed') return 1
													return 0
												})

												// Group fees by fee_handling
												const groupedFees = sortedFees.reduce((acc: any, fee: any) => {
													const feeHandling = fee.fee_handling || 'UNDEFINED'
													if (!acc[feeHandling]) {
														acc[feeHandling] = []
													}
													acc[feeHandling].push(fee)
													return acc
												}, {})

												return (
													<>
														<Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted' mt={2}>
															{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_ADDITIONAL_FEES}
														</Typography>
														{Object.entries(groupedFees).map(([feeHandling, fees]: any) => (
															<Box key={feeHandling} theme={theme} sx={{ pl: 2 }}>
																<Box theme={theme} pt={2}>
																	<Typography theme={theme} className='fw-medium text-uppercase' component='span' variant='caption'>
																		{feeHandling}
																	</Typography>
																</Box>
																{fees.map((fee: any, index: number) => (
																	<Box theme={theme} pt={2} className='dFlex justifyContentBetween gap-3' key={index}>
																		<Typography theme={theme} className='text-muted' component='span' variant='body2'>
																			{fee.fee_name}
																		</Typography>
																		<Typography theme={theme} className='text-muted' component='span' variant='body2'>
																			{`${default_currency_symbol}${(fee.applied_price || fee?.default_amount).toLocaleString(undefined, {
																				maximumFractionDigits: 2,
																				minimumFractionDigits: 2,
																			})}`}
																		</Typography>
																	</Box>
																))}
															</Box>
														))}
													</>
												)
											} else {
												return null
											}
										})(),
									},
								]}
							/>
						</Box>
					</AccordianBox>

					{order_info?.order_fnI ? (
						<AccordianBox>
							<Box theme={theme} sx={{ mt: 2 }}>
								<Accordion
									theme={theme}
									items={[
										{
											title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FNI_PRODUCTS_TITLE,
											key: 'f&IAccordion',
											subtitle: `${
												order_info?.order_fnI && order_info?.order_fnI?.length > 0
													? `${default_currency_symbol}${order_info.order_fnI
															.reduce((sum: any, current: any) => sum + current.applied_price, 0)
															.toLocaleString(undefined, {
																maximumFractionDigits: 2,
																minimumFractionDigits: 2,
															})}`
													: '$0.00'
											}`,
											content: (
												<>
													<SummaryList
														options={order_info?.order_fnI?.map((fniData: any) => ({
															title: fniData.product_name,
															subtitle: `${default_currency_symbol}${fniData.applied_price?.toLocaleString(undefined, {
																maximumFractionDigits: 2,
																minimumFractionDigits: 2,
															})}`,
														}))}
													/>
												</>
											),
										},
									]}
								/>
							</Box>
						</AccordianBox>
					) : (
						'-'
					)}

					{order_info?.order_options ? (
						<AccordianBox>
							<Box theme={theme} sx={{ mt: 2 }}>
								<Accordion
									theme={theme}
									items={[
										{
											title: PLACEHOLDERS.SUBMIT_ORDER_ADD_ONS,
											key: 'optionsAccordion',
											subtitle: addOnTotalPrice(order_info?.order_options),
											content: (
												<>
													<Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted' mt={2}>
														{order_info?.order_options.length > 0 && PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_ADD_ONS_TEXT}
													</Typography>
													{sortedOptionsArray?.length > 0 &&
														sortedOptionsArray?.map((option: any, index: any) => (
															<Box theme={theme} sx={{ pl: 2 }} key={index}>
																<>
																	{sortedOptionsArray[index - 1]?.installation_mode !== option?.installation_mode && (
																		<Box theme={theme} pt={2}>
																			<Typography theme={theme} className='fw-medium text-uppercase' component='span' variant='caption'>
																				{option?.installation_mode}
																			</Typography>
																		</Box>
																	)}
																	<Box theme={theme} pt={2} className='dFlex justifyContentBetween gap-3'>
																		<Typography theme={theme} className='text-muted' component='span' variant='body2'>
																			{option?.product_name}
																		</Typography>
																		<Typography theme={theme} className='text-muted' component='span' variant='body2'>
																			{option?.price === 0.0 ? '-' : addOnPrice(option)}
																		</Typography>
																	</Box>
																</>
															</Box>
														))}
												</>
											),
										},
									]}
								/>
							</Box>
						</AccordianBox>
					) : (
							`${default_currency_symbol}0.00`
					)}

					<Stack bgLight paddingMd={3} marginTopXs={2} dFlex justifyBetween>
						<Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>
							{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_TRADE_IN_VALUE}
						</Typography>
						<Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>
							{calculateAdjustedTradeInAmount(order_info, default_currency_symbol)}
						</Typography>
					</Stack>
					<Stack bgLight paddingMd={3} marginTopXs={2} dFlex justifyBetween>
						<Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>
							{PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_REBATES_INCENTIVES}
						</Typography>
						<Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>
							{
								orderState?.rebate
										? `${default_currency_symbol}${orderState?.rebate.toLocaleString(undefined, {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
										})}`
										: `${default_currency_symbol}0.00`
							}
						</Typography>
					</Stack>

					<Box theme={theme} sx={{ mt: 2 }}>
						<Typography theme={theme} variant='caption' className='text-muted line-height18' component={'small'}>
							{getPaymentBreakdownText(order_info, order_info?.finance_type, appState?.tenant_name, appState?.default_currency_symbol, totalDispositionFeeSum)}
						</Typography>
					</Box>
				</PaymentBreakDownPopupWrap>
			</Grid>
		</Grid>
	)
}
