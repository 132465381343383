import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PopupContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.popup-content': {
    textAlign: "center",
    ".action-btns": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      marginBottom: 72,
      gap: 16,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      },
    }
  }
}))

