import { SelectChangeEvent, Stack, useTheme } from "@mui/material";
import { Select, Typography } from "@ntpkunity/controls";
import { CoverageItemProps } from "./coverage-item.props";
import { CoverageItemWrap } from "./coverage-item.style";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "@app/context-provider";
import { useOrderContext } from "@pages";

export const CoverageItem = ({ header, title, coveragePrice }: CoverageItemProps) => {
  const theme = useTheme();
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  const { state: { order } } = useOrderContext();
  const[impact,setImpact] = useState(null)
  useEffect(() => {
    if (order && order?.order_fni) {
      const matchingFni = order?.order_fni.find(fni => fni?.name === header);
      if (matchingFni) {
        setImpact(parseFloat(matchingFni?.monthly_impact || '0.0')?.toFixed(2));
      }
      else{
        setImpact(null);
      }
    }
  }, [order.order_fni]);
  return (
    <CoverageItemWrap theme={theme} className="coverage-item-wrap">
      <Stack className="cj-card">
        <Typography
          theme={theme}
          className="fw-medium list-title"
          variant="body2"
          component={"p"}
          display={"block"}
        >
          {title}
        </Typography>
        <Typography
          theme={theme}
          className="text-primary"
          variant="subtitle1"
          component={"p"}
          display={"block"}
        >
          {"$"}
          {impact ?? coveragePrice}
          /{PLACEHOLDERS.PER_MONTH}.
        </Typography>
      </Stack>
    </CoverageItemWrap>
  );
};
