import { Link, useTheme } from '@mui/material'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import React, { useEffect, useState } from 'react'
import { ProductAddBundleCardProps } from './product-add-bundle-card.props'
import { ProductAddBundleCardWrap } from './product-add-bundle-card.style'
import clsx from 'clsx'
import { Stack } from '@components'
import { useOrderContext } from '@pages'
import { areArraysEqual } from 'helpers/methods'
import { getTabsClass } from '@helpers'
import arrowIcon from '../../src/shared/assets/icons/arrow-icon.svg'

export const ProductAddBundleCard = ({ title, primaryText, secondaryText, productDetail, onClick, onArrowClick }: ProductAddBundleCardProps) => {
  const theme = useTheme()
  const {
    state: { activeTab },
  } = useOrderContext();
  const [orderTabs, setOrderTabs] = useState([]);

  useEffect(() => {
    const workflow: any = JSON.parse(localStorage.getItem('workflow'))
    if (workflow?.meta_data?.tabs && !areArraysEqual(workflow?.meta_data?.tabs, orderTabs)) {
      setOrderTabs(workflow?.meta_data?.tabs)
    }
  })

  return (
    <ProductAddBundleCardWrap theme={theme} className="product-detail">
      <Stack className='cj-card'>
        <Box theme={theme} className='cp'>
          {title && (
            <Box theme={theme} className='title'>
              <Typography
                theme={theme}
                className="fw-medium"
                variant="body2"
                component={"p"}
                display={"block"}
              >
                {title}
              </Typography>
              <a href='javascript:void(0)' className='decoration-none' onClick={onArrowClick}>
                <img src={arrowIcon} />
              </a>
            </Box>
          )}
          <Box
            theme={theme}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "baseline",
              gap: "4px",
            }}
          >
            {primaryText && (
              <Typography
                theme={theme}
                className="text-primary"
                variant="h4"
                component={"h4"}
                display={"block"}
              >
                {primaryText}
              </Typography>
            )}
            {secondaryText && (
              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component={"p"}
                display={"block"}
              >
                {secondaryText}
              </Typography>
            )}
          </Box>
        </Box>
        <Box theme={theme}>
          {productDetail?.map((item, index) => {
            return (
              <Box theme={theme} className='pd-listing' key={index}>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="caption"
                  component={"small"}
                >
                  {item.title}
                </Typography>
                <Typography
                  theme={theme}
                  className="fw-sbold"
                  variant="caption"
                  component={"small"}
                >
                  {item.subTitle}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Stack>
    </ProductAddBundleCardWrap>
  );
}