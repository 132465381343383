import { ReactNode, useContext, useEffect, useReducer, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Grid, Snackbar, CircleLoader } from '@ntpkunity/controls'
import clsx from 'clsx'
import { JourneyLayoutWrap } from './journey-layout.style'
import { ProductCard, ProductDetail, Steps, StepsCount } from 'components'
import { OrderActionType, useOrderContext } from 'pages'
import { AppContext, updateCollapseState, useAppContext } from '@app/context-provider'
import { IStates, IVehicle} from '@interfaces'
import { useQueryClient } from 'react-query'
import {Navigation, QueryKeys, ResidenceType} from '@constants'
import {pagesActionType, UpdateTabsStatus, useJourneyLayoutContext} from './journey-layout-context-provider'
import {getDealerCode, getNextTabTitle, isEmpty} from '@helpers'
import {
  SaveEmploymentDetails, useAddPersonalFinance, useGetDealerFeebyState,
  useSaveOrderRequest,
  useUpdateCustomerContact, useUpdateEmploymentInfo,
  useUpdateOrderRequest, useUpdateOrderStatus, useUpdatePeronalFinanceInfo
} from '@hooks'
import {updateDealerFee, updateOrder} from 'pages/(order-management)/order/order-context-provider'

import {AssetDetailControl, FeeType} from '@ntpkunity/controls-common'
import { SnackbarUtility } from '@utilities'
import { useWorkflowContext } from '@app/router-workflow'
import {useFormContext} from "../../pages/(order-management)/order/form-context-provider";
import {formatPayloadDate, formatUrl, getEmployedSinceDate, getMoveInDate} from "../../helpers/methods";
import { AlertDialog } from 'libraries/trade-in-journey-components/alert-popup/alert-popup'
import { useUpdateCustomerLicenseInfo } from 'hooks/customer-management'
import {useParams} from "react-router-dom";
import { CancelOrderDialog } from '@libraries'
import {useAuthentication} from "../../hooks/useAuthenticationHook";
import {useNavigate} from "react-router-dom";
import {FeeTypes, Status} from "../../constants/enums";
import {FEE_MONTHLY_PAYMENT_BASED} from "../../constants/fee";


export const JourneyLayout = ({
  orderTabs,
  nextTab,
  getActiveTab,
  getActiveTabTitle,
  createOrderStepObject,
  activeTab,
  setActivetab,
  children,
  hideDisclaimer,
  vehicleStatus
}: {
  orderTabs: any, nextTab: string, getActiveTab: any,
  getActiveTabTitle: any, activeTab: any, setActivetab: any
  createOrderStepObject: any, children?: ReactNode, hideDisclaimer?: boolean, vehicleStatus?:any
}) => {
  const theme = useTheme()
  const { navigateNext, getOrderWorkflow } = useWorkflowContext()
  const [stepsCollapsed, setStepCollapsed] = useState(true)
  const [financeTab, setFinanceTab] = useState<{isOpen: boolean, tab: string[]}>({isOpen:false, tab:[] })
  const { dispatch } = useAppContext();
  const [cancelorder, setCancelorder] = useState(false)
  const navigate = useNavigate()
  const { authenticationStatus } = useAuthentication()

  const [openPopup, setOpenPopup] = useState(false)
  const { state: pageState, dispatch: pageDispatch } = useJourneyLayoutContext()
  const { state: { order }, dispatch: orderDispatch,calculateQuotation } = useOrderContext()
  const appState = useContext(AppContext);
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  const PLACEHOLDERS = appState.state.language.placeholders
  const defaultCurrency = appState.state.default_currency_symbol
  const { mutate: saveOrderRequest } = useSaveOrderRequest()
  const { mutate: updateOrderRequest } = useUpdateOrderRequest()
  const { getDirtyForms, getFormData, resetFormState, isFormValid } = useFormContext()
  const { mutate: updateCustomerByReferenceId } = useUpdateCustomerContact()
  const { mutate: addEmploymentData } = SaveEmploymentDetails()
  const { mutate: updateEmployInfoReferenceId } = useUpdateEmploymentInfo()
  const { mutate: createPersonalFinance } = useAddPersonalFinance()
  const { mutate: updatePersonalFinance } = useUpdatePeronalFinanceInfo()
  const { mutate: updateLicenseByInfoReferenceId } = useUpdateCustomerLicenseInfo();
  const { mutate: updateOrderStatus } = useUpdateOrderStatus()
  const { mutate: dealerFee } = useGetDealerFeebyState(appState?.state?.slug)
  const { vin } = useParams()

  const queryClient = useQueryClient();
  const LenderConfigurations:any = queryClient.getQueryData(QueryKeys.GET_DEFAULT_LENDER);
  const vehicle: IVehicle = queryClient.getQueryData(
    [QueryKeys.VEHICLE, vin]
  );
  const states: IStates[] = queryClient.getQueryData(
      QueryKeys.GET_ALL_STATES
  );

  const dealerData = JSON.parse(localStorage.getItem('dealer_information'))?.dealer

  useEffect(() => {
    if (nextTab)
      setActivetab(nextTab)
  }, [nextTab])

  useEffect(() => {
    orderDispatch({
      type: OrderActionType.UPDATE_ACTIVE_TAB,
      payload: activeTab
    })
  }, [activeTab])

  useEffect(() => {
    pageDispatch(UpdateTabsStatus(order))
  }, [order])

const handleSubmit = async () => {

    const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL);
    const workflow = JSON.parse(localStorage.getItem('workflow'));
    if (!authenticationStatus) {
        localStorage.setItem('vin', order?.order_asset?.vin)
        navigate(Navigation.AUTHENTICATION.SIGN_IN_PAGE.replace(':tenant', appState?.state?.slug));

    }

    try {

        if (pageState.addons.error || pageState.fnI.error || pageState.insurance.error || pageState.payments.error ) {
                return false;
            }


        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVING_IN_PROGRESS));
        const { order_fni, order_fees, dealer_fees, ...payload_order } = order;
        const orderFees = order_fees?.map((fee) => {
          if (fee.calculation_type === FEE_MONTHLY_PAYMENT_BASED && fee.is_active && fee.event === FeeTypes.EOT) {
            const dealer_fee = order?.dealer_fees?.find(dealerFee =>dealerFee.id === (fee?.order_id ? fee.dealer_fee_id : fee.id))
            const multipliedValue = (order?.estimated_monthly_payment) * fee.multiplication_factor;
            fee.applied_price = dealer_fee?.default_amount;
            if (multipliedValue < dealer_fee?.default_amount) {
              fee.applied_price = parseFloat(multipliedValue.toFixed(2));
            }
          }
          return fee;
        });
        const payload = {
            ...payload_order,
            lender_id: order?.lender_id && order?.lender_id != 0 ? order?.lender_id : LenderConfigurations?.data?.id,
            down_payment: (order?.down_payment * 100) / order?.order_asset?.unit_price,
            allowed_usage: order?.annual_usage,
            running_id: localStorage.getItem('running_id'),
            order_options: order?.order_options?.length > 0 ? order?.order_options : null,
            order_fnI: order?.order_fnI?.length > 0 ? order?.order_fnI : null,
            insurance: order?.insurance ? order?.insurance : null,
            down_payment_value: order?.down_payment,
            order_fees: orderFees,
            customer_id: order?.customer_id ?? customerData?.customer_id,
            reference_id: order?.reference_id ?? customerData?.reference_id,
            estimated_monthly_payment: order?.monthly_payment ? order?.monthly_payment : order?.estimated_monthly_payment
        };
        if (order?.identifier) {
            await new Promise<void>((resolve, reject) => {
                updateOrderRequest(payload, {
                    onSuccess() {
                        resetFormState('TradIn')
                      if(order?.appointment_details && !order?.appointment_details?.identifier){
                        updateOrderStatus({
                          status: Status.Appointment,
                          reference_number: order?.reference_number,
                          user_id: 0,
                        }) }

                        queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, payload.reference_number]);

                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_UPDATE_SUCCESS));
                        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                        resolve();
                    },
                    onError() {
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_UPDATE_FAIL));
                        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                        reject();
                    }
                });
            });
        } else {
            await new Promise<void>((resolve, reject) => {
                saveOrderRequest([payload], {
                    onSuccess(response) {
                        orderDispatch(updateOrder(response[0]));
                        const stateCode = states?.find((x) => x?.id === dealerData?.dealer_address?.state_id)?.code;
                          dealerFee({ stateCode, dealerCode: dealerData?.dealer_code }, {
                            onSuccess(data) {
                              const activeFees = data?.filter(fee => fee.is_active);
                              orderDispatch(updateDealerFee(activeFees));
                            }
                          });



                        const quotationObject = response
                            .filter(data => data?.finance_type === order?.finance_type)
                            .map(data => {
                                return { ...data, base_rate: 0, margin: 0, applicable_rate: 0 };
                            });
                            if(payload?.order_tradein){
                             resetFormState('TradIn')
                            }
                            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_SUCCESS));
                            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                            navigateNext(Navigation.ORDER_PAGE, {
                                'vin': order?.order_asset?.vin,
                                'orderId': response[0].reference_number
                            });
                            resolve();
                    },
                    onError() {
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_FAILED));
                        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                        reject();
                    }
                });
            });
        }
    } catch (error) {
        console.error("An error occurred during submission:", error);
    }
};

const validateFinanceData = async () => {
    const dirtyForms = getDirtyForms();
    const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL);
    const customer_email = JSON.parse(localStorage.getItem('settings'))?.email;
    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_VALIDATION_IN_PROGRESS));
    let pendingMutations = [];

    for (const form of dirtyForms) {
        switch (form.formName) {
            case 'ContactDetails':
                if (form.state.isDirty) {
                    const contactData = getFormData('ContactDetails');
                    const isValid = isFormValid('ContactDetails')
                    if (isValid) {
                        const contactPayload = {
                            customer_profile: { ...contactData, updated_by: customer_email },
                            reference_id: customerData?.reference_id
                        };

                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            updateCustomerByReferenceId(contactPayload, {
                                onSuccess: () => {
                                    resetFormState('ContactDetails');
                                    resolve();
                                },
                                onError: reject
                            });
                        });

                        pendingMutations.push(mutationPromise);
                    } else {
                    setFinanceTab(prevState => ({
                        isOpen: true,
                        tab: [...(prevState?.tab || []), PLACEHOLDERS.FINANCING_PERSONAL_DETAILS]
                    }));
                    snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                    return false;
                    }

                }
                break;

            case 'licence':
                if (form.state.isDirty) {
                    const licenceData = getFormData('licence');
                    const isValid = isFormValid('licence')
                    if (isValid) {
                        const licencePayload = {
                            ...licenceData,
                            address: {state: licenceData?.state},
                            expiry_date: formatPayloadDate(licenceData?.expiry_date),
                            date_of_birth: formatPayloadDate(licenceData?.date_of_birth),
                            reference_id: customerData?.reference_id
                        };

                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            updateLicenseByInfoReferenceId(licencePayload, {
                                onSuccess: () => {
                                    resetFormState('licence');
                                    resolve();
                                },
                                onError: reject
                            });
                        });

                        pendingMutations.push(mutationPromise);
                    } else {
                    setFinanceTab(prevState => ({
                        isOpen: true,
                        tab: [...(prevState?.tab || []), PLACEHOLDERS.FINANCING_TITLE_LICENSE_DETAILS]
                    }));
                    snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                    return false;
                    }

                }
                break;

            case 'employment':
                if (form.state.isDirty) {
                    const employInfoData = getFormData('employment');
                    const isValid = isFormValid('employment')
                    if (isValid) {
                        const employmentPayload = {
                            ...employInfoData,
                            annual_other_income: parseInt(employInfoData.annual_other_income),
                            annual_employment_income: parseInt(employInfoData.annual_employment_income),
                            employed_since: getEmployedSinceDate(employInfoData.employed_since),
                            reference_id: customerData?.reference_id
                        };

                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            const mutationFunction = isEmpty(employInfoData)
                                ? addEmploymentData
                                : updateEmployInfoReferenceId;

                            mutationFunction(employmentPayload, {
                                onSuccess: (response) => {
                                    handleEmploymentUpdate(response);
                                    resolve();
                                },
                                onError: reject
                            });
                        });

                        pendingMutations.push(mutationPromise);
                    } else {
                        setFinanceTab(prevState => ({
                            isOpen: true,
                            tab: [...(prevState?.tab || []), PLACEHOLDERS.FINANCING_TITLE_EMPLOYMENT_DETAILS]
                        }));
                        snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                        return false;
                    }
                }
                break;

            case 'personalFinance':
                if (form.state.isDirty) {
                    const personalFinanceData = getFormData('personalFinance');
                    const isValid = isFormValid('personalFinance')
                    if (isValid) {
                        const financePayload = {
                            ...personalFinanceData,
                            monthly_payment: (personalFinanceData.residence === ResidenceType.Renting || personalFinanceData.residence === ResidenceType.Mortgage)
                                ? personalFinanceData.monthly_payment
                                : 0,
                            reference_id: customerData?.reference_id
                        };

                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            const mutationFunction = isEmpty(personalFinanceData)
                                ? createPersonalFinance
                                : updatePersonalFinance;

                            mutationFunction(financePayload, {
                                onSuccess: () => {
                                    handlePersonalFinanceUpdate();
                                    resolve();
                                },
                                onError: reject
                            });
                        });

                        pendingMutations.push(mutationPromise);
                    } else {
                        setFinanceTab(prevState => ({
                            isOpen: true,
                            tab: [...(prevState?.tab || []), PLACEHOLDERS.FINANCING_TITLE_PERSONAL_FINANCE_DETAILS]
                        }));
                        snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                        return false;
                    }

                }
                break;

            case 'residence':
                if (form.state.isDirty) {
                    const residenceData = getFormData('residence');
                    const isValid = isFormValid('residence')
                    if (isValid) {
                        const addresses = [];

                        addAddressIfValid(residenceData.mail_address, addresses, 'Mailing');
                        addAddressIfValid(residenceData.garaging_address, addresses, 'Garaging');
                        addAddressIfValid(residenceData.Billing, addresses, 'Billing');

                        const residencePayload = {
                            customer_addresses: addresses,
                            reference_id: customerData?.reference_id
                        };

                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            updateCustomerByReferenceId(residencePayload, {
                                onSuccess: () => {

                                    handleResidenceUpdate();
                                    if (residenceData.mail_address?.is_garaging) {
                                       calculateQuotation()
                                    }
                                    else if (residenceData.garaging_address) {
                                       calculateQuotation()
                                    }
                                    resolve();
                                },
                                onError: reject
                            });
                        });

                        pendingMutations.push(mutationPromise);
                    } else {
                        setFinanceTab({isOpen: true, tab: [...financeTab?.tab, PLACEHOLDERS.FINANCING_TITLE_RESIDENCE_DETAILS]})
                        snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                        return false;
                    }

                }
                break;

            default:
                break;
        }
    }

    function handleEmploymentUpdate(response) {
        resetFormState('employment');
        pageDispatch({
            type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
            payload: { success: true, error: false }
        });
    }

    function handlePersonalFinanceUpdate() {
        resetFormState('personalFinance');
        pageDispatch({
            type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS,
            payload: { success: true, error: false }
        });
    }

    function handleResidenceUpdate() {
        resetFormState('residence');
        pageDispatch({
            type: pagesActionType.UPDATE_RESIDENCE_DETAILS,
            payload: { success: true, error: false }
        });
    }

    function addAddressIfValid(address, addresses, type) {
        if (address) {
            address.address_type = type;
            address.verified = !getDealerCode();
            addresses.push({
                ...address,
                move_in_date: address.move_in_duration ? getMoveInDate(address.move_in_duration) : null,
                is_garaging: address.is_garaging
            });
        }
    }

    try {
        await Promise.all(pendingMutations);
        return true;
    } catch (error) {
        console.error('Error occurred:', error);
        return false;
    }
    };
    const handleSuccess = () => {
        if (dealerData?.website) {
            let url = formatUrl(dealerData?.website)
            window.open(url, '_self');
        } else {
            getOrderWorkflow();
        }
    };

    const handleCancleOrder = () =>
        order?.identifier ? setCancelorder(true) : handleSuccess();

    const orderAsset = order && order.order_asset ? order.order_asset : {};

  return (
    <JourneyLayoutWrap theme={theme} className={clsx({ 'journey-layout-wrap': true, 'hide-disclaimer': hideDisclaimer })} p={{ xs: 3 }}>
      {openPopup && (
        <AssetDetailControl
          theme={theme}
          selectedVehicle={orderAsset}
          defaultCurrency={defaultCurrency}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClick={() => { }}
          hideFooter={true}
          onListViewClick={()=> {}}
          dealerWebsite = {dealerData?.website}
        />
      )}
          {cancelorder && (
              <CancelOrderDialog setCancelorder={setCancelorder} handleSuccess={handleSuccess} />
          )}
      <Grid theme={theme} container item spacing={{ xs: 3, md: 1 }} flexWrap={{ lg: 'nowrap' }}>
        <Grid theme={theme} item xs={12} md={4} lg={3} maxWidth={{ lg: 370 }} className='sticky'>
          <Box theme={theme} className={clsx('steps-area', stepsCollapsed ? '' : 'steps-collapsed')}>
            <StepsCount
              title={getActiveTabTitle()}
              subtitle={getNextTabTitle(orderTabs, activeTab, PLACEHOLDERS) !== '' ? `${PLACEHOLDERS.NEXT_TEXT}: ${getNextTabTitle(orderTabs, activeTab, PLACEHOLDERS)}` : ''}
              counter
              CounterStepCurrent={orderTabs ? orderTabs?.findIndex((item) => item == activeTab) + 1 : 0}
              CounterStepTotal={orderTabs?.length || 0}
              counterProgressValue={orderTabs ? ((orderTabs?.findIndex((item) => item == activeTab) + 1) / orderTabs.length) * 100 : 0}
              actionArea
              onBack={() => {getOrderWorkflow()}}
              onSave={handleSubmit}
              onClose={() => {handleCancleOrder()}}
              progressBar
              collapse={(!stepsCollapsed)}
              progressValue={orderTabs ? ((orderTabs?.findIndex((item) => item == activeTab) + 1) / orderTabs.length) * 100 : 0}
              onClick={() => {
                setStepCollapsed(!stepsCollapsed)
                dispatch(updateCollapseState())
              }
              }
            />
            <Box theme={theme} className={clsx({'all-steps-container': true,'program-error': (vehicleStatus?.vehicle_status === 'Not Available'|| vehicleStatus?.listing_status == false)})}>
                  {order?.order_asset && (
                <ProductCard
                  productImg={order?.order_asset?.photo_urls?.[0]?.location}
                  productbg={false}
                  title={`${order?.order_asset?.year ? order?.order_asset?.year: ''} ${order?.order_asset?.make ? order?.order_asset?.make : ''} ${order?.order_asset?.model ? order?.order_asset?.model : ''}`}
                  subtitle={order?.order_asset?.trim_description ?? ''}
                  catagory={`${dealerData?.dealer_name}  |  ${dealerData?.city}, ${dealerData?.state}`}
                  onClick={() => setOpenPopup(true)}
                />
              )}
              {orderTabs?.length > 0 && <Steps
                stepItems={orderTabs?.map((item) => { return createOrderStepObject(item,setStepCollapsed) })}
              />}
              {order?.order_asset && (
                <ProductDetail
                  modal={order?.order_asset?.model_code}
                  stock={order?.order_asset?.stock_number}
                  vin={order?.order_asset?.vin}
                  onClick={() => { }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid theme={theme} item xs={12} md={8} lg={9} maxWidth={{ lg: 1133 }} ml={{ md: 'auto' }} mr={{ md: 'auto' }}>
          <div className='journey-content-outer'>
            <div className='content-inner'>
              {getActiveTab(activeTab)}
              {children && children}
            </div>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        theme={theme}
        message={[PLACEHOLDERS.ORDER_SAVING_IN_PROGRESS, PLACEHOLDERS.ORDER_VALIDATION_IN_PROGRESS, "Generating Documents"].includes(snackbarState.message) ?
          <Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px">
              <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
          </Box> : snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      />
    {financeTab?.isOpen &&
       <AlertDialog
       title={'Failed to save data'}
       subtitle={`You have invalid data on tab ${financeTab?.tab?.join(', ').replace(/,([^,]*)$/, ' and$1')}. Please add mandatory information and try again`}
       btnText={'Cancel'}
       isOpen={financeTab?.isOpen}
       onClick={() => { setFinanceTab({isOpen: false, tab: [] }) }}
       onClose={() => { setFinanceTab({isOpen: false, tab: [] }) }}
   />}
    </JourneyLayoutWrap>
  )
}
