import React, { FC, useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { PageWrap } from '../addons-style/add-ons.style'
import { PageHeader } from '../../../components'
import { useQueryClient } from 'react-query'
import { IVehicle } from 'interfaces'
import { QueryKeys } from '../../../constants'
import { OptionsListPopup } from '../optionslistpopups/optionslistpopup'
import { Tabs } from '../tabs/tabs'
import { AppContext } from '@app/context-provider'
import { OrderActionType, useOrderContext } from 'pages'
import { useParams } from 'react-router-dom';
import { useTags } from 'hooks/event-analytics';
import { Event } from 'apis/event-analytics';
import { Tags, PageType } from 'constants/enums';
import { user_session } from '@constants'
import {useGetTrimCode, useGetVehicleDetailByVin} from '@hooks'

export const AddOn: FC<{
}> = () => {
  const theme = useTheme()
  const queryClient = useQueryClient();
  const { state, dispatch } = useOrderContext()
  const { dealer_id, order_asset: {make, model,vin} } = state?.order
  const vehicle: IVehicle = queryClient.getQueryData(
    [QueryKeys.VEHICLE, vin]
  );
  const dealer = JSON.parse(localStorage.getItem('dealer_information'))?.dealer

  const appState = useContext(AppContext);
  const { data: vehicleDetail } = useGetVehicleDetailByVin(vin, appState?.state?.slug, dealer?.dealer_code, appState?.state?.tenant)

  const { data: addOnData } = useGetTrimCode(vehicle?.dealer_code, vehicle?.model, appState?.state?.slug)
  const PLACEHOLDERS = appState.state.language.placeholders
  const defaultCurrency = appState.state.default_currency_symbol
  const add_on_data = state?.order?.order_options
  const [popupAddedItems, setPopupAddedItems] = useState<any>([])
  const [totalSumArray, setTotalSumArray] = useState<any>(add_on_data);
  const [finalSum, setTotalSum] = useState(0);

  const preInstalledOptions = vehicleDetail?.dealer_options
  const { tenant } = useParams()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const { mutate: createTags } = useTags();
  // popup data are show
  useEffect(() => {
    if (add_on_data?.length > 0)
      setPopupAddedItems([...add_on_data.map(add_on => add_on.option_id)])
    const page_event: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.FNI_END,
      user_id: user_profile?.user_id,
      page_type: PageType.FIPage
    }
    createTags(page_event)
    const event: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.DISPLAY_ADDONS,
      dealer_id: +dealer_id,
      vehicle_make: make ?? '',
      vehicle_model: model ?? '',
      vin:vin??'',
      order_id:state?.order?.identifier,
      user_id: user_profile?.user_id,
      page_type: PageType.Addons
    }
    createTags(event)
  }, []);

  // total sum show in Add on screen
  useEffect(() => {
    if (state?.order?.order_options?.length) {
      const filteredOptions = state.order.order_options.filter(
          (option) =>
              (option.installation_mode === 'Pre-Installed' && !option.is_price_inclusive) ||
              option.installation_mode === 'Optional'
      );
      const total = filteredOptions.reduce((sum, item) => {
        return sum + item.applied_price;
      }, 0)
      setTotalSum(total)
    }
  }, [state?.order?.order_options])


  const callBack = (type: string, data: any) => {
    if (type === 'add') {
      setTotalSumArray([...totalSumArray, data])
      const added_option = {
        option_id: data?.id,
        product_name: data?.product_name,
        installation_mode: data?.installation_mode,
        dealer_id: data?.dealer_id,
        applied_price: data?.installation_mode == 'Pre-Installed' && data?.is_price_inclusive ? 0 : data?.price,
        price: data?.price || 0,
        is_price_inclusive: data.is_price_inclusive,
        rv_balloon_percentage: data?.rv_balloon_percentage || data.rv_adder || 0,
        rv_balloon_value: data?.is_price_inclusive ? 0 : data.rv_balloon_value || data.rv_adder || 0
      }
      dispatch({
        type: OrderActionType.UPDATE_ORDER_OPTION,
        payload: [...add_on_data, added_option]
      })
    } else if (type === 'remove') {
      const newArray = totalSumArray?.filter(
        (obj: any) => (obj.option_id ?? obj.id) !== (data.option_id ?? data.id)
      )
      const removed_object = add_on_data?.filter((obj: any) => (obj.option_id ?? obj.id) !== (data.option_id ?? data.id));
      dispatch({
        type: OrderActionType.UPDATE_ORDER_OPTION,
        payload: [...removed_object]
      })
      setTotalSumArray([...newArray])
    }
  }
  const preInstalledOptionIds = preInstalledOptions.map(item => item.id)

  const selectedArray = [
    ...Array.from(
        new Set(
            addOnData
                ?.filter((x: any) => x.is_active === true && !preInstalledOptionIds.includes(x.id))
                ?.map((item: any) => item.category_name)
        )
    ),
  ]
  const preinstalled = [
    {
      title: 'Pre-Installed',
      content: (
        <OptionsListPopup
          callBack={callBack}
          data={preInstalledOptions}
          addedItems={popupAddedItems}
          setAddedItems={setPopupAddedItems}
          preInstalledOptions={preInstalledOptions}
          defaultCurrency={defaultCurrency}
        />
      )
    }
  ]
  const categories_data = selectedArray?.map((result: any) => {
    {
      return {
        title: result,
        content: (
          <OptionsListPopup
            callBack={callBack}
            data={addOnData?.filter((item: any) => {
              return item.category_name
                ? item.category_name == result &&
                item.installation_mode !== 'Pre-Installed' &&
                item.is_active === true
                : null
            })}
            addedItems={popupAddedItems}
            setAddedItems={setPopupAddedItems}
            defaultCurrency={defaultCurrency}
          />
        )
      }
    }
  })
  const tabs = preinstalled.concat(categories_data)
  if (preInstalledOptions.length ===0){
    //means no preinstalled order options so removing this tab preinstalled
    tabs.shift()
  }
  return (
    <PageWrap theme={theme} className='page-wrap'>
      <PageHeader
        marginBottom={3}
        title={PLACEHOLDERS.ADD_ONS_TITLE}
      />
      <Tabs
        tabs={tabs}
        totalSum={finalSum}
        defaultCurrency={defaultCurrency}
      />
    </PageWrap>
  )
};
