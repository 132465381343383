import { Link, useTheme } from "@mui/material";
import { Box, Button, Skeleton, Typography, Image, Icon, Label } from "@ntpkunity/controls";
import { ProductBundleCardProps } from "./product-bundle-card.props";
import { Stack, CoverageItem } from "@components";
import { useContext, useEffect, useState } from "react";
import { fniContext } from "@utilities";
import { AddOnsType, TaggingClasses } from "constants/enums";
import clsx from 'clsx'
import { areArraysEqual } from "helpers/methods";
import { useOrderContext } from "@pages";
import { getTabsClass } from "@helpers";
import { ProductBundleCardWrap } from "./product-bundle-card.style";
import circleIcon from "../../src/shared/assets/icons/circle-primary.svg"
import React from "react";
export const ProductBundleCard = ({
  title,
  saveUpTo,
  bundleList,
  productPrice,
  linkText,
  buttonText,
  onBtnClick,
  onLinkClick,
  buttonStatePrimary = true,
  buttonStateSecondary = false,
  coverageItem,
  coverageTitle,
  coveragePrice,
  changeTextonHover,
  onHoverText,
}: ProductBundleCardProps) => {
  const theme = useTheme();
  const [buttonLabel, setButtonLabel] = useState<any>();
  const { isLoading } = useContext(fniContext);
  const {
    state: { order, activeTab },
  } = useOrderContext();
  const [orderTabs, setOrderTabs] = useState([]);
  const buttonClass = buttonStatePrimary ? `${getTabsClass(orderTabs, activeTab, false)}-add` : `${getTabsClass(orderTabs, activeTab, false)}-remove`;

  useEffect(() => {
    if (changeTextonHover) {
      setButtonLabel(buttonText);
    }
  }, [buttonText]);

  useEffect(() => {
    const workflow: any = JSON.parse(localStorage.getItem('workflow'))
    if (workflow?.meta_data?.tabs && !areArraysEqual(workflow?.meta_data?.tabs, orderTabs)) {
      setOrderTabs(workflow?.meta_data?.tabs)
    }
  })

  return (
    <ProductBundleCardWrap theme={theme} className="product-bundle-card-wrap">
      <Stack className="cj-card">
        <Box theme={theme} className="product-img">
          <Box theme={theme} className="cj-card-content">
            <Box theme={theme} className="cp">
              {title && (
                <Typography
                  theme={theme}
                  className="title"
                  variant="h4"
                  component={"h4"}
                  display={"block"}
                >
                  {title}
                </Typography>
              )}
              {saveUpTo && (
                <Label
                  theme={theme}
                  text={`Save upto ${saveUpTo}%`}
                  state="primary"
                  textTransform="none"
                />
              )}
              <Box theme={theme} className="bundle" >
                {bundleList?.map((item, index) => {
                  return (
                    <Box theme={theme} className="bundle-list" key={index}>
                      <img src={circleIcon} alt="circle icon" />
                      <Typography
                        theme={theme}
                        className="text-muted"
                        variant="caption"
                        component={"small"}
                        display={"block"}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
              {productPrice && (
                <Typography
                  theme={theme}
                  className="text-primary"
                  variant="subtitle1"
                  component={"p"}
                  display={"block"}
                >
                  {productPrice}
                </Typography>
              )}

              {onLinkClick && (
                <Link
                  className="link decoration-none fw-medium"
                  variant="caption"
                  component={"a"}
                  display={"block"}
                  onClick={onLinkClick}
                >
                  {linkText}
                </Link>
              )}
            </Box>

            {coverageItem &&
              (isLoading ? (
                <Skeleton
                  theme={theme}
                  width={"100%"}
                  height={32}
                  sx={{ margin: "auto" }}
                />
              ) : (
                <CoverageItem
                  header={title}
                  title={coverageTitle}
                  coveragePrice={coveragePrice}
                />
              ))}

            {buttonText && (
              <Button
                theme={theme}
                primary={buttonStatePrimary}
                secondary={buttonStateSecondary}
                className={clsx({
                  [buttonClass]: true,
                  'btn-desktop': true
                })}
                text={changeTextonHover ? buttonLabel : buttonText}
                onClick={onBtnClick}
                onMouseEnter={() => {
                  changeTextonHover && buttonStateSecondary &&
                    setButtonLabel(onHoverText);
                }}
                onMouseLeave={() => {
                  changeTextonHover &&
                    buttonStateSecondary &&
                    setButtonLabel(buttonText);
                }}
                fullWidth
              />
            )}
          </Box>
        </Box>
      </Stack>
    </ProductBundleCardWrap>
  );
};
