import React, { useEffect, useState } from 'react'
import { useCustomDirtyForm } from '../../../../hooks/order-management'
import { ResidenceDetailsComponent } from '../../../../libraries/financing-control/ResidenceDetails'
import { useFormContext } from '../../../(order-management)/order/form-context-provider'
import { getDefaultValues } from '../../../../utilities/form/residence-details'

const ResidenceDetailsWrapper = ({customerReferenceId, contactData, setTab, residentForm, watchResidenceFields, isGaraging, setIsGaraging, isBilling, setIsBilling ,previous, setPrevious}) => {
	const { formStates } = useFormContext()

	useCustomDirtyForm('residence', watchResidenceFields, residentForm?.formState?.isValid)

	useEffect(() => {
		if (contactData) {
			const garagingAdrees = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Garaging' &&
					 x?.entity_type == null &&
					x?.address_line_1?.trim() &&
					x?.state_name?.trim() &&
					x?.zip_code?.trim()
			)
			const billingAdrees = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Billing' &&
					x?.entity_type == null &&
					x?.address_line_1?.trim() &&
					x?.state_name?.trim() &&
					x?.zip_code?.trim()
			)
			setIsGaraging(!garagingAdrees)
			setIsBilling(!billingAdrees)
			const residenceFormData = formStates?.['residence']?.data
			residentForm?.reset(residenceFormData || getDefaultValues(contactData, customerReferenceId,isGaraging, isBilling, previous))
		}
	}, [contactData])

	return <ResidenceDetailsComponent
			customerReferenceId={customerReferenceId}
			contactData={contactData}
			setTab={setTab}
			form={residentForm}
			isGaraging={isGaraging}
			setIsGaraging={setIsGaraging}
			isBilling={isBilling}
			setIsBilling={setIsBilling}
			previous={previous}
			setPrevious={setPrevious}
	/>
}

export default ResidenceDetailsWrapper
