import { Link, useTheme } from '@mui/material'
import {
  Icon,
  Box,
  Typography,
  Button
} from '@ntpkunity/controls'
import clsx from 'clsx'
import React, { Fragment } from 'react'
import { NeedHelpProps } from './need-help.props'
import { NeedHelpWrap } from './need-help.style'
import { Stack } from '@components'
import { getDealerInfo } from 'helpers/methods'
import { useGetDealerByDealerCode } from '@hooks'
import { useAppContext } from '@app/context-provider'



export const NeedHelp = ({className, title, mapAddress, mapAddressLinkText, bgLight, paddingXs = 3, paddingMd = 4, onChatClick, onCallClick, onDirectionClick, onMapAddressClick, chatBtnText, callBtnText, directionBtnText, map}: NeedHelpProps) => {
    const theme = useTheme()
    const { state: appState } = useAppContext();
    const { tenant } = appState
    const { data: dealerByDealerCode } = useGetDealerByDealerCode({
        dealerCode: getDealerInfo()?.dealer_code,
        headers: { tenant },
    });
  return (
    <NeedHelpWrap theme={theme} className={clsx(className, { 'need-help-wrap': true, 'bg-light': bgLight })} p={{xs: paddingXs, md: paddingMd}}>
        {title &&
                <Typography theme={theme} className='title' component={'h4'} variant='h4'>{title}</Typography>
            }
            <Box theme={theme} className='action-btns'>
                {(dealerByDealerCode?.chat && onChatClick) &&
                    <Button theme={theme} secondary text={chatBtnText} onClick={onChatClick} startIcon={<Icon name='StartChatIcon' />} ariaLabel='start chat icon'  />
                }
                {onCallClick &&
                    <Button theme={theme} secondary text={callBtnText} onClick={onCallClick} startIcon={<Icon className='fill-icon' name="CallIcon" />} ariaLabel='call icon' />
                }
                {onDirectionClick &&
                    <Button theme={theme} secondary text={directionBtnText} onClick={onDirectionClick} startIcon={<Icon name="DirectionIcon" />} ariaLabel='direction icon' />
                }
            </Box>
            {map &&
                <Box theme={theme} className='google-map'>
                    {mapAddress &&
                        <Typography theme={theme} variant='caption' component={'small'} display={'block'}>
                            <a href='javascript:void(0)' className='link fw-medium decoration-none' onClick={onMapAddressClick} aria-label='map'>{mapAddressLinkText}</a>
                            {mapAddress}
                        </Typography>
                    }
                    {map}
                </Box>
            }
    </NeedHelpWrap>
  )
}
