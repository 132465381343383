import {FC, useEffect, useState} from 'react'
import { useTheme } from '@mui/material'
import { PageHeader } from '@components'
import { PageWrap } from './contracting-control.style'
import { useAppContext } from '@app/context-provider'
import { DocumentPackage } from './document-package.component'
import {DocumentPackageStatus, DocumentPackageTypes, PageType, Tags} from 'constants/enums'
import { GeneralDocument } from './general-document.component'
import { useOrderContext } from '@pages'
import { IDocumentPackage } from '@interfaces'
import {useGetAllDocumentPackages} from "@hooks";
import {OrderStatus, QueryKeys, user_session} from "@constants";
import {useQueryClient} from "react-query";
import {pagesActionType, useJourneyLayoutContext} from "../../layouts/journey-layout/journey-layout-context-provider";
import {Event} from "../../apis/event-analytics";
import {useTags} from "../../hooks/event-analytics";
import {useGetOrderStatus} from "../../hooks/order-management";
import {CircleLoader} from "@ntpkunity/controls";

export const ContractingControl: FC<{}> = ({ }) => {
  const theme = useTheme()
  const { state: { order } } = useOrderContext()
  const appState = useAppContext();
  const PLACEHOLDERS = appState.state.language.placeholders;
  const [approvedDoc, setApprovedDoc] = useState<boolean>(false)
  const { dispatch: pageDispatch } = useJourneyLayoutContext()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const { mutate: createTags } = useTags();
  const {data: orderStatus, isLoading: orderStatusLoading}= useGetOrderStatus(order?.reference_number)
  const queryClient = useQueryClient()

  if(orderStatus && orderStatus?.status===OrderStatus.CANCELLED ){
    queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, order?.reference_number]);

  }

  const { data: documentPackages } = useGetAllDocumentPackages({
        referenceId: order?.reference_id,
        orderId: order?.reference_number,
        documentType: ['Contract', 'General']
    })
  useEffect(() => {
        const allSigned = documentPackages?.every(
          f => f.type === DocumentPackageTypes.CONTRACT && [DocumentPackageStatus.Signed, DocumentPackageStatus.SignatureNotRequired].includes(f.status)
        );

        if (allSigned) {
          pageDispatch({
            type: pagesActionType.UPDATE_CONTRACTING,
            payload: {
              success: true,
              error: false
            }
        });

            const event: Event = {
                session: user_session,
                slug: appState?.state?.slug,
                event_name: Tags.CONTRACTING,
                user_id: user_profile?.user_id,
                dealer_id: +order?.dealer_id,
                vehicle_make: order?.order_asset?.make,
                vehicle_model: order?.order_asset?.model,
                order_id: order?.identifier,
                vin: order?.vin,
                page_type: PageType.ScheduleAppointments
            }
            createTags(event)
        }
    }, [documentPackages])

  useEffect(() => {
    if(approvedDoc) {
      queryClient.invalidateQueries([QueryKeys.GET_ALL_DOCUMENT_PACKAGES, order?.reference_id]);
      setApprovedDoc(false)
    }
  }, [approvedDoc]);

  const handleDocumentPackage = (documentPackage: IDocumentPackage) => {
    switch (documentPackage.type) {
      case DocumentPackageTypes.CONTRACT:
        return documentPackage?.documents?.length > 0 && <DocumentPackage documentPackage={documentPackage} PLACEHOLDERS={PLACEHOLDERS} key={documentPackage?.identifier} setApprovedDoc={setApprovedDoc} />
      case DocumentPackageTypes.GENERAL:
        return documentPackage?.documents?.length > 0 && <GeneralDocument documentPackage={documentPackage} PLACEHOLDERS={PLACEHOLDERS} key={documentPackage?.identifier} />
    }
  }

  const sortDocumentPackages = (documentPackages: IDocumentPackage[]) => {
    return documentPackages.sort((a, b) => {
      if (a.type === DocumentPackageTypes.CONTRACT && b.type === DocumentPackageTypes.GENERAL) {
        return -1;
      }
      if (a.type === DocumentPackageTypes.GENERAL && b.type === DocumentPackageTypes.CONTRACT) {
        return 1;
      }
      return 0;
    });
  };

  return (
      orderStatusLoading  ? <CircleLoader theme={theme} size='lg' />
          :
          <PageWrap theme={theme} className='page-wrap'>
      <PageHeader
        marginBottom={3}
        title={PLACEHOLDERS.CONTRACTING_PAPERWORK_DONE}
        subTitle={
          <>
            {PLACEHOLDERS.CONTRACTING_READ_CONTRACT}{" "}
            <a href="javascript:void(0)" className="text-primary decoration-none" aria-label={PLACEHOLDERS.CONTRACTING_CONTACT_US}>{PLACEHOLDERS.CONTRACTING_CONTACT_US}</a>
            {` ${PLACEHOLDERS.CONTRACTING_CONFUSION_HELP}`}
            {PLACEHOLDERS.DOT_TEXT}
          </>
        }
      />
      {sortDocumentPackages(documentPackages || [])?.map((documentPackage) => {
        return handleDocumentPackage(documentPackage)
      })}
    </PageWrap>
  )
}