import { ContractingControl } from '@libraries'
import { DocumentPackageStatus, DocumentPackageTypes } from 'constants/enums'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { useOrderContext } from 'pages/(order-management)/order/order-context-provider'
import { useEffect } from 'react'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags, PageType } from 'constants/enums';
import { user_session } from '@constants'
import { useParams } from 'react-router-dom'
import { useGetAllDocumentPackages } from '@hooks'

export const Contracting = () => {
    const { dispatch: pageDispatch } = useJourneyLayoutContext()
    const { state: { order } } = useOrderContext()
    const { tenant } = useParams()
    const user_profile = JSON.parse(localStorage.getItem("user_profile"))
    const { mutate: createTags } = useTags();
    const { data: documentPackages } = useGetAllDocumentPackages({
        referenceId: order?.reference_id,
        orderId: order?.reference_number,
        documentType: ['Contract', 'General']
    })

    useEffect(() => {
        const allSigned = documentPackages?.every(
          f => f.type === DocumentPackageTypes.CONTRACT && f.status === DocumentPackageStatus.Signed
        );

        if (allSigned) {
          pageDispatch({
            type: pagesActionType.UPDATE_CONTRACTING,
            payload: {
              success: true,
              error: false
            }
        });

            const event: Event = {
                session: user_session,
                slug: tenant,
                event_name: Tags.CONTRACTING,
                user_id: user_profile?.user_id,
                dealer_id: +order?.dealer_id,
                vehicle_make: order?.order_asset?.make,
                vehicle_model: order?.order_asset?.model,
                order_id: order?.identifier,
                vin: order?.vin,
                page_type: PageType.ScheduleAppointments
            }
            createTags(event)
        }
    }, [documentPackages])

    return (
        <ContractingControl />
    )
}