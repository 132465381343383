import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductAddBundleCardWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-detail': {
        '.cj-card': {
            padding: '24px 24px 24px 32px',
            borderRadius: 16,
            border: '1px solid ' + theme.palette.grey[100],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'stretch',
            gap: 24,
            // transition: '0.4s',
            [theme.breakpoints.down('md')]: {
                padding: '24px',
            },
            ".cp": {
                cursor: 'auto',
                ".title": {
                    display: 'flex',
                    gap: 8
                },
            },
            ".pd-listing": {
                display: 'flex',
                alignItems: 'center',
                gap: 4
            }
        }
    }
}))
