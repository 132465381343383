import { useTheme } from '@mui/material'
import {
  Icon,
  Box,
  Typography,
  Button,
  Image
} from '@ntpkunity/controls'
import clsx from 'clsx'
import { ProductCardWrap } from './product-card.style'
import {formatUrl} from "@ntpkunity/controls-common";


export const ProductCard = ({productImg, productbg, title, subtitle, catagory, onClick}) => {
  const theme = useTheme()
  const dealerInformation = JSON.parse(
    localStorage.getItem("dealer_information")
  );
  const { dealer_address, trading_as ,website } =
  dealerInformation?.dealer || {};


  return (
    <ProductCardWrap theme={theme} className='product-wrap'>
      <Box theme={theme} className='product-card'>
        {productbg && 
          <Box theme={theme} className='product-card-bg'>
            <Image 
                theme={theme}
                src={productbg}
                aspectRatio='16:9'
                alt='image'
            />
          </Box>
        }
        <Box theme={theme} className='card-content'>
          <Box theme={theme} width={'100%'}>
            {productImg && 
              <Box theme={theme} className={clsx({ 'product-card-fg': true, 'no-bg': productbg === false })}>
                <Image 
                  theme={theme}
                  src={productImg}
                  aspectRatio='16:9'
                  alt='image'
                />
              </Box>
            }
            <Box theme={theme} className='text-items'>
              {subtitle &&
                  <Typography theme={theme} className='subtitle text-muted fw-medium' variant='body1' component={'p'} display={'block'}>{subtitle}</Typography>
              }
              {title &&
                <Box theme={theme} className='title'>
                  <Typography theme={theme} variant='h3' component={'h3'} display={'block'}>{title}</Typography>
                  {onClick && 
                    <Button defaultBtn theme={theme} onClick={onClick} iconText={<Icon name="CicleLinkIcon" />} ariaLabel='circle link icon' />
                  }
                </Box>
              }
              <Box theme={theme} className="catagories" >
                  <a className="link" href={formatUrl(website)} rel="noreferrer" target="_blank">
                    {trading_as}
                  </a>
                  <a className="link">
                    {" "}
                    {dealer_address?.city}{" "}
                    {dealer_address?.state_name ? `, ${dealer_address.state_name}` : ""}
                  </a>
                </Box>
              {/* {catagory &&
                <Box theme={theme} className='catagories'>
                  <Link variant='caption' component={'small'} display={'block'}>{catagory}</Link>
                </Box>
              } */}
            </Box>
          </Box>
        </Box>
      </Box>
    </ProductCardWrap>
  )
}
