import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {EmploymentType} from "@constants";
import {setMoveInDate} from "../../helpers/methods";

export const getDefaultFormValues = (employmentInfo: any) => {
  if (!employmentInfo) return {
      address:{ address_type: 'Employment'
      }
  };

  const { address, employed_since } = employmentInfo;

  return {

    ...employmentInfo,
    employed_since: setMoveInDate(employed_since),
    address: address ? {
        ...address,
        address_type: 'Employment',
        state_id: address?.state_id ?? null,
    }: null,

  };
};
export function useEmploymentDetailsForm(employmentInfo: any, PLACEHOLDERS: any, formStates: any) {
  function createValidationSchema() {
    const schema = yup.object().shape({
      employment_type: yup.string().nullable().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_EMPLOYMENT_TYPE_REQUIRED),
      name: yup
        .string()
        .nullable()
        .when('employment_type', {
          is: EmploymentType.Employed || EmploymentType.Self_Employed,
          then: yup.string().nullable().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_EMPLOYER_NAME_REQUIRED).max(60, PLACEHOLDERS.EMPLOYMENT_DETAILS_EMPLOYER_NAME_MAX_LENGTH)
        }),
      phone: yup
        .string()
        .nullable()
        .when('employment_type', {
          is: EmploymentType.Employed,
          then: yup.string().nullable().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_EMPLOYER_PHONE_REQUIRED)
            .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, PLACEHOLDERS.EMPLOYMENT_DETAILS_VALID_EMPLOYER_PHONE_REQUIRED)
        }),
      occupation: yup
        .string()
        .nullable()
        .when('employment_type', {
          is: EmploymentType.Employed || EmploymentType.Self_Employed,
          then: yup.string().nullable().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_OCCUPATION_REQUIRED).max(60, PLACEHOLDERS.EMPLOYMENT_DETAILS_OCCUPATION_MAX_LENGTH)
        }),
      employed_since: yup
        .string()
        .nullable()
        .when('employment_type', {
          is: EmploymentType.Employed || EmploymentType.Self_Employed,
          then: yup.string().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_EMPLOYED_SINCE_DATE_REQUIRED)
          .matches(/^(0[1-9]|1[0-2])\d{4}$/, PLACEHOLDERS.EMPLOYMENT_DETAILS_EMPLOYED_SINCE_DATE_FORMAT)
        }),
      annual_employment_income: yup
        .number()
        .nullable()
        .when('employment_type', {
          is: EmploymentType.Employed || EmploymentType.Self_Employed,
          then: yup.number().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_ANNUAL_EMPLOYMENT_INCOME_REQUIRED).typeError(PLACEHOLDERS.EMPLOYMENT_DETAILS_ANNUAL_EMPLOYMENT_INCOME_NUMBER)
        }),
      annual_other_income: yup
        .number()
        .nullable()
        .required(PLACEHOLDERS.EMPLOYMENT_DETAILS_ANNUAL_OTHER_INCOME_REQUIRED)
        .typeError(PLACEHOLDERS.EMPLOYMENT_DETAILS_ANNUAL_OTHER_INCOME_NUMBER),
      source_of_other_income: yup
        .string()
        .nullable()
        .when('annual_other_income', {
          is: (value :any) => value > 0,
          then: yup.string().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_SOURCE_OF_OTHER_INCOME_REQUIRED).max(100, PLACEHOLDERS.EMPLOYMENT_DETAILS_SOURCE_OF_OTHER_INCOME_MAX_LENGTH)
        }),
      address: yup.object().when('employment_type', {
        is: (employmentType) => employmentType == EmploymentType.Employed || employmentType == EmploymentType.Self_Employed,
        then: yup.object().shape({
          address_line_1: yup.string().nullable().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_ADDRESS_LINE_1_REQUIRED),
          city: yup.string().nullable().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_CITY_REQUIRED),
          state_name: yup.string().nullable().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_STATE_NAME_REQUIRED),
          zip_code: yup.string().nullable().required(PLACEHOLDERS.EMPLOYMENT_DETAILS_ZIP_CODE_REQUIRED)
        }),
        otherwise: yup.object().shape({})
      })
    })

    return schema
  }

  const validationSchema = createValidationSchema();


  const employmentForm = useForm<any>({
    defaultValues: formStates?.['employment']?.data || getDefaultFormValues(employmentInfo),
    resolver: yupResolver(validationSchema),
    mode: 'all'
  });

  const watchEmploymentFields = employmentForm.watch();

  return { employmentForm, watchEmploymentFields };
}
