import { FC, useContext, useEffect, useState } from 'react'
import { Divider, useTheme, TextField, Button } from '@mui/material'
import { Typography, FileDragDrop, FilePreview, DatePicker, Box, Grid, Input, FileUpload, FileUploadPreview, Autocomplete, Icon } from '@ntpkunity/controls'
import { Controller } from 'react-hook-form'
import { IOrderInsurance } from '@models'
import AddressDetailsComponent from '../../libraries/insurance-control/customer-details/address-detail'
import { FileUploader, Stack } from '@components'
import { InsuranceFormWrap } from './insurance.style'
import { AppContext } from '@app/context-provider'
import { http } from "@utilities";
import  { createFilterOptions } from "@mui/material/Autocomplete";
import { Endpoint } from '@constants'
import { useGetInsuranceCompanies } from 'hooks/insurance-management'


export const Insurance: FC<{
	InsuranceForm?: any
	formData?: IOrderInsurance
}> = ({ InsuranceForm }) => {
	const theme = useTheme()
	const appState = useContext(AppContext)
	const PLACEHOLDERS = appState.state.language.placeholders
	


	const {
		control,
		setValue,
		watch,
		clearErrors,
		formState: { errors },
	} = InsuranceForm
	const image = watch('image')
	const [imageFiles, setImageFiles] = useState<any[]>([])
	const [insuranceProviders, setInsuranceProviders] = useState<any[]>([])

	const onimageUpload = (e: any) => {
		if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
			addFilesForPreview(e.target.files)
			clearErrors('insurance_documents')
		}
	}
	const onimageDrop = (e: any) => {
		if (e && e?.dataTransfer && e?.dataTransfer?.files && e?.dataTransfer?.files && e?.dataTransfer?.files.length > 0) {
			addFilesForPreview(e.dataTransfer.files)
			clearErrors('insurance_documents')
		}
	}
   
const addFilesForPreview = async (files: FileList) => {
    const newImagesArray: any[] = [];

    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        try {
            const result = await readFileAsDataURL(file);
			const base64Content = result.split(',')[1];
            newImagesArray.push({
                document: base64Content,
                content_type: file.type,
                name: file.name,
                file_size: `${(file.size / 1024).toFixed(2)}KB`,
                created_by: 'null',
            });
        } catch (error) {
            console.error('Error reading file:', error);
        }
    }

    const currentDocuments = InsuranceForm.getValues('insurance_documents') || [];
    
    setImageFiles((prevFiles) => [...prevFiles, ...newImagesArray]); // Append new files
    setValue('insurance_documents', [...currentDocuments, ...newImagesArray]); // Append to form field
};

	const readFileAsDataURL = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()

			fileReader.onload = (event) => {
				if (event.target && event.target.result) {
					resolve(event.target.result.toString())
				} else {
					reject('Failed to read file as data URL.')
				}
			}

			fileReader.onerror = () => {
				reject('Error reading file as data URL.')
			}

			fileReader.readAsDataURL(file)
		})
	}

	const onRemoveFile = (idx: string) => {
		const _index = Number(idx)
		const newImagesArray = imageFiles.filter((_obj: any, index: number) => index !== _index)
		setImageFiles(newImagesArray)
		const formImages = InsuranceForm.getValues('insurance_documents').filter((_obj: any, index: number) => index !== _index)
		setValue('insurance_documents', formImages)
	}
	const base64ToFile = (base64Strings: any[]): File[] => {
		return base64Strings
			?.map((base64String, index) => {
				const matches = base64String?.document?.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/)
				if (!matches || matches.length !== 3) {
					console.error(`Invalid Base64 string format for element at index ${index}.`)
					return null
				}
				const mime = matches[1]
				const bstr = matches[2]
				let n = bstr.length
				const u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				return new File([u8arr], base64String.name || `file_${index + 1}`, {
					type: mime,
				})
			})
			.filter((file) => file !== null) as File[]
	}
  
  const [providerName, setProviderName] = useState<any>(null);
  const [providerContactNumber, setProviderContactNumber] = useState<any>(null);
  const {data: insuranceCompanies } = useGetInsuranceCompanies(appState?.state?.tenant)

  const filter = createFilterOptions<any>();
	useEffect(() => {
		if (InsuranceForm?.getValues('insurance_documents')?.length > 0) {
			const filesArray = base64ToFile(InsuranceForm?.getValues('insurance_documents'))
			setImageFiles(filesArray)
		}
	}, [InsuranceForm?.getValues('insurance_documents')])

	useEffect(() => {
		setInsuranceProviders(insuranceCompanies)
	}, [insuranceCompanies]);

	return (
    <InsuranceFormWrap className="insurance-form-wrap" theme={theme}>
      <Stack bgLight paddingMd={3}>
        <Grid theme={theme} container item spacing={3}>
          <Grid item theme={theme} xs={12}>
            <Controller
              name="insurer_name"
              control={control}
              render={({ field }) => {
              return (
                <Autocomplete
                  value={providerName}
                  {...field}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.insurance_provider_name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        insurance_provider_name: `${inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  id={`${PLACEHOLDERS.INSURANCE_PROVIDER_NAME}`}
                  items={insuranceProviders}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.insurance_provider_name;
                  }}
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        {option.insurance_provider_name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${PLACEHOLDERS.INSURANCE_PROVIDER_NAME}`}
                      placeholder={PLACEHOLDERS.INSURANCE_ADD_HERE}
                      error={!!errors?.insurer_name?.message} // Show error state
                      helperText={errors?.insurer_name?.message} // Display error message
                    />
                  )}
                  onChange={(event, newValue: any) => {
                    if (
                      newValue?.insurance_provider_name !== null ||
                      newValue?.insurance_provider_name !== undefined
                    ) {
                      InsuranceForm.setValue(
                        "insurer_name",
                        newValue?.insurance_provider_name
                      );
                    }
                    if (newValue?.insurance_provider_name === undefined) {
                      InsuranceForm.setValue("insurer_name", newValue);
                    }

                    if (
                      InsuranceForm.getValues("insurer_name") !== null ||
                      InsuranceForm.getValues("insurer_name") !== undefined
                    ) {
                      setProviderName(InsuranceForm.getValues("insurer_name"));
                      if (insuranceProviders.length > 0) {
                        const provider = insuranceProviders.find(
                          (provider) =>
                            provider.insurance_provider_name ===
                            InsuranceForm.getValues("insurer_name")
                        );
                        if (provider) {
                          setProviderContactNumber(provider.contact_number);
                          InsuranceForm.setValue(
                            "agent_phone",
                            provider.contact_number
                          );
                        } else {
                          InsuranceForm.setValue("agent_phone", "");
                        }
                      }
                    }
                  }}
                  freeSolo
                  theme={theme}
                  disablePortal={true}
                  placeholder={PLACEHOLDERS.INSURANCE_PROVIDER_NAME}
                />
              );}
            }
            />
          </Grid>
          <Grid item theme={theme} xs={12}>
            <Controller
              name="agent_phone"
              control={control}
              render={({ field }) => {
              return (
                <Input
                  theme={theme}
                  fullWidth
                  label={PLACEHOLDERS.INSURANCE_CONTACT}
                  placeholder={PLACEHOLDERS.INSURANCE_ADD_HERE}
									type='text'
                  {...field}
                  error={errors?.agent_phone?.message}
                ></Input>
              )}
            }
            />
          </Grid>
          <Grid item theme={theme} xs={12}>
            <Controller
							name='policy_number'
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  label={`${PLACEHOLDERS.INSURANCE_POLICY_NUMBER_OPTIONAL}`}
                  placeholder={PLACEHOLDERS.INSURANCE_ADD_HERE}
									type='text'
                  {...field}
                  error={errors?.policy_number?.message}
                ></Input>
              )}
            />
          </Grid>
          <Grid item theme={theme} xs={12}>
            <Controller
							name='expiration_date'
              control={control}
							defaultValue=''
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={`${PLACEHOLDERS.INSURANCE_EXPIRATION_OPTIONAL}`}
                  disablePast
                  disablePortal={true}
                  theme={theme}
									value={value ?? ''}
                  onChange={(e: any) => {
										if (e instanceof Date && e.getFullYear() === 1970 && e.getMonth() === 0 && e.getDate() === 1) {
											onChange(null)
                    } else {
											onChange(e.toISOString())
                    }
                  }}
                  error={errors.expiration_date?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack bgLight paddingMd={3} marginTopXs={2} marginTopMd={2}>
				<Typography theme={theme} variant='subtitle1' component='p'>
          {PLACEHOLDERS.INSURANCE_PROOF}
        </Typography>
				<Typography theme={theme} variant='caption' className='text-muted' component='span' display={'block'}>
          {PLACEHOLDERS.INSURANCE_SUPPORTED_FORMATS}
        </Typography>
				<Box theme={theme} className='insurance-file-upload' mt={3}>
          <FileUploader>
            <Controller
							name='insurance_documents'
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <FileUpload
                  {...field}
                  theme={theme}
									width='300'
									accept= '.pdf, .jpg, .jpeg, .png'
                  hoverLabel={
                    <>
											{PLACEHOLDERS.FILE_DRAG_DROP_HOVER_LABEL_1} <span className='text-primary'>{PLACEHOLDERS.FILE_DRAG_DROP_HOVER_LABEL_2}</span>
                    </>
                  }
									height='243px'
									backgroundColor='white'
                  onDrop={onimageDrop}
                  onChange={onimageUpload}
                  allowMultiple={true}
                  inlinePreview={false}
                  error={errors?.insurance_documents?.message}
                />
              )}
            />
            {imageFiles && imageFiles.length > 0 && imageFiles[0] != null && (
              <FileUploadPreview
                files={
                  imageFiles.length > 0
                    ? imageFiles.map((image, index) => ({
                        key: `${index}`,
                        file: image,
                        onRemoveFile: (event, index) => {
													onRemoveFile(event)
                        },
                      }))
                    : []
                }
                theme={theme}
              />
            )}
          </FileUploader>
        </Box>
      </Stack>
      <Stack bgLight paddingMd={3} marginTopXs={2} marginTopMd={2}>
				<Typography theme={theme} variant='subtitle1' component='p'>
          {PLACEHOLDERS.INSURANCE_PROVIDER_ADDRESS}
        </Typography>
				<Box theme={theme} className='insurance-address' mt={2}>
          <AddressDetailsComponent
            form={InsuranceForm}
            isGoogleEnabled={false}
						addressValue={'insurer_address'}
            moveIn={false}
            errorProps={errors.insurer_address}
            control={control}
						contactName={'agent_phone'}
            contactError={errors?.agent_phone?.message}
            showContactNumber={false}
          />
        </Box>
      </Stack>
      
    </InsuranceFormWrap>
  );
}
