import { useTheme } from '@mui/material'
import { Controller, SubmitHandler } from 'react-hook-form'
import { Stack, Textbox } from '@components'
import { FC, useContext, useEffect, useReducer, useState } from 'react'
import { Box, Button, CircleLoader, Grid, Icon, Input, Select, Snackbar, Typography } from '@ntpkunity/controls'
import AddressDetailsComponent from '../../insurance-control/customer-details/address-detail'
import { AppContext } from '@app/context-provider'
import { IAddress, IEmploymentDetails } from '@interfaces'
import { useGetCurrencies, SaveEmploymentDetails, useUpdateEmploymentInfo } from '@hooks'
import { loadDefaultCurrencyConfigurations } from '@app/context-provider'
import { isEmpty} from '@helpers'
import { EmploymentType } from '@constants'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { FinanceTab } from '@constants'
import { IntegrationProvider, LenderIntegrationType } from 'constants/providers'
import { useGetIntegrationByProviderName } from 'hooks/order-management'
import { PageWrap } from '@libraries'
import {useFormContext} from "../../../pages/(order-management)/order/form-context-provider";
import {getEmployedSinceDate} from "../../../helpers/methods";
import { SnackbarUtility } from '@utilities'
import { TaggingClasses } from 'constants/enums'

export const defaultValuesCustomer = {
  employment_type: '',
  name: '',
  phone: '',
  occupation: '',
  employed_since: '',
  annual_employment_income: 0,
  annual_other_income: 0,
  source_of_other_income: '',
  reference_id: ''
}

interface IEmploymentDetailsComponentProps {
  setTab: (value: number) => void
  employInfoData?: any
  customerReferenceId?: string
  form: any
}

export const EmploymentDetailsComponent: FC<IEmploymentDetailsComponentProps> = ({
  setTab,
  employInfoData,
  customerReferenceId,
  form
}) => {
  const theme = useTheme()
  const { dispatch: pageDispatch } = useJourneyLayoutContext()
  const appState = useContext(AppContext);
  const defaultCurrency = appState.state.default_currency_symbol
  const PLACEHOLDERS = appState.state.language.placeholders
  const company_id = appState.state.tenant
  const { data: currencyData } = useGetCurrencies(appState?.state?.slug)
  const { mutate: getProvider} = useGetIntegrationByProviderName( IntegrationProvider.GOOGLE,
    LenderIntegrationType.ADDRESS_VALIDATION,company_id,appState?.state?.slug );
  const [errorDialog, setErrorDialog] = useState<any>({ visible: false, error: '' })
  const { mutate: addEmploymentData } = SaveEmploymentDetails()
  const [months, setMonths] = useState(0)
  const [years, setYears] = useState(0)
  const [isAddressDirty, setIsAddressDirty] = useState(false)
  const { mutate: updateEmployInfoReferenceId,isLoading: updateEmployInfoReferenceIsLoading} = useUpdateEmploymentInfo()
  const [isVerified, setIsVerified] = useState(false)
  const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)
  const {formStates, resetFormState} = useFormContext()
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  useEffect(() => {
    const defaultCurrency = currencyData?.find(
      (currency: any) => currency.id === appState.state.default_currency_symbol
    )
    appState.dispatch(loadDefaultCurrencyConfigurations(defaultCurrency))
  }, [currencyData])


  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors, isDirty }
  } = form


  useEffect(() => {
    if (getValues('address')?.verified || !isGoogleEnabled) {
      setIsVerified(true)
    }

  }, [getValues('address')])


  useEffect(() => {
    if(company_id)
   { getProvider(
      {
        provider_name: IntegrationProvider.GOOGLE,
        integration_type: LenderIntegrationType.ADDRESS_VALIDATION,
        company_id:company_id
      },
      {
        onSuccess(response: any) {
          setIsGoogleEnabled(response?.is_active)
        }
      }
    )}
  }, [company_id])

  function handleEmployedSince(e: any) {
    const [inputMonth, inputYear] = e?.target?.value
      ?.toString()
      ?.split('/')
      ?.map((num: string) => parseInt(num))
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    if (inputMonth > 12 || inputMonth === 0 || inputYear === 0) {
      setErrorDialog({ visible: true, error: PLACEHOLDERS.FINANCING_VALID_EMPLOYED_SINCE_DATE })
      return
    }

    if (inputYear > currentYear || (inputYear >= currentYear && inputMonth > currentMonth)) {
      setErrorDialog({ visible: true, error: PLACEHOLDERS.FINANCING_EMPLOYED_SINCE_FUTURE_DATE_INVALID })
      return
    }
    setErrorDialog({ visible: false, error: '' })
    if (inputMonth > 0 && inputYear > 0) {
      calculateDateDifference(inputMonth, inputYear)
    }
  }

  function calculateDateDifference(inputMonth: number, inputYear: number): void {
    const currentDate = new Date()
    const months = currentDate.getMonth() + 1
    const yearDiff = currentDate.getFullYear() - inputYear
    const monthDiff = months - inputMonth

    if (monthDiff < 0) {
      setYears(yearDiff - 1)
      setMonths(12 + monthDiff)
    } else {
      setYears(yearDiff)
      setMonths(monthDiff)
    }
  }


  const onSubmit: SubmitHandler<any> = (formData) => {
    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Employment details saving!'))
      formData.address.verified==true

    let { annual_other_income, source_of_other_income } = formData
    const payload = {
      ...formData,
      reference_id: customerReferenceId,
      annual_other_income: parseInt(annual_other_income),
      source_of_other_income,
      annual_employment_income: parseInt(formData.annual_employment_income),
      employed_since: getEmployedSinceDate(getValues('employed_since'))
    }

    if (isEmpty(employInfoData)) {
      addEmploymentData(payload, {

        onSuccess: (__response: IEmploymentDetails) => {
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Employment details saved!'))

          resetFormState('employment')
          setTab(FinanceTab['Personal-Finance-Details'])
          pageDispatch({
            type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
            payload: {
              success: true,
              error: false
            }
          })
        },
        onError(__error: any) {
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Employment details saving failed!'))
          setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); });

          pageDispatch({
            type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
            payload: {
              success: false,
              error: true
            }
          })
        }
      })
    } else if (isDirty || isAddressDirty) {
      updateEmployInfoReferenceId(payload, {

        onSuccess: (__response: IEmploymentDetails) => {
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Employment details updated!'))
          resetFormState('employment')

          setTab(FinanceTab['Personal-Finance-Details'])
          pageDispatch({
            type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
            payload: {
              success: true,
              error: false
            }
          })
        }
      })
    } else {
      setTab(4)
    }
  }

  useEffect(() => {
    if (Object.keys(errors).length) {
      pageDispatch({
        type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
        payload: {
          success: false,
          error: true
        }
      })
    }
  }, [errors])

  return (
    <>
      <PageWrap theme={theme} className='detail employment-detail'>
        <Stack marginTopXs={3} className='scroll-horizontal'>
          <Typography theme={theme} component={'h4'} variant='h4'>{PLACEHOLDERS.FINANCING_WORK_HISTORY_REQUEST}</Typography>
          <Grid theme={theme} container item columnSpacing={2} rowSpacing={3} mt={0}>
            <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={'employment_type'}
                defaultValue={''}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={PLACEHOLDERS.FINANCING_EMPLOYMENT_TYPE_FIELD_LABEL}
                    items={Object.entries(EmploymentType).map((item) => ({
                      text: item[1],
                      value: item[1]
                    }))}
                    sxProps={''}
                    value={value}
                    selectError={errors?.employment_type?.message?.toString()}
                    onChange={(e: any) => {
                      onChange(e)
                    }}
                  />
                )}
              />
            </Grid>
            {(watch('employment_type') === EmploymentType.Employed || watch('employment_type') === EmploymentType.Self_Employed) && (
              <>
                <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                  <Textbox>
                    <Controller
                      name={'name'}
                      defaultValue={''}
                      control={control}
                      render={({ field }) => (
                        <Input
                          type={'text'}
                          fullWidth theme={theme}
                          placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                          label={PLACEHOLDERS.FINANCING_EMPLOYER_NAME_FIELD_LABEL}
                          {...field}
                          error={errors?.name?.message}
                        />
                      )}
                    />
                  </Textbox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                  <Textbox>
                    <Controller
                      name={'occupation'}
                      defaultValue={defaultValuesCustomer.occupation}
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          placeholder={PLACEHOLDERS.FINANCING_OCCUPATION_FIELD_LABEL}
                          label={PLACEHOLDERS.FINANCING_OCCUPATION_FIELD_LABEL}
                          type="text"
                          {...field}
                          error={errors?.occupation?.message?.toString()}
                        />
                      )}
                    />
                  </Textbox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                  <Textbox>
                    <Controller
                      name={'employed_since'}
                      control={control}
                      defaultValue={''}
                      render={({ field }) => (
                        <Input
                          masking={true}
                          maskNumeric={true}
                          format="##/####"
                          theme={theme}
                          fullWidth
                          placeholder={PLACEHOLDERS.FINANCING_EMPLOYED_SINCE_FIELD_LABEL}
                          label={PLACEHOLDERS.FINANCING_EMPLOYED_SINCE_FIELD_LABEL}
                          {...field}
                          onBlur={(e) => {
                            field.onBlur()
                            handleEmployedSince(e)
                          }}
                          type={'text'}
                          helperText={
                            (errors?.employed_since?.message?.toString() ?? '').length <= 0 &&
                              errorDialog?.visible == false
                              ? `${PLACEHOLDERS.FINANCING_DURATION}: ${years.toString().padStart(2, '0')} ${PLACEHOLDERS.FINANCING_DURATION_YEARS_LOWERCASE}, ${months
                                .toString()
                                .padStart(2, '0')} ${PLACEHOLDERS.FINANCING_DURATION_MONTHS_LOWERCASE}`
                              : ''
                          }
                          error={
                            errorDialog?.visible == false
                              ? errors?.employed_since?.message?.toString()
                              : errorDialog?.error
                          }
                        />
                      )}
                    />
                  </Textbox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                  <Textbox>
                    <Controller
                      name={'annual_employment_income'}
                      defaultValue={0}
                      // rules={validation('Annual Employment Income', true)}
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                          label={PLACEHOLDERS.FINANCING_GROSS_ANNUAL_INCOME_FIELD_LABEL}
                          type="text"
                          maskNumeric={true}
                          masking={true}
                          startAdornment={defaultCurrency}
                          maskDecimalScale={2}
                          {...field}
                          error={errors?.annual_employment_income?.message?.toString()}
                        />
                      )}
                    />
                  </Textbox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                  <Textbox>
                    <Controller
                      name={'annual_other_income'}
                      defaultValue={0}
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          placeholder={PLACEHOLDERS.EMPLOYMENT_INFO_ANNUAL_OTHER_INCOME_TEXT}
                          label={PLACEHOLDERS.EMPLOYMENT_INFO_ANNUAL_OTHER_INCOME_TEXT}
                          type="text"
                          maskNumeric={true}
                          masking={true}
                          startAdornment={defaultCurrency}
                          maskDecimalScale={2}
                          {...field}
                          error={errors?.annual_other_income?.message?.toString()} />
                      )}
                    />
                  </Textbox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                  <Textbox>
                    <Controller
                      name={'source_of_other_income'}
                      defaultValue={''}
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                          label={PLACEHOLDERS.FINANCING_SOURCE_OF_OTHER_INCOME_OPTIONAL_FIELD_LABEL}
                          type="text"
                          {...field}
                          error={errors?.source_of_other_income?.message?.toString()}
                        />
                      )}
                    />
                  </Textbox>
                </Grid>
              </>
            )}
            {(watch('employment_type') !== EmploymentType.Employed && watch('employment_type') !== EmploymentType.Self_Employed) && (
              <>
                <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                  <Textbox>
                    <Controller
                      name={'annual_other_income'}
                      defaultValue={0}
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          placeholder={PLACEHOLDERS.EMPLOYMENT_INFO_ANNUAL_OTHER_INCOME_TEXT}
                          label={PLACEHOLDERS.EMPLOYMENT_INFO_ANNUAL_OTHER_INCOME_TEXT}
                          type="text"
                          maskNumeric={true}
                          masking={true}
                          startAdornment={defaultCurrency}
                          maskDecimalScale={2}
                          {...field}
                          error={errors?.annual_other_income?.message?.toString()}
                        />
                      )}
                    />
                  </Textbox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                  <Textbox>
                    <Controller
                      name={'source_of_other_income'}
                      defaultValue={''}
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                          label={PLACEHOLDERS.FINANCING_SOURCE_OF_OTHER_INCOME_OPTIONAL_FIELD_LABEL}
                          type="text"
                          {...field}
                          error={errors?.source_of_other_income?.message?.toString()}
                        />
                      )}
                    />
                  </Textbox>
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
        {(watch('employment_type') !== EmploymentType.Retired && watch('employment_type') !== EmploymentType.Not_Employed) && (
          <Stack marginTopXs={3} className='scroll-horizontal'>
          <Box theme={theme} display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
            <Typography theme={theme} component={'h4'} variant='h4'>{PLACEHOLDERS.FINANCING_WORKPLACE_QUESTION}</Typography>
            {isGoogleEnabled && isVerified ? (
              <Box theme={theme} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography theme={theme} component={'p'} variant='body2' className='fw-medium text-muted' display={{ xs: 'none', sm: 'none', md: 'block'}}>{PLACEHOLDERS.FINANCING_ADDRESS_VERIFIED}</Typography>
                <Icon name="GreenCheckIcon" />
              </Box>
            ) : null}
          </Box>
          <AddressDetailsComponent
            form={form}
            isGoogleEnabled={isGoogleEnabled}
            addressValue={'address'}
            setIsAddressDirty={setIsAddressDirty}
            moveIn={false}
            errorProps={errors.address}
            control={control}
            contactName={'phone'}
          />
        </Stack>)}
        {formStates?.['employment']?.isDirty || isAddressDirty ? (
          <Box theme={theme} mt={3}>
            <Button
              className={`${TaggingClasses.FINANCING}-employment-save`}
              type="submit"
              disabled={updateEmployInfoReferenceIsLoading}
              theme={theme}
              primary
              onClick={handleSubmit(onSubmit)}
              text={
              <>
              {updateEmployInfoReferenceIsLoading && <CircleLoader theme={theme} size="xs" />}
              {PLACEHOLDERS.FINANCING_SAVE_AND_CONTINUE}
              </>}
              fullWidth
            />
          </Box>
        ) : null}
      </PageWrap>
      <Snackbar
            theme={theme}
            message={<Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px">
            <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
        </Box> }
            open={snackbarState.open}
            onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
    </>
  )
}