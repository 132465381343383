import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigation } from '../constants/navigation';
import { useAppContext } from '@app/context-provider';
import { Tabs } from 'constants/tabs';

function usePageTitle() {
  const location = useLocation();
  const { state: app_state } = useAppContext()

  useEffect(() => {
    const path = location.pathname;
    let title = 'Default Title';
    const workflow = JSON.parse(localStorage.getItem('workflow') || '{}');
    const currentState = workflow.current_state;
    switch (true) {
      case currentState === Tabs.CONTRACTING:
        title = Tabs.CONTRACTING;
        break;
      case currentState === Tabs.PRICING:
        title = Tabs.PRICING;
        break;
      case currentState === "Order Submitted":
        title = Tabs.SUBMIT_ORDER;
        break;
      case path === Navigation.BASE_PATH:
        title = 'Home';
        break;
      case path === Navigation.AUTHENTICATION.SIGN_IN_PAGE:
      case path === Navigation.AUTHENTICATION.LOG_IN_PAGE:
        title = 'Sign In';
        break;
      case path === Navigation.AUTHENTICATION.OTP_PAGE:
        title = 'OTP Verification';
        break;
      case path === Navigation.INVENTORY_PAGE:
        title = 'Inventory';
        break;
      case path === Navigation.ORDER_PAGE:
        title = 'Deal Management';
        break;
      case path === Navigation.PROFILE.USER_PROFILE:
        title = 'User Profile';
        break;
      case path === Navigation.PROFILE.ORDERS.replace(':tenant', app_state.slug):
          title = 'My Deals';
          break;
      default:
        const lastPart = path.substring(path.lastIndexOf('/') + 1);
        title = lastPart.charAt(0).toUpperCase() + lastPart.slice(1);
    }

    document.title = `Drift - ${title}`;
  }, [location]);
}

export default usePageTitle;