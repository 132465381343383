import { useEffect, useReducer, useState } from "react";
import {
  ActionType,
  FinancingPage,
  FnIProductsPage,
  InsurancePage,
  OrderActionType,
  PaymentContextProvider,
  PaymentsPage,
  RebatesPage,
  ReviewOrderPage,
  SchedulePage,
  TradeInPage,
  useOrderContext,
} from "@pages";
import { updateCollapseState, useAppContext } from "@app/context-provider";
import { user_session, LeadKey, FinanceTypes, US_STATES_ABBREVATION } from "@constants";
import {
  getVehicleDetailByVin,
  useGetCustomerContact,
  useGetPersonalFinance,
  useGetLicenseInformation,
  useGetEmploymentInformation,
  useUpdateLead, useGetAllPrograms, useGetSetupData,
} from "@hooks";
import {
  programData,
  updateLenderId,
} from "../../pages/(order-management)/order/order-context-provider";
import {
  pagesActionType,
  useJourneyLayoutContext,
} from "./journey-layout-context-provider";
import { JourneyLayout } from "@layouts";
import { useTags } from "hooks/event-analytics";
import { Event } from "apis/event-analytics";
import {Icon } from '@ntpkunity/controls'
import {Tags, PageType, PaymentType, TaggingClasses, Stage} from "constants/enums";
import { useParams } from "react-router-dom";
import { AddOn, AlertDialog, ConfirmationDialog, JourneyFooter, PaymentBreakDownPopup } from "@libraries";
import { getNextTabTitle, getTabsClass } from "@helpers";
import { QueryKeys, Tabs } from "@constants";
import { useTheme } from "@mui/material";
import { useQueryClient } from "react-query";
import {
  UseGetFinancialInsuranceProduct,
  useScrollOnTop,
} from "../../hooks/order-management";
import { mapPayload } from "helpers/lead";
import {
  calculateAdjustedTradeInAmount,
  formatUrl,
  generateTabOutput,
  getFormattedPaymentDetails,
} from "../../helpers/methods";
import { Button, NoDataPreview, Snackbar } from "@ntpkunity/controls";
import { useWorkflowContext } from "@app/router-workflow";
import { useFormContext } from "pages/(order-management)/order/form-context-provider";
import { SnackbarUtility } from "@utilities";
import { useModal } from "react-modal-hook";

export const PreOrderJourneyLayoutComponent = ({
  vehicle,
  showPopup,
  orderTabs,
  nextTab,
  handleLinkClick,
  handleCloseDialog,
  onNextTabClick,
  setNextTab,
  children,
}) => {
  const { tenant } = useParams();
  const { mutate: createTags } = useTags();
  const { getOrderWorkflow } = useWorkflowContext();
  const { mutate: updateLead } = useUpdateLead(tenant);
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const { state: pageState, dispatch: pageDispatch } =
    useJourneyLayoutContext();
  const {
    state: { order, activeTab },
    dispatch: orderDispatch,
  } = useOrderContext();
  const dealerData = JSON.parse(localStorage.getItem("dealer_information"))?.dealer
  const [totalSum, setTotalSum] = useState(0);
  const [currentTab, setCurrentTab] = useState(Tabs.PAYMENTS);
  const user_profile = JSON.parse(localStorage.getItem("user_profile"));
  const PLACEHOLDERS = appState?.language?.placeholders;
  const defaultCurrency = appState?.default_currency_symbol;

  const { data: vehicleData, isLoading: vehicleLoading } = getVehicleDetailByVin(
    order?.order_asset?.vin,
    appState?.slug,
    appState?.tenant,
    dealerData?.dealer_code,
  );
  const queryClient = useQueryClient();

  const customerData: any = queryClient.getQueryData(
    QueryKeys.GET_CUSTOMER_BY_EMAIL
  );
  const {
    state: { order: orderState },
    dispatch: orderContextDispatch
  } = useOrderContext();
  const { data: contactData } = useGetCustomerContact(
    customerData?.reference_id
  );
  const { formStates } = useFormContext();
  const { data: personalFinanceData } = useGetPersonalFinance(
    customerData?.reference_id ?? ""
  );
  const setting = JSON.parse(localStorage.getItem("settings"));
  const { data: licenseInformation, isFetched: isLicenseFetched } =
    useGetLicenseInformation(customerData?.reference_id ?? "");
  const { data: employmentInfo, isLoading: employmentInfoLoading } =
    useGetEmploymentInformation(customerData?.reference_id);

  const queryParams = `company_id=${appState.tenant}&setup_data_indicators=credit-ratings&setup_data_indicators=contract-terms&setup_data_indicators=contract-allowed-annum-mileage`

  const { data: setupData, isLoading: setupDataLoading } = useGetSetupData(queryParams, appState?.slug)

  const { mutate: getAllPrograms, data: programdata, isLoading: programLoading } = useGetAllPrograms(appState?.slug)
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
  const payload = {
    dealer_code: dealerData?.dealer_code,
    model_detail_name: order?.order_asset?.trim_description,
    credit_rating: order?.credit_rating,
    make_name: order?.order_asset?.make,
    model_name: order?.order_asset?.model,
    rental_frequency: 'Monthly',
    rental_mode: null,
    terms: order?.contract_term,
    retrieve_all_programs: false,
    company_code: appState?.company_code,
    vin: order?.order_asset?.vin,
    selling_price: order?.order_asset?.unit_price,
    msrp: order?.order_asset?.msrp,
    is_new: order?.order_asset?.type?.toLowerCase() === 'new',
    odometer: order?.order_asset?.mileage || 0,
    total_score: setupData?.['credit-ratings']?.find(item => item.description === order?.credit_rating)?.score_from,
    lender_code: appState?.default_lender?.external_code,
    model_code: order?.order_asset?.model_code
  }
  const { data: allFniProducts } = UseGetFinancialInsuranceProduct(
    `dealer_code=${dealerData?.dealer_code
    }&page_number=${0}&page_size=${1000}&is_active=${true}`,
    appState?.slug,
    appState?.tenant
  );

  useEffect(() => {
    if (order?.order_stage === Stage.APPLICATION)
      setCurrentTab(Tabs.FINANCING)

  }, [order?.order_stage])

  useScrollOnTop(currentTab);
  const { dispatch } = useAppContext();
  const theme = useTheme();
  const [show, hide] = useModal(
    () => (
      <AlertDialog
      title={PLACEHOLDERS.PROGRAM_NOT_FOUND}
      subtitle={PLACEHOLDERS.NO_PROGRAM_SUB_TEXT}
      btnText={PLACEHOLDERS.CLOSE_TEXT}
      isOpen={true}
      onClick={() => hide()}
      onClose={() => hide()}
      />
    ),[]
  )
  useEffect(() => {

    if ((order?.order_asset || vehicle)&& order?.contract_term && order?.annual_usage >= 0 && order?.credit_rating && setupData) {
      getAllPrograms({
        ...payload,
        finance_type: order?.finance_type,
        ...(order?.finance_type === 'Lease' && { annual_usage: order?.annual_usage, annual_mileage: order?.annual_usage }),
      }, {
        onSuccess(data) {
          if (data?.detail) {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.PROGRAM_NOT_FOUND))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
          }
          else {
            appDispatch({
              type: order?.finance_type === FinanceTypes.LEASE ? ActionType.UPDATE_LEASE_PROGRAM : ActionType.UPDATE_FINANCE_PROGRAM,
              payload: data?.[0]
            })
            orderDispatch(programData(data?.[0]))
          }
        }
      })
      const event_detail: Event = {
        session: user_session,
        slug: appState?.slug,
        event_name: order?.finance_type,
        user_id: user_profile?.user_id,
        vehicle_model: payload.model_name,
        vin: order?.vin,
        dealer_id: dealerData?.dealer_id,
        page_type: PageType.Payments,
        data: JSON.stringify(payload),
        deal_type: order?.finance_type,
        vehicle_make: order?.order_asset?.make
      }
      createTags(event_detail)
    }
  }, [order?.contract_term, order?.annual_usage, order?.credit_rating, order?.finance_type, order?.order_asset])

  useEffect(() => {
    orderDispatch(updateLenderId(order?.lender_id ?? appState?.lender_id));
  }, []);

  useEffect(() => {
    if (order?.order_options?.length) {
      const total = order?.order_options
          .filter((option: any) =>
              (option.installation_mode === 'Pre-Installed' && !option.is_price_inclusive) ||
              option.installation_mode === 'Optional'
          )
          .reduce((sum, item) => sum + item.applied_price, 0);

      setTotalSum(total);
    }
  }, [order?.order_options?.length]);


  useEffect(() => {
    if (vehicle) {
      let lead = mapPayload(
        +order?.dealer_id,
        tenant,
        customerData?.reference_id,
        LeadKey.Customer_Detail,
        { email: setting?.email }
      );
      updateLead(lead);
    }
    if (order?.estimated_monthly_payment) {
      let lead = mapPayload(
        +order?.dealer_id,
        tenant,
        customerData?.reference_id,
        LeadKey.Customer_Detail,
        { email: setting?.email }
      );
      updateLead(lead);
    }
  }, [order?.estimated_monthly_payment]);

  useEffect(() => {
    if (vehicleData) {
      const event: Event = {
        session: user_session,
        slug: tenant,
        event_name: Tags.SELECT_VEHICLE,
        user_id: user_profile?.user_id,
        vin: vehicleData.vin,
        vehicle_status: vehicleData.vehicle_status,
      };
      createTags(event);
      const event_detail: Event = {
        session: user_session,
        slug: tenant,
        event_name: Tags.VIEW_DETAILS,
        user_id: user_profile?.user_id,
        vin: vehicleData.vin,
        vehicle_status: vehicleData.vehicle_status,
        page_type: PageType.Payments,
      };
      createTags(event_detail);
    }
  }, [vehicleData]);

  useEffect(() => {
    if (isLicenseFetched && licenseInformation) {
      pageDispatch({
        type: pagesActionType.UPDATE_LICENSE_DETAILS,
        payload: {
          success: true,
          error: false,
        },
      });
    }
    if (
      contactData?.first_name &&
      contactData?.last_name &&
      contactData?.ssn &&
      contactData?.mobile_number
    ) {
      pageDispatch({
        type: pagesActionType.UPDATE_PERSONAL_DETAILS,
        payload: {
          success: true,
          error: false,
        },
      });
    }
    if (personalFinanceData) {
      pageDispatch({
        type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS,
        payload: {
          success: true,
          error: false,
        },
      });
    }
    if (contactData?.customer_addresses.length > 0) {
      pageDispatch({
        type: pagesActionType.UPDATE_RESIDENCE_DETAILS,
        payload: {
          success: true,
          error: false,
        },
      });
    }
    if (employmentInfo) {
      pageDispatch({
        type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
        payload: {
          success: true,
          error: false,
        },
      });
    }
  }, [isLicenseFetched, contactData, personalFinanceData, employmentInfo]);

  const handleGoToInventory = () => {
    if (dealerData?.website) {
      let url = formatUrl(dealerData?.website)
      window.open(url, '_self');
    } else {
      getOrderWorkflow();
    }
  }

  const onTabClick = (tab, setState) => {
    setNextTab(tab);
    setCurrentTab(tab), setState(true);
    dispatch(updateCollapseState());
  };
  const getActiveTab = (tab: string) => {
    const tab_change =
      ((vehicleData?.vehicle_status === "Not Available" || vehicleData?.listing_status == false) && !vehicleLoading || !!vehicle?.detail)
        ? Tabs.NOT_AVAILABLE
        : tab;
    switch (tab_change) {
      case Tabs.ADD_ONS:
        return <AddOn />;
      case Tabs.FINANCING:
        return <FinancingPage />;
      case Tabs.FNI:
        return <FnIProductsPage allFniProducts={allFniProducts}/>;
      case Tabs.INSURANCE:
        return <InsurancePage />;
      case Tabs.PAYMENTS:
      case Tabs.PAYMENTS:
        return (
          <PaymentContextProvider>
            <PaymentsPage
              programdata={programdata}
              programLoading={programLoading}
              setupDataLoading={setupDataLoading}
            />
          </PaymentContextProvider>
        );
      case "rebates":
        return <RebatesPage />;
      case Tabs.SCHEDULE:
        return <SchedulePage />;
      case Tabs.TRADE_IN:
        return <TradeInPage />;
      case Tabs.REVIEW_ORDER:
        return <ReviewOrderPage />;
      case Tabs.NOT_AVAILABLE:
        return (
          <NoDataPreview
            theme={theme}
            icon={true}
            title="Vehicle Not Found!"
            subTitle="Looks like there are no programs available with this vehicle. Please choose another vehicle to proceed with your order."
            actionArea={
              <>
                <Button
                  theme={theme}
                  primary
                  text="Back to Inventory"
                  onClick={() => handleGoToInventory()}
                ></Button>
              </>
            }
          />
        );
      default:
        return children;
    }
  };

  const getActiveTabTitle = () => {
    switch (currentTab) {
      case Tabs.PAYMENTS:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE;
      case Tabs.FNI:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FNI_PRODUCTS_TITLE;
      case Tabs.ADD_ONS:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_ADD_ONS_TITLE;
      case Tabs.TRADE_IN:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_TRADE_IN_TITLE;
      case Tabs.INSURANCE:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE;
      case Tabs.SCHEDULE:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE;
      case Tabs.FINANCING:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FINANCING_TITLE;
      case Tabs.REVIEW_ORDER:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_REVIEW_ORDER_TITLE;
    }
  };

  const createOrderStepObject = (
    tab: string,
    setState: (value: boolean) => void
  ) => {
    switch (tab) {
      case Tabs.PAYMENTS:
        return {
          class: TaggingClasses.PAYMENT,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE,
          subtitle: generateTabOutput(
            Tabs.PAYMENTS,
            pageState.payments.success
              ? getFormattedPaymentDetails(order, appState?.mileage_unit)
              : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
            appState?.default_currency_symbol,
            pageState.payments.success
          ),
          onClick: () => onTabClick(Tabs.PAYMENTS, setState),
          isActive: currentTab === Tabs.PAYMENTS,
          success:
            currentTab !== Tabs.PAYMENTS ? pageState.payments.success : false,
          error:
            currentTab !== Tabs.PAYMENTS ? pageState.payments.error : false,
        };
      case Tabs.FNI:
        return {
          class: TaggingClasses.FNI,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FNI_PRODUCTS_TITLE,
          subtitle: generateTabOutput(
            Tabs.FNI,
            pageState.fnI.success
              ? {
                addedCount: order?.order_fnI?.length,
                totalAmount: order?.order_fnI
                  .reduce(
                    (sum: any, current: any) => sum + current.applied_price,
                    0
                  )
                  .toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }),
              }
              : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
            appState?.default_currency_symbol,
            pageState.fnI.success
          ),
          isActive: currentTab === Tabs.FNI,
          success: currentTab !== Tabs.FNI ? pageState.fnI.success : false,
          error: currentTab !== Tabs.FNI ? pageState.fnI.error : false,
          onClick: () => onTabClick(Tabs.FNI, setState),
        };
      case Tabs.ADD_ONS:
        return {
          class: TaggingClasses.ADDS_ON,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_ADD_ONS_TITLE,
          subtitle: order?.order_options?.length
            ? subtitle_text
            : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
          success:
            currentTab !== Tabs.ADD_ONS ? pageState.addons.success : false,
          error: currentTab !== Tabs.ADD_ONS ? pageState.addons.error : false,
          isActive: currentTab === Tabs.ADD_ONS,
          onClick: () => onTabClick(Tabs.ADD_ONS, setState),
        };

      case Tabs.TRADE_IN:
        return {
          class: TaggingClasses.TRADE_IN,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_TRADE_IN_TITLE,
          subtitle: generateTabOutput(
            Tabs.TRADE_IN,
            pageState.tradeIn.success
              ? {
                status: "Added",
                totalAmount: calculateAdjustedTradeInAmount(
                  order,
                  appState.default_currency_symbol
                ),
              }
              : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_NONE_SELECTED_SUB_TITLE,
            appState?.default_currency_symbol,
            pageState.tradeIn.success
          ),
          success:
            currentTab !== Tabs.TRADE_IN ? pageState.tradeIn.success : false,
          error: currentTab !== Tabs.TRADE_IN ? pageState.tradeIn.error : false,
          isActive: currentTab === Tabs.TRADE_IN,
          onClick: () => onTabClick(Tabs.TRADE_IN, setState),
        };
      case Tabs.INSURANCE:
        return {
          class: TaggingClasses.INSURANCE,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE,
          subtitle: generateTabOutput(
            Tabs.INSURANCE,
            pageState.insurance.success
              ? { status: "Added", purchaseStatus: "Purchased" }
              : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_1_MINUTES_SUB_TITLE,
            appState?.default_currency_symbol,
            pageState.insurance.success
          ),
          isActive: currentTab === Tabs.INSURANCE,
          success:
            currentTab !== Tabs.INSURANCE ? pageState.insurance.success : false,
          error:
            currentTab !== Tabs.INSURANCE ? pageState.insurance.error : false,
          onClick: () => onTabClick(Tabs.INSURANCE, setState),
        };
      case Tabs.SCHEDULE:
        return {
          class: TaggingClasses.SCHEDULE,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE,
          subtitle: generateTabOutput(
            Tabs.SCHEDULE,
            pageState.appointment.success
              ? { status: "Added" }
              : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_1_MINUTES_SUB_TITLE,
            appState?.default_currency_symbol,
            pageState.appointment.success
          ),
          success:
            currentTab !== Tabs.SCHEDULE
              ? pageState.appointment.success
              : false,
          error:
            currentTab !== Tabs.SCHEDULE ? pageState.appointment.error : false,
          isActive: currentTab === Tabs.SCHEDULE,
          onClick: () => onTabClick(Tabs.SCHEDULE, setState),
        };
      case Tabs.FINANCING:
        return {
          class: TaggingClasses.FINANCING,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FINANCING_TITLE,
          subtitle: generateTabOutput(
            Tabs.FINANCING,
            pageState.finance.employment_details.success &&
              pageState.finance.license_details.success &&
              pageState.finance.personal_details.success &&
              pageState.finance.personal_finance_details.success &&
              pageState.finance.residence_details.success &&
              formStates?.["ContactDetails"]?.data?.ssn
              ? { status: "Application Filled" }
              : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
            appState?.default_currency_symbol,
            pageState.finance.employment_details.success &&
            pageState.finance.license_details.success &&
            pageState.finance.personal_details.success &&
            pageState.finance.personal_finance_details.success &&
            pageState.finance.residence_details.success &&
            formStates?.["ContactDetails"]?.data?.ssn
          ),

          success:
            currentTab !== Tabs.FINANCING
              ? pageState.finance.employment_details.success &&
              pageState.finance.license_details.success &&
              pageState.finance.personal_details.success &&
              pageState.finance.personal_finance_details.success &&
              pageState.finance.residence_details.success &&
              formStates?.["ContactDetails"]?.data?.ssn
              : false,

          error:
            currentTab !== Tabs.FINANCING
              ? pageState.finance.employment_details.error ||
              pageState.finance.license_details.error ||
              pageState.finance.personal_details.error ||
              pageState.finance.personal_finance_details.error ||
              pageState.finance.residence_details.error
              : false,

          isActive: currentTab === Tabs.FINANCING,
          onClick: () => onTabClick(Tabs.FINANCING, setState),
        };
      case Tabs.REVIEW_ORDER:
        return {
          class: TaggingClasses.REVIEW_ORDER,
          title: PLACEHOLDERS?.JOURNEY_LAYOUT_STEP_ITEMS_REVIEW_ORDER_TITLE,
          subtitle: PLACEHOLDERS?.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
          success: false,
          error: false,
          isActive: currentTab === Tabs.REVIEW_ORDER,
          onClick: () => onTabClick(Tabs.REVIEW_ORDER, setState),
        };
    }
  };
  const subtitle_text = `${order?.order_options?.length} ${PLACEHOLDERS?.JOURNEY_LAYOUT_STEP_ITEMS_SELECTED_SUB_TITLE} / ${defaultCurrency}${totalSum}`;
  return (
    <JourneyLayout
      orderTabs={orderTabs}
      nextTab={nextTab}
      getActiveTab={getActiveTab}
      getActiveTabTitle={getActiveTabTitle}
      createOrderStepObject={createOrderStepObject}
      activeTab={currentTab}
      setActivetab={setCurrentTab}
      hideDisclaimer={false}
      vehicleStatus={vehicleData}
    // need to check the logic
    // hideDisclaimer={orderTabs[orderTabs?.length - 1] === activeTab ? true : false}
    >
      {(vehicleData?.vehicle_status === "Available" && vehicleData?.listing_status == true) ? (
        <>
          <JourneyFooter
            noFooter={
              orderTabs?.[orderTabs?.length - 1] === activeTab ? true : false
            }
            title={
              orderState?.finance_type === PaymentType.Lease
                ? PLACEHOLDERS.FOOTER_TITLE_LEASE
                : PLACEHOLDERS.FOOTER_TITLE
            }
            subTitle={`${(
              order?.estimated_monthly_payment ||
              0
            )?.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "currency",
              currency: appState?.default_currency_code || "USD",
            })}/${PLACEHOLDERS.FOOTER_SUBTITLE}`}
            linkText={PLACEHOLDERS.FOOTER_LINK_TEXT}
            onLinkClick={handleLinkClick}
            buttonText={
              getNextTabTitle(orderTabs, activeTab, PLACEHOLDERS) !== ""
                ? `${PLACEHOLDERS.NEXT_TEXT}: ${getNextTabTitle(
                  orderTabs,
                  activeTab,
                  PLACEHOLDERS
                )}`
                : ""
            }
            taggingClass={getTabsClass(orderTabs, activeTab)}
            onButtonClick={onNextTabClick}
          />
          {showPopup && (
            <PaymentBreakDownPopup
              onCloseDialog={handleCloseDialog}
              order_info={order}
            />
          )}
          <Snackbar
            theme={theme}
            message={snackbarState.message}
            open={snackbarState.open}
            onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          />
        </>
      ) : null}
    </JourneyLayout>
  );
};
