import { useTheme } from '@mui/material'
import { Box, Label, Typography } from '@ntpkunity/controls'
import { ProductBundleDecriptionCardProps } from './product-bundle-description-card.props';
import React from 'react';
import { ProductBundleDecriptionCardWrap } from './product-bundle-description-card.style';

export const ProductBundleDecriptionCard = ({ label, title, productPrice, secondaryText, desc, link }: ProductBundleDecriptionCardProps) => {
  const theme = useTheme()

  return (
    <ProductBundleDecriptionCardWrap theme={theme} className="product-detail">
      <Box theme={theme} className="cj-card">
        <Box theme={theme} className='cj-card-header'>
          <Box theme={theme} display={'flex'} flexDirection={'column'} gap={0.5}>
            {title && (
              <Typography
                theme={theme}
                variant="h3"
                component={"h3"}
                display={"block"}
                className="text-h3"
              >
                {title}
              </Typography>
            )}
            {label && (
              <Box theme={theme}>
                <Label
                theme={theme}
                text={`Save upto ${label}%`}
                state="primary"
                textTransform="none"
              />
              </Box>
            )}
          </Box>
          <Box theme={theme} textAlign={'right'} display={'flex'} flexDirection={'column'} gap={0.3}>
            {productPrice && (
              <Typography
                theme={theme}
                variant="h3"
                component={"h3"}
                display={"block"}
                className="text-primary text-h3"
              >
                {productPrice}
              </Typography>
            )}
            {secondaryText && (
              <Typography
                theme={theme}
                className="text-muted text-body2"
                variant="body2"
                component={"p"}
                display={"block"}
              >
                {secondaryText}
              </Typography>
            )}
          </Box>

        </Box>
        {desc && (
          <Typography
            theme={theme}
            className="text-muted text-body2"
            variant="body2"
            component={"p"}
            display={"block"}
          >
            {desc}
          </Typography>
        )}
        {link && (
          <Box theme={theme} className="card-action">
            {link.map(
              (item, index) =>
                !item?.hideLink && (
                  <Typography
                    theme={theme}
                    variant="body2"
                    component={"span"}
                    display={"block"}
                    key={index}
                    className="text-body2 fw-sbold"
                  >
                    <a
                      className="link decoration-none fw-medium text-primary text-body2"
                      onClick={item.onClick}
                    >
                      {item.title}
                    </a>
                  </Typography>
                )
            )}
          </Box>
        )}
      </Box>
    </ProductBundleDecriptionCardWrap>
  );
}