import { FC, useEffect, useRef, useState } from 'react'
import { HeaderProps } from './header.types'
import Toolbar from '@mui/material/Toolbar'
import { HeaderWrap } from './header.style'
import { HeaderMenu } from '@components'
import { ClickAwayListener } from '@mui/material'
import { useAuthentication } from 'hooks/useAuthenticationHook'
import { Icon, Box ,Image } from '@ntpkunity/controls'
import { useAppContext, ILanguageCode } from '@app/context-provider'
import { useForm } from 'react-hook-form'
import IconGermany from '../../src/shared/assets/flags/IconGermany.svg'
import IconItaly from '../../src/shared/assets/flags/IconItaly.svg'
import IconSpain from '../../src/shared/assets/flags/IconSpain.svg';
import IconChina from '../../src/shared/assets/flags/IconChina.svg'
import IconUnitedStates from '../../src/shared/assets/flags/IconUnitedStates.svg'


export const Header: FC<HeaderProps> = ({ theme }) => {

  const [openMenu, setOpenMenu] = useState(true)
  const [openSearch, setOpenSearch] = useState(true)
  const { authenticationStatus } = useAuthentication()
  const [isAuthenticated, setIsAuthenticated] = useState(authenticationStatus)
  const [menuOptions, setMenuOptions] = useState<any[]>([])
  const {
    state: {
      allLanguage: {
        DefaultCode,
        languages
      },
      language: { placeholders },
    },
    dispatch,
  } = useAppContext()
  const form = useForm()
  const flags = {
    "English": IconUnitedStates,
    "German": IconGermany,
    "Spanish": IconSpain,
    "Italian": IconItaly,
    "Chinese": IconChina
  }

  const defaultValues = {
    homeText: 'Home',
    newInventory: 'Inventory',
    preOwnedInventory: 'Pre-Owned Inventory',
    contactUs: 'Contact Us',
    logout: 'Logout',
    preQualification: 'Pre-Qualification Check',
    login: 'Login',
    createAccount: 'Create Account',
    profile: 'Profile',
  }

  useEffect(() => {
    if (authenticationStatus == isAuthenticated) {
      setIsAuthenticated(authenticationStatus)
    }

    if (!authenticationStatus && languages?.length > 0) {
      const languageOptions = languages.map((language: ILanguageCode) => ({
        optionkey: "Language",
        optionText: (
          <Box theme={theme} display="flex" flexDirection="row" alignItems={'center'} >
            <Box theme={theme} display="flex" mr={0.5}>
            <img className="menu-icon" src={flags[language.name]}/>
            </Box>
              <Box theme={theme} className="selected-icon" >
                {language.name}
                {DefaultCode == language.id && (<Icon className="tick-icon" name="TickIcon" />)}
              </Box>
            </Box>
        ),
        optionValue: language,
        className: DefaultCode == language.id ? 'selected' : '',
      }));
      setMenuOptions([
        { optionText: placeholders?.LOGIN_TEXT ?? defaultValues.login, optionkey: 'Login' },
        { optionText: placeholders?.CREATE_ACCOUNT_TEXT ?? defaultValues.createAccount, optionkey: 'Create Account' },
        { optionText: '', className: 'divider' },
        ...languageOptions,
        { optionText: '', className: 'divider' },
      ]);
    } else if (authenticationStatus){
      const languageOptions = languages.map((language: ILanguageCode) => ({
        optionkey: "Language",
        optionText: (
          <Box theme={theme} display="flex" flexDirection="row" alignItems={'center'} >
            <Box theme={theme} display="flex" mr={0.5}>
            <img className="menu-icon" src={flags[language.name]}/>
            </Box>
              <Box theme={theme} className="selected-icon">
                {language.name}
                {DefaultCode == language.id && (<Icon className="tick-icon" name="TickIcon" />)}
              </Box>
          </Box>
        ),
        optionValue: language,
        className: DefaultCode == language.id ? 'selected' : '',
      }));
      setMenuOptions([
        { optionText: placeholders?.PROFILE_TEXT ?? defaultValues.profile, optionkey: 'Profile' },
        { optionText: 'My Deals', optionkey: 'deals' },
        { optionText: 'Appointments', optionkey: 'appointments' },
        { optionText: '', className: 'divider' },
        ...languageOptions,
        { optionText: '', className: 'divider' },
        { optionText: placeholders?.LOGOUT_TEXT ?? defaultValues.logout, optionkey: 'Logout' },
      ]);
    }
  }, [authenticationStatus, DefaultCode, languages, placeholders, defaultValues, theme]);
  const handleClickAway = () => {
    setOpenMenu(true);
    setOpenSearch(true);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <HeaderWrap theme={theme} className='header-wrap'>
        <Toolbar disableGutters className="u-toolbar">
          <HeaderMenu
            form={form}
            menuOptions={menuOptions}
            isAuthenticated={isAuthenticated}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openSearch={openSearch}
            setOpenSearch={setOpenSearch}
          />
        </Toolbar>
      </HeaderWrap>
    </ClickAwayListener>
  )
}
