import { FC, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { PageWrap } from "./fni-product.style";
import { PageHeader, ProductBundleCard, ProductShoppingCard, Tab } from "../../components";
import { Box, Grid, ScrollableTabs, Typography } from "@ntpkunity/controls";
import { isEmpty } from 'lodash'
import {
  useGetIntegrationByProviderName,
  useGetFnIProductRate,
  useCalculateFniMonthlyImpact,
} from "hooks/order-management";
import { QueryKeys, Translations } from "@constants";
import {
  IDealerProfile,
  IFniControlProps,
  IFniProductFilter,
  IOrderFnI,
  IPenCredentials,
  IVehicle,
} from "interfaces";
import { useQueryClient } from "react-query";
import { useParams } from 'react-router-dom'
import { FniProductCard } from "./fni-product-card";
import { fniContext } from "@utilities";
import { AppContext } from "@app/context-provider";
import { user_session } from '@constants'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { useOrderContext } from "@pages";
import { Tags, PageType } from 'constants/enums';
import { IntegrationProvider, LenderIntegrationType } from "constants/providers";
import { updateFnI } from "../../pages/(order-management)/order/order-context-provider";
import { BundleDetailPopup, VehicleDetailsPopup } from "@libraries";

export const FniProduct: FC<IFniControlProps> = ({
  finance_type = "Lease",
  terms = 12,
  apr = 1.2,
  financed_amount = 33718,
  addedFniProducts,
  allFniProducts,
}) => {

  const queryClient = useQueryClient();
  const theme = useTheme();
  const { state: { order }, pricingPayloadGenerator, dispatch } = useOrderContext()
  const appState = useContext(AppContext);
  const { tenant, vin } = useParams()

  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const dealerCode = localStorage.getItem('dealer_information') ? JSON.parse(localStorage.getItem('dealer_information')).dealer?.dealer_code : 0

  const PLACEHOLDERS = appState.state.language.placeholders;
  const company_id = appState.state.tenant

  const vehicle: IVehicle = queryClient.getQueryData([QueryKeys.VEHICLE, vin]);
  const dealerProfile: any = queryClient.getQueryData([QueryKeys.DEALER_PROFILE, dealerCode])

  const [fniProducts, setFniProducts] = useState<IFniProductFilter>(allFniProducts);
  const [productRates, setProductRates] = useState<any>();
  const [penEnabled, setPenEnabled] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const openPopup = () => setOpen(true)


  const { mutate: calculateFnisMonthlyImpacts } = useCalculateFniMonthlyImpact(appState?.state?.slug);
  const { mutate: createTags } = useTags();
  const { mutate: getProductRates, isLoading: isRatesLoading } =
    useGetFnIProductRate(appState?.state?.slug);
  const { mutate: getPenIntegrations } = useGetIntegrationByProviderName(
    IntegrationProvider.PEN, LenderIntegrationType.FNI_PRODUCTS, company_id, appState?.state?.slug
  );

  const handleGetFniMonthlyPaymentImpact = (rates) => {
    let payload = pricingPayloadGenerator();
    let coverages: any = []
    if (rates.rates?.rate?.length) {
      rates.rates.rate.forEach((item: any) => {
        const singleFni = allFniProducts?.result?.find((fni: { product_id: any }) => fni.product_id === item.product_id)
        if (item.coverages?.coverage?.length) {
          item.coverages.coverage.forEach(
            (coverage: any) => {
              coverages.push(...setCoverage(coverage, singleFni))
            }
          )
        } else if (!isEmpty(item.coverages?.coverage)) {
          coverages.push(...setCoverage(item.coverages?.coverage, singleFni))
        }
      })
    }

    payload = {
      ...payload,
      fni_products: coverages
    }

    calculateFnisMonthlyImpacts(payload, {
      onSuccess(data) {
        if (data?.products) {
          const updatedRates = rates?.rates?.rate?.map(item => {
            const coverages = item?.coverages?.coverage;
            coverages?.map(coverage => {
              const coverageName = `${coverage?.coverage_name} - ${coverage?.term_months} Months`
              const monthlyImpactProduct = data?.products?.find(product => product.name === coverageName);
              if (monthlyImpactProduct && coverage) {
                coverage.deductibles.deductible.monthly_impact = monthlyImpactProduct?.monthly_impact;
              }
            })

            return item;
          })

          setProductRates({
            rates: {
              rate: updatedRates
            }
          })
        }
      }
    })
  }

  const setCoverage = (coverage: any, singleFni: any) => {
    const coverageList = [];
    const deductible = coverage?.deductibles?.deductible;
    if (Array.isArray(deductible)) {
      deductible.forEach((d: any) => {
        coverageList.push({
          fni_product_name: `${coverage?.coverage_name} - ${coverage?.term_months} Months`,
          fni_product_amount: getSellingPrice(singleFni, d),
          is_taxable: singleFni?.taxable || false,
        })
      })
    }
    else {
      coverageList.push({
        fni_product_name: `${coverage?.coverage_name} - ${coverage?.term_months} Months`,
        fni_product_amount: getSellingPrice(singleFni, deductible),
        is_taxable: singleFni?.taxable || false,
      })
    }

    return coverageList;

  }
  const getSellingPrice = (dealerFnIProduct: any, deductible: any) => {
    const dealerCost = +deductible.dealer_cost; // Extract dealer cost
    const minimumRetailPrice = +deductible.min_retail_price;
    const maximumRetailPrice = +deductible.max_retail_price;
    const retailPrice = +deductible.retail_price;

    // Min = max = retail => we cannot change anything
    if (minimumRetailPrice === maximumRetailPrice && maximumRetailPrice === dealerCost) {
      return dealerCost;
    }

    // If there's no dealer product, return the dealer cost
    if (!dealerFnIProduct) {
      return dealerCost;
    }

    const amountPercentage = +(dealerCost * (dealerFnIProduct.markup / 100)).toFixed(2);
    const newRetailPrice = amountPercentage + dealerCost;

    // Min and max are negative, we can update without any check
    if (minimumRetailPrice < 0 && maximumRetailPrice < 0) {
      return newRetailPrice;
    }

    // Min and max are positive
    if (newRetailPrice < minimumRetailPrice) {
      return minimumRetailPrice;
    }

    if (newRetailPrice > maximumRetailPrice) {
      return maximumRetailPrice;
    }

    return newRetailPrice;
  };
  // Function to apply markup on rates
  const applyMarkupOnRates = (fniProduct: any, fniRates: any) => {
    // Function to get the selling price based on markup and min/max constraints


    // Iterate over rates and apply markup
    fniRates?.rates?.rate?.map((rate: any) => {
      const product = fniProduct.find((x: any) => x.product_id == rate?.product_id);
      const markup = product?.markup;

      // Check if the markup exists
      if (!markup) {
        return; // If no markup, skip this rate
      }

      // Handle single coverage (pen returns object instead of array)
      if (rate?.coverages?.coverage && !Array.isArray(rate?.coverages?.coverage)) {
        let deductible = rate?.coverages?.coverage?.deductibles?.deductible;

        // Ensure we are getting the latest dealer cost
        const dealerCost = deductible ? deductible.dealer_cost : 0;

        // Use the getSellingPrice function to get the new retail price based on the latest dealer cost
        const newPrice = getSellingPrice(product, deductible);

        // Update the retail price
        deductible.selling_price = newPrice;

        // Ensure coverage is in an array format
        rate.coverages.coverage = [rate?.coverages?.coverage]; // Convert to array
      } else {
        // For multiple coverages
        rate?.coverages?.coverage?.map((item: any) => {
          let deductible = item.deductibles.deductible;

          // Ensure we are getting the latest dealer cost
          const dealerCost = deductible ? deductible.dealer_cost : 0;

          // Use the getSellingPrice function to get the new retail price based on the latest dealer cost
          const newPrice = getSellingPrice(product, deductible);

          // Update the retail price
          deductible.selling_price = newPrice;
        });
      }
    });

    // After markup is applied, update the rates and perform further actions
    setProductRates(fniRates);
    handleGetFniMonthlyPaymentImpact(fniRates);
  };
  const getPenCredentials = () => {
    getPenIntegrations(
      { provider_name: IntegrationProvider.PEN, integration_type: LenderIntegrationType.FNI_PRODUCTS, company_id: company_id },
      {
        onSuccess(response: any) {
          setPenEnabled(response?.is_active)
        },
      }
    );
  };
  const getProductInfo = () => {
    if (fniProducts) {
      let productInfo: Array<any> = [];
      fniProducts?.result?.map((obj: any) => {
        const newObj: any = {
          product_id: obj?.product_id,
          starting_mileage: 0,
          ending_mileage: 10000,
        };
        productInfo.push(newObj);
      });
      return productInfo;
    }
  };
  const currentDate = new Date(Date.now()).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  })
  const getCarStatus = (type: string): string => {
    switch (type?.toLowerCase()) {
      case 'pre-owned':
      case 'pre installed':
        return 'USED';
      case 'certified pre-owned':
        return 'CERTIFIEDPREOWNED';
      default:
        return type?.toUpperCase() || '';
    }
  };
  const getRateRequestObject = () => {
    const ratesRequestObject = {
      deal_info: {
        pen_dealer_id: dealerProfile?.pen_dealer_id,
        vin: order?.order_asset?.vin,
        mileage: order?.order_asset?.mileage,
        car_status: getCarStatus(order?.order_asset?.type?.toString()?.toLowerCase()), //"NEW"
        effective_date: currentDate,
        in_service_date: currentDate,
        finance_type: finance_type,
        vehicle_msrp: order?.order_asset?.msrp,
        vehicle_purchase_price: order?.order_asset?.unit_price,
        deal_type: finance_type === 'Lease' ? 'LEASE' : 'LOAN',
        finance_terms: [terms],
        finance_terms_mileage: [24],
        apr: apr,
        financed_amount: financed_amount,
        language: "ENGLISH",
        user_role: "FI_MANAGER",
      },
      products_info: getProductInfo(),
      test_request: true,
    };
    return ratesRequestObject;
  };
  const getRatesCall = () => {
    getProductRates(
      {
        data: getRateRequestObject(),
      },
      {
        onSuccess: (data) => {
          applyMarkupOnRates(fniProducts?.result, data);
        },
      }
    );
  };
  const addOrRemoveProduct = (
    fni_product_id: number,
    add_product: boolean,
    is_price: number = null,
    term: number = null,
    mileage: number = null,
    monthly_impact: number = null
  ) => {
    setFniProducts((prevState) => ({
      ...prevState,
      result: prevState.result.map((item) =>
        item.id === fni_product_id
          ? {
            ...item,
            is_added: add_product,
            price: is_price ?? item.price,
            term: term,
            mileage: mileage,
            monthly_impact
          }
          : item
      ),
    }));
  };
  useEffect(() => {
    if (company_id) {
      getPenCredentials();
    }
  }, [company_id]);

  useEffect(() => {
    if (allFniProducts && !fniProducts?.result) {
      setFniProducts(allFniProducts);
    }
    if (allFniProducts?.result && penEnabled) {
      getRatesCall();
    }
  }, [allFniProducts, penEnabled]);

  useEffect(() => {
    if (order?.order_fnI?.length && allFniProducts?.result) {
      order?.order_fnI?.forEach((fni) => {
        addOrRemoveProduct(
          fni?.financial_insurance_id,
          true,
          fni?.applied_price,
          fni?.term,
          fni?.mileage
        )
      })
    }
  }, [])

  //Add data To order store whenever product is added
  useEffect(() => {
    if (fniProducts?.result?.length > 0) {
      let order_fni: Array<IOrderFnI> = []
      fniProducts?.result
        .filter((x) => x.is_added)
        .map((item) => {
          order_fni?.push({
            order_id: item?.order_id ?? 0,
            financial_insurance_id: item.id,
            product_name: item.product_name,
            dealer_id: +vehicle?.dealer_id,
            applied_price: item?.price,
            session_id: item?.session_id,
            rate_id: item?.rate_id,
            form_id: item?.form_id,
            identifier: item?.identifier,
            is_taxable: item?.taxable,
            term: item?.term,
            mileage: item?.mileage

          });
        });
      dispatch(updateFnI(order_fni));
      const event_detail: Event = {
        session: user_session,
        slug: tenant,
        event_name: Tags.ADD_PRODUCT,
        user_id: user_profile?.user_id,
        vin: vehicle?.vin,
        dealer_id: +vehicle?.dealer_id,
        vehicle_make: vehicle?.make,
        vehicle_model: vehicle?.model,
        order_id: order?.identifier,
        page_type: PageType.FIPage
      }
      createTags(event_detail)
    }
  }, [fniProducts]);

  const product_type = [
    ...Array.from(
      new Set(fniProducts?.result?.map((item: any) => item.product_type))
    ),
  ];

  const sampleBundles = new Array(4).fill(null);
  const tabs = [
    {
      title: "Bundle Products",
      content: (
        <>
          <Grid
            theme={theme}
            container
            item
            spacing={{ xs: 3, md: 2 }}
            alignItems={"stretch"}
          >
            {sampleBundles?.map((_, index) => (
              <Grid theme={theme} item xs={12} lg={6} key={index}>
                <ProductBundleCard
                  title={'Sample Bundle Name'}
                  saveUpTo={'30'}
                  bundleList={[
                    { title: 'Product Name-1' },
                    { title: 'Product Name-2' },
                    { title: 'Product Name-3' },
                    { title: 'Product Name-4' },
                  ]}
                  linkText={'View Bundle Details'}
                  onLinkClick={openPopup}
                  buttonStatePrimary={true}
                  buttonText={'Add Bundle'}
                  onBtnClick={() => { }}
                  coverageItem
                  coverageTitle={'Price'}
                  coveragePrice={40}
                  changeTextonHover={true}
                  onHoverText={PLACEHOLDERS.FNI_REMOVE_PRODUCT_TEXT}
                />
                
                {open && (
                  <BundleDetailPopup
                    open={open}
                    setOpen={setOpen}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </>

      ),
    },
    {
      title: "Individual Products",
      content: (
        <fniContext.Provider
          value={{
            addOrRemoveProduct: addOrRemoveProduct,
            isLoading: isRatesLoading,
          }}
        >
          <FniProductCard
            products={fniProducts?.result}
            rates={productRates?.rates?.rate}
          />
        </fniContext.Provider>
      ),
    }
  ]

  return (
    <PageWrap theme={theme} className="page-wrap">
      <PageHeader
        marginBottom={3}
        title={PLACEHOLDERS.FNI_HEADER_TITLE}
      />
      <Box theme={theme} mt={3}>
        <Tab hasBorder autoWidth>
          <ScrollableTabs
            scrollButtons={"auto"}
            theme={theme}
            items={tabs}
            defaultTabIndex={0}
          />
        </Tab>
      </Box>
      <Typography
        theme={theme}
        className="text-muted"
        variant="caption"
        component={"small"}
        display={"block"}
        textAlign={"center"}
        mt={3}
      ></Typography>
      {/* <VehicleDetailsPopup /> */}
      {/* <QuestionnairePopup /> */}
    </PageWrap>
  );
};
