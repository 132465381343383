import { Endpoint } from "../constants/endpoint";
import { http } from "../utilities";
import { CONFIG_API_GATEWAY } from "apis/order-management";

export const HUBEX_BASE_URL = process.env.HUBEX_BASE_URL;
export const DMS_BASE_URL = process.env.DMS_BASE_URL;
export const CONFIG_BASE_URL = process.env.CONFIG_BASE_URL;

export namespace InsuranceManagement {
  export const addWayInsurance = (data: any): any => {
    return http(HUBEX_BASE_URL, { "x-service-provider": "Way" })
      .post(`${Endpoint.ADD_WAY_INSURANCE}`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const addTransactionId = (data: any): any => {
    return http(DMS_BASE_URL).post(`${Endpoint.ADD_TRANSACTION_ID}`, data).then((res) => {
        return res.data;
      });
  };

  export const getInsuranceCompanies = async ({
    companyId,
  }: {
    companyId: number;
  }) => {
    return http(CONFIG_API_GATEWAY)
      .get(`${Endpoint.INSURANCE_COMPANIES}`, {
        headers: { Company_id: companyId },
      })
      .then((res) => {
        return res.data;
      });
  };
}
