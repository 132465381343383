import {createContext, Dispatch, useContext, useEffect, useReducer, useRef} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { updateHeaderFooter, useAppContext } from './context-provider'
import { useExistingWorkFlowMutation, useGetDealerProfileByDealerCode, useWorkFlowMutation, useWorkFlowNextStep } from '@hooks'
import { OrderStatus, Tabs} from '@constants'
import { RESET_PASSWORD_ROUTE, Navigation } from 'constants/navigation'
import { useAuthentication } from '../hooks/useAuthenticationHook'
type MetaData = {
	tabs?: string[]
}

type Workflow = {
	workflow_id: number
	running_id: number
	current_state: string
	next_state: string
	task_id: number
	task_status: string
	api_response: null | string
	api_url: null | string
	meta_data: null | MetaData
}

// ================================== Workflow Context ================================= //
export enum WorkflowActionType {
   UPDATE_WORKFLOW_LOADING = 'UPDATE_WORKFLOW_LOADING',
}
const initialState: { workflowLoading: boolean } = {
   workflowLoading: false
}

type WorkflowContextType = {
	navigateNext: (path?: string, payload?: any) => void
	getOrderWorkflow: (vin?: string, referece_number?: string, running_id?: string, status?: string) => void
	state: { workflowLoading: boolean }
    dispatch: Dispatch<any>;
}
const WorkflowContext = createContext<WorkflowContextType>({
	navigateNext: () => null,
	getOrderWorkflow: () => null,
	state: initialState,
   	dispatch: () => null,
})
const workflowReducer = (state: { workflowLoading: boolean } = initialState, action: { type: WorkflowActionType; payload: any }) => {
   switch (action.type) {
	   case WorkflowActionType.UPDATE_WORKFLOW_LOADING:
		   return {...state, workflowLoading: action.payload}

   }
}

const useWorkflowContext = () => useContext(WorkflowContext)

const WorkflowProvider = ({ children }: { children: JSX.Element }) => {
    const [state, dispatch] = useReducer(workflowReducer, initialState);
	const navigate = useNavigate()
	const location = useLocation()
	const { pathname, search } = location
	const { authenticationStatus } = useAuthentication()
	const { state: app_state, dispatch: appDispatch } = useAppContext()
	const { mutate: fetch_workflow, isLoading:fetchLoading } = useWorkFlowMutation()
	const { mutate: fetch_workflow_status, isLoading:fetchStatuLoading } = useExistingWorkFlowMutation()
	const { mutate: move_next, isLoading:moveStepLoading } = useWorkFlowNextStep()
    const dealerCode = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code ?? 0;
	const previousLocationRef = useRef('')
	const defaultRoutes = [
		Navigation.PROFILE.USER_PROFILE,
		Navigation.PROFILE.APPOINTMENTS,
		Navigation.PROFILE.FAVORITES,
		Navigation.PROFILE.ORDERS,
		Navigation.PROFILE.PREFRENCES,
		Navigation.AUTHENTICATION.USER_PROFILE,
		Navigation.AUTHENTICATION.OTP_PAGE,
		Navigation.AUTHENTICATION.FORGOT_PASSWORD,
	]

	useEffect(() => {
		dispatch({
			  type: WorkflowActionType.UPDATE_WORKFLOW_LOADING,
			  payload: fetchStatuLoading || fetchLoading || moveStepLoading
			})

	}, [fetchLoading, fetchStatuLoading, moveStepLoading]);

	//social media icons implementation
	const { data: dealerProfile } = useGetDealerProfileByDealerCode(dealerCode, app_state?.slug);

	useEffect(() => {
		if (dealerProfile) {
			const footerLinks: any = []
			const headerLinks: any = []
			dealerProfile?.custom_menu_links?.forEach((link) => {
				if (link.menu_position === 'Footer') footerLinks.push(link)
				else if (link.menu_position === 'Header') headerLinks.push(link)
			})
			appDispatch(
				updateHeaderFooter(
					{
						twitter: dealerProfile.twitter || '',
						linkedin: dealerProfile.linkedin || '',
						youtube: dealerProfile.youtube || '',
						google: dealerProfile.google || '',
						instagram: dealerProfile.instagram || '',
						facebook: dealerProfile.facebook || '',
					},
					headerLinks,
					footerLinks,
					dealerCode
				)
			)
		}
	}, [dealerProfile])

	useEffect(() => {
	 if (!app_state.slug) return;
	 if (location.pathname?.replace(app_state.slug, ':tenant') === Navigation.AUTHENTICATION.SIGN_UP_PAGE) {
		return fetchState({slug: app_state.slug}, null, true);
	 }

	 // Check if the current location is different from the previous one
	 if (previousLocationRef.current === location.pathname) {
       return; // Exit if the same location
     }


	  const currentUrl = window.location.href;
	  const isOrderPage = currentUrl.includes('vehicle-detail');
	  const runningId = localStorage.getItem('running_id');
	  const basePath = pathname.includes(app_state.slug) ? pathname.replace(app_state.slug, ':tenant') : pathname;
	  const path = updatePath(basePath);

	  // If not authenticated
	  if (!authenticationStatus) {
		if (isOrderPage) {
		  const lastSegment = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
		  localStorage.setItem('reference_dealer', lastSegment);
		}
		if (!defaultRoutes.includes(path)) {
			fetchState({slug: app_state.slug});
		}
		return; // Exit early to avoid further checks
	  }

	  // If authenticated
	  if (!runningId) {
		if (isOrderPage) {
		  const lastSegment = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
		  localStorage.setItem('reference_dealer', lastSegment);
		}
		return fetchState({ slug: app_state.slug });
	  } else if (!defaultRoutes.includes(path)) {
		  const workflow = JSON.parse(localStorage.getItem('workflow')) || {};

		  if (path === Navigation.INVENTORY_PAGE) {
			  return fetchState({slug: app_state.slug, target: 'Inventory Page'});
		  }
		  if((Navigation.ORDER_PAGE === path && workflow.current_state !== 'Pricing')){
			  return fetchState({slug: app_state.slug, target: 'Pricing'},);
		  }

	      const shouldGoToProfile =
			  ( Navigation.ORDER_ID_PAGE === path && !['Pricing'].includes(workflow.current_state)) ||
			  ( Navigation.DEAL_SUMMARY_PAGE === path && !['Contracting'].includes(workflow.current_state)) ||
			  ([Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE].includes(path) && workflow.current_state != 'Order Submitted' );

		  if (shouldGoToProfile){
			  previousLocationRef.current = location.pathname;
			  navigate(Navigation.PROFILE.ORDERS.replace(':tenant', app_state.slug))
	  	}
		else {

		  return fetch_workflow_status(
			{ running_id: parseInt(runningId) },
			{
			  onSuccess: (data) => {
				localStorage.setItem('workflow', JSON.stringify(data));
				navigateTo(data);
			  },
			}
		  );
		}
	  }
	}, [app_state.slug, authenticationStatus, location]);

	const updatePath = (path) => {
	  // Replace for ORDER_ID_PAGE
	  const orderIdPattern = /\/vehicle-detail\/[^/]+\/([^/]+)$/; // Matches /vehicle-detail/VIN/orderId
	  if (path.match(orderIdPattern)) {
		return path.replace(orderIdPattern, '/vehicle-detail/:vin/:orderId');
	  }

	  // Replace for DEAL_SUMMARY_PAGE
	  const dealIdPattern = /\/deal\/[^/]+\/([^/]+)$/; // Matches /vehicle-detail/VIN/orderId
	  if (path.match(dealIdPattern)) {
		return path.replace(dealIdPattern, '/deal/:vin/:orderId');
	  }

	  // Replace for SUBMIT_ORDER_PAGE
	  const submitOrderPattern = /\/submit-deal\/[^/]+\/([^/]+)$/; // Matches /submit-deal/VIN/orderId
	  if (path.match(submitOrderPattern)) {
		return path.replace(submitOrderPattern, '/submit-deal/:vin/:orderId');
	  }

	  // Replace the VIN segment for generic order paths
	  const vinPattern = /\/vehicle-detail\/[^/]+$/; // Matches /vehicle-detail/VIN
	  if (path.match(vinPattern)) {
		return path.replace(vinPattern, '/vehicle-detail/:vin');
	  }

	  return path; // Return original path if no replacements were made
	};


	const navigateTo = (data: Workflow, payload?: any) => {
		switch (data.current_state) {
			case 'Sign In':
				navigateNext(Navigation.AUTHENTICATION.SIGN_IN_PAGE)
				break
			case 'Inventory Page':
				navigateNext(Navigation.INVENTORY_PAGE, payload)
				break
			case 'Profile':
				navigateNext(Navigation.PROFILE.USER_PROFILE, payload)
				break
			case 'Pricing':
				navigateNext(Navigation.ORDER_PAGE, payload)
				break
			case 'Order Submitted':
				navigateNext(Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE, payload)
				break
			case 'Contracting':
				navigateNext(Navigation.DEAL_SUMMARY_PAGE, payload)
				break
		}
	}

	const fetchState = (state: any, payload = null, restrictNavigation: boolean = false) => {
		fetch_workflow(state, {
			onSuccess: (data) => {
				localStorage.setItem('running_id', data.running_id.toString())
				localStorage.setItem('workflow', JSON.stringify(data))
				!restrictNavigation && navigateTo(data, payload)
			},
		})
	}

	const fetchStateWithRunningId = (running_id: any, payload = null) => {
		fetch_workflow_status(
			{ running_id: running_id },
			{
				onSuccess: (data) => {
					localStorage.setItem('running_id', data.running_id.toString())
					localStorage.setItem('workflow', JSON.stringify(data))
					navigateTo(data, payload)
				},
			}
		)
	}

	const getOrderWorkflow = (vin = null, reference_number = null, running_id = null, status= null) => {
		const payload = reference_number ? { vin: vin, orderId: reference_number } : { vin: vin };
		  if (status) {
			if (status === OrderStatus.DRAFT) {
			  return fetchState({ slug: app_state.slug, target: 'Pricing' }, payload);
			}

			if (![OrderStatus.APPROVED].includes(status)) {
			  return fetchState({ slug: app_state.slug, target: 'Order Submitted' }, payload);
			}

			return fetchState({ slug: app_state.slug, target: 'Contracting' }, payload);
		  }

		  if (running_id) {
			return fetchStateWithRunningId(running_id, payload);
		  }

		  if (vin) {
			return fetchState({ slug: app_state.slug, target: 'Pricing' }, payload);
		  }

		  return fetchState({ slug: app_state.slug, target: 'Inventory Page' });
	}

	function replaceTenantWithSlug(path: string) {
		return path.includes(':tenant') ? path.replace(':tenant', app_state.slug) : path
	}

	const navigateNext = (path?: string, payload?: any) => {
		previousLocationRef.current = location.pathname
		if (!path) {
			const state_based_on_url: string = GET_WORKFLOW_STATE_BASED_ON_URL(pathname.replace(app_state.slug, ':tenant'))
			const work_flow: Workflow = JSON.parse(localStorage.getItem('workflow'))

			if (state_based_on_url === work_flow?.current_state) {
				move_next(
					{ running_id: parseInt(localStorage.getItem('running_id')) },
					{
						onSuccess: (data) => {
							localStorage.setItem('workflow', JSON.stringify(data))
							navigateTo(data, payload)
						},
					}
				)
			} else {
				fetchState({ slug: app_state.slug, target: work_flow?.current_state })
			}
		} else {
			const redirectPath = routeToDefaultURL(path, payload)

			if (redirectPath) return navigate(redirectPath)

			const state_based_on_url: string = GET_WORKFLOW_STATE_BASED_ON_URL(path)
			const work_flow: Workflow = JSON.parse(localStorage.getItem('workflow'))

			if (state_based_on_url === work_flow?.current_state || state_based_on_url === work_flow?.next_state) {
				if (
					state_based_on_url === work_flow.next_state &&
					(path === Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE || path === Navigation.ORDER_ID_PAGE || path === Navigation.DEAL_SUMMARY_PAGE)
				) {
					move_next(
						{ running_id: parseInt(localStorage.getItem('running_id')) },
						{
							onSuccess: (data) => {
								localStorage.setItem('workflow', JSON.stringify(data))
								navigateTo(data, payload)
							},
						}
					)
				}

				switch (path) {
					case Navigation.AUTHENTICATION.FORGOT_PASSWORD:
						path = `${Navigation.AUTHENTICATION.FORGOT_PASSWORD}?email=${payload.email}`
						break
					case Navigation.ORDER_PAGE:
						path = payload?.orderId
							? Navigation.ORDER_ID_PAGE.replace(':vin', payload.vin).replace(':orderId', payload.orderId)
							: Navigation.ORDER_PAGE.replace(':vin', payload.vin)
						break
					case Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE:
						path = Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE.replace(':vin', payload.vin).replace(':orderId', payload.orderId)
						break
					case Navigation.ORDER_ID_PAGE:
						path = Navigation.ORDER_ID_PAGE.replace(':vin', payload.vin).replace(':orderId', payload.orderId)
						break
					case Navigation.DEAL_SUMMARY_PAGE:
						path = Navigation.DEAL_SUMMARY_PAGE.replace(':vin', payload.vin).replace(':orderId', payload.orderId)
						break
				}

				if (pathname.includes(RESET_PASSWORD_ROUTE)) {
					path = pathname + search
				}

				const PATH_WITH_TENANT = replaceTenantWithSlug(path)
				return navigate(PATH_WITH_TENANT)
			} else {
				fetchState({ slug: app_state.slug, target: state_based_on_url })
			}
		}
	}
	const routeToDefaultURL = (path: string, payload = null) => {
		if (path.replace(app_state.slug, ':tenant').includes(Navigation.AUTHENTICATION.RESEND_PASSWORD_LINK)) return path

		switch (path) {
			case Navigation.AUTHENTICATION.FORGOT_PASSWORD:
				path = `${Navigation.AUTHENTICATION.FORGOT_PASSWORD}?email=${payload.email}`
				return replaceTenantWithSlug(path)

			case Navigation.AUTHENTICATION.SIGN_UP_PAGE:
			case Navigation.PROFILE.USER_PROFILE:
			case Navigation.PROFILE.APPOINTMENTS:
			case Navigation.PROFILE.FAVORITES:
			case Navigation.PROFILE.ORDERS:
			case Navigation.PROFILE.PREFRENCES:
				return replaceTenantWithSlug(path)

			default:
				return
		}
	}

	return <WorkflowContext.Provider value={{ navigateNext, getOrderWorkflow, state, dispatch }}>{children}</WorkflowContext.Provider>
}

// ================================== Workflow Context ================================= //

export { WorkflowProvider, useWorkflowContext }

const GET_WORKFLOW_STATE_BASED_ON_URL = (URL: string) => {
	switch (URL) {
		case Navigation.AUTHENTICATION.EMAIL_VERIFICATION:
		case Navigation.AUTHENTICATION.OTP_PAGE:
		case Navigation.AUTHENTICATION.RESET_PASSWORD:
		case Navigation.AUTHENTICATION.USER_PROFILE:
		case Navigation.AUTHENTICATION.RESET_PASSWORD_LINK_EXPIRED:
		case Navigation.AUTHENTICATION.SIGNUP_CONSENT:
		case Navigation.AUTHENTICATION.SIGN_IN_PAGE:
		case Navigation.AUTHENTICATION.LOG_IN_PAGE:
			return 'Sign In'
		case Navigation.INVENTORY_PAGE:
			return 'Inventory Page'
		case Navigation.ORDER_PAGE:
			return 'Pricing'
		case Navigation.DEAL_SUMMARY_PAGE:
			return 'Contracting'
		case Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE:
			return 'Order Submitted'
	}
}
