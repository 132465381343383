import React, { FC, useEffect, useReducer } from "react";
import { useTheme } from "@mui/material";
import { Stack } from "@components";
import {
  Box,
  Button,
  DatePicker,
  Grid,
  Icon,
  Input,
  Select,
  Typography,
  FileUpload,
  Snackbar,
  CircleLoader
} from "@ntpkunity/controls";
import { useAppContext } from "@app/context-provider";
import { Controller, useForm } from "react-hook-form";
import { ILicenseDetail } from "@interfaces";
import dayjs from "dayjs";
import {
  useAddLicenseDetails,
  useAddLicenseDetailsToMitek,
  useUpdateCustomerLicenseInfo,
} from "hooks/customer-management";
import {
  useGetIntegrationByProviderName,
  useGetStates,
} from "hooks/order-management";
import { MitekCallTypes, QueryKeys } from "@constants";
import { useQueryClient } from "react-query";
import { FinanceTab } from "@constants";
import {
  pagesActionType,
  useJourneyLayoutContext,
} from "layouts/journey-layout/journey-layout-context-provider";

import { CustomImageUploader } from "./custom-image-uploader";
import { IntegrationProvider, LenderIntegrationType } from "constants/providers";
import {formatPayloadDate} from "../../helpers/methods";
import {useFormContext} from "../../pages/(order-management)/order/form-context-provider";
import { SnackbarUtility } from "@utilities";
import {useGetConfiguration} from "../../hooks/trade-in-management";
import { TaggingClasses } from "constants/enums";

export const LicenseDetails: FC<{
  licenseData?;
  setTab: (value: number) => void;
  form: any
}> = ({ licenseData, setTab, form }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const customer: any = queryClient.getQueryData(
    QueryKeys.GET_CUSTOMER_BY_EMAIL
  );
  const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

  const { state: appState } = useAppContext();
  const PLACEHOLDERS = appState.language.placeholders;
  const company_id = appState.tenant
  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    getValues,
    setValue,
    watch,
    trigger,
    reset,
    handleSubmit,
  } = form

  const { mutate: createLicenseInfoByReferenceId } = useAddLicenseDetails();
  const { dispatch: pageDispatch } = useJourneyLayoutContext();
  const { data: MitekData, mutate: verifyLicenseOnMitek, isLoading: mitekLoading } =
    useAddLicenseDetailsToMitek();
  const { mutate: updateLicenseByInfoReferenceId,isLoading:updateLicenseByInfoReferenceIdisLoad } =
    useUpdateCustomerLicenseInfo();
  const { data: mitekProvider } = useGetConfiguration(dealer_code, LenderIntegrationType.IDENTITY_VERIFICATION, IntegrationProvider.MITEK, company_id, appState?.slug);
  const { data: licenseStates } = useGetStates(appState?.slug);
  const {formStates, resetFormState} = useFormContext()
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  useEffect(() => {
    if(MitekData?.mitek_verification_status === "Manual Review" ){
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.FINANCING_LICENSE_MANUAL_REVIEW_STATUS))
      setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 5000);
      return
    }
    if (
      MitekData?.mitek_verification_status === "Verified" ||
      MitekData?.mitek_verification_status === "Rejected"
    ) {
      let fetch_data = licenseStates?.find(
        (field: { code: any }) =>
          field?.code ===
          MitekData?.mitek_response?.evidence[0]?.extractedData?.address
            ?.stateProvince
      );
      setValue(
        "first_name",
        MitekData?.mitek_response?.evidence[0]?.extractedData?.name?.givenNames,
        { shouldDirty: true }
      );
      setValue(
        "last_name",
        MitekData?.mitek_response?.evidence[0]?.extractedData?.name?.surname,
        {
          shouldDirty: true,
        }
      );
      setValue(
        "date_of_birth",
        MitekData?.mitek_response?.evidence[0]?.extractedData?.dateOfBirth,
        { shouldDirty: true }
      );
      setValue(
        "expiry_date",
        MitekData?.mitek_response?.evidence[0]?.extractedData?.dateOfExpiry,
        {
          shouldDirty: true,
        }
      );
      setValue(
        "license_number",
        MitekData?.mitek_response?.evidence[0]?.extractedData?.documentNumber,
        { shouldDirty: true }
      );
      setValue("issue_state", fetch_data?.id, { shouldDirty: true });
      trigger([
        "date_of_birth",
        "first_name",
        "last_name",
        "expiry_date",
        "license_number",
        "issue_state",
      ]);
    }
  }, [MitekData]);


  const generateRandomString = () => {
    let length = 10;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };
  const isEmpty = (value: any) =>
    value === undefined ||
    value === null ||
    (typeof value === "string" && !value.trim().length) ||
    (typeof value === "object" && !Object.keys(value).length);

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          const result: string = reader.result as string;
          const base64Data = result.split(",")[1]; // Remove the data URL scheme
          resolve(base64Data);
        };
        reader.onerror = (error) => reject(error);
      } else {
        resolve(null);
      }
    });
  };
  const isValidUrl = (url: any) => {
    return /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?(\?.*)?$/.test(
      url
    );
  };
  const onSubmit = async (data: ILicenseDetail) => {
    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Saving license details'))

    const {
      first_name,
      last_name,
      issue_state,
      license_number,
      front_image,
      expiry_date,
      date_of_birth,
      back_image,
    } = data;

    let front_img_string: string = "";
    let back_img_string: string = "";
    if (dirtyFields?.front_image) {
      front_img_string = (await getBase64(front_image)) as string;
    }
    if (dirtyFields?.back_image) {
      back_img_string = (await getBase64(back_image)) as string;
    }

    const partialPayload = Object.keys(dirtyFields).reduce<
      Partial<ILicenseDetail>
    >((payload, field) => {
      const key = field as keyof ILicenseDetail;

      if (key in dirtyFields && dirtyFields[key]) {
        if (field === "front_image") {
          payload[key] = front_img_string;
        } else if (field === "back_image") {
          payload[key] = back_img_string;
        } else {
          payload[key] = data[key];
        }
      }

      return payload;
    }, {});

    const payload = {
      ...partialPayload,
      first_name,
      last_name,
      expiry_date: formatPayloadDate(expiry_date),
      date_of_birth: formatPayloadDate(date_of_birth),
      license_number,
      issue_date:
        MitekData &&
        MitekData?.mitek_response?.evidence[0]?.dateOfIssue !== null
          ? formatPayloadDate(
              MitekData?.mitek_response?.evidence[0]?.dateOfIssue
            )
          : null,
      status:
        MitekData && MitekData?.mitek_verification_status === "Approved"
          ? "Approved"
          : "Rejected",
      call_type: MitekData ? MitekCallTypes.AUTO : MitekCallTypes.MANUAL,
      reference_id: customer?.reference_id,
      mitek_response: null,
      address: {
        address_line_1:
          MitekData &&
          MitekData?.mitek_response?.evidence[0]?.extractedData
            ?.addressLine1 !== null
            ? MitekData?.mitek_response?.evidence[0]?.extractedData?.address
                ?.addressLine1
            : null,
        address_line_2:
          MitekData &&
          MitekData?.mitek_response?.evidence[0]?.extractedData
            ?.addressLine2 !== null
            ? MitekData?.mitek_response?.evidence[0]?.extractedData?.address
                ?.addressLine2
            : null,
        state_name: issue_state,
        zip_code:
          MitekData &&
          MitekData?.mitek_response?.evidence[0]?.extractedData?.postalCode !==
            null
            ? MitekData?.mitek_response?.evidence[0]?.extractedData?.address
                ?.postalCode
            : null,
        city:
          MitekData &&
          MitekData?.mitek_response?.evidence[0]?.extractedData?.city !== null
            ? MitekData?.mitek_response?.evidence[0]?.extractedData?.address
                ?.city
            : null,
        verified:
          MitekData && MitekData?.mitek_verification_status === "Approved"
            ? true
            : false,
        identifier:
          MitekData && MitekData?.identifier !== null
            ? MitekData?.identifier
            : null,
      },
    };
    if (isEmpty(licenseData)) {
      createLicenseInfoByReferenceId(payload,{
        onSuccess: () => {
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Saved license details'))

          resetFormState('licence')

      setTab(FinanceTab["Employment-Details"]);
      pageDispatch({
        type: pagesActionType.UPDATE_LICENSE_DETAILS,
        payload: {
          success: true,
          error: false,
        },
      });
        }
      });
    } else if (isDirty || licenseData) {
      updateLicenseByInfoReferenceId(payload, {
        onSuccess: ()=>{
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('License details updated'))

          resetFormState('licence')
          setTab(FinanceTab["Employment-Details"]);
          pageDispatch({
        type: pagesActionType.UPDATE_LICENSE_DETAILS,
        payload: {
          success: true,
          error: false,
        },
      });
        }
      });
    }
  };
  const licenseImage = (image: any) => {
    let image_url;
    const validUrl = isValidUrl(image);
    if (!validUrl) {
      image_url = URL.createObjectURL(image as unknown as File);
    }
    const url = validUrl ? image : image_url ?? "";
    return url;
  };

  const verifyLicenseByMitek = async () => {
    if (
      getValues("back_image") &&
      getValues("back_image") &&
      mitekProvider?.is_active === true && (dirtyFields?.back_image || dirtyFields?.front_image)
    ) {
      const backImage = await getBase64(watch("back_image"));
      const frontIimage = await getBase64(watch("back_image"));
      const dealerCode = mitekProvider?.is_active === true  ? dealer_code : undefined
      const payload = {
        front_image: frontIimage,
        back_image: backImage,
        customer_id:customer?.reference_id,
        dealer_code: mitekProvider?.dealer_code,
        api_version: 1.2,
      };
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Verifying license details'))
      verifyLicenseOnMitek(payload,{
        onSuccess:()=>{
          snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
        }
      });
    }
  };

  const onFileChange = (event: any, type: "front_image" | "back_image") => {
    setValue(type, (event?.dataTransfer ?? event?.target).files?.[0], {
      shouldDirty: true,
    });
  };

  const hasFrontImage = watch("front_image");
  const hasBackImage = watch("back_image");
  useEffect(() => {
    if (Object.keys(errors).length) {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Error occured in saving details'))
      setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); });

      pageDispatch({
        type: pagesActionType.UPDATE_LICENSE_DETAILS,
        payload: {
          success: false,
          error: true,
        },
      });
    }
  }, [errors]);
  return (
    <>
      <Stack marginTopXs={3} className="scroll-horizontal">
        <Typography theme={theme} component={"h4"} variant="h4">
          {PLACEHOLDERS.FINANCING_DRIVER_LICENSE_UPLOAD}
        </Typography>
        <Grid
          theme={theme}
          container
          item
          rowSpacing={2}
          columnSpacing={3}
          justifyContent={"center"}
          mt={1}
        >
          <Grid theme={theme} item xs={12} md={6}>
            {hasFrontImage ? (
              <CustomImageUploader>
                <Box theme={theme} className="img-wrap">
                  <Button
                    onClick={() =>
                      setValue("front_image", "", {
                        shouldDirty: true,
                      })
                    }
                    theme={theme}
                    className="btn-close"
                    iconText={<Icon name="CloseBlack" />}
                  />
                  <img src={licenseImage(getValues("front_image") as any)} />
                </Box>
              </CustomImageUploader>
            ) : (
              <>
                <Controller
                  name={"front_image"}
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <FileUpload
                      hoverLabel={PLACEHOLDERS.UPLOAD_FILE_CLICK_OR_DRAG_TEXT}
                      {...field}
                      // image={{ url: field.value }}
                      theme={theme}
                      id="front_id"
                      // hoverLabel="Drag and drop image file, or browse"
                      onChange={async (e) => {
                        onFileChange(e, "front_image");
                        verifyLicenseByMitek();
                      }}
                      onDrop={async (e) => {
                        onFileChange(e, "front_image");
                        verifyLicenseByMitek();
                      }}
                      onDeleteImage={(e) => {
                        setValue("front_image", "");
                      }}
                    />
                  )}
                />
                <Typography
                  theme={theme}
                  component="p"
                  variant="body2"
                  textAlign={"center"}
                  sx={{ color: theme.palette.grey[600] }}
                >
                  {`Upload the image of the front side of your driver's license.`}
                </Typography>
              </>
            )}
          </Grid>
          <Grid theme={theme} item xs={12} md={6}>
            {hasBackImage ? (
              <CustomImageUploader>
                <Box theme={theme} className="img-wrap">
                  <Button
                    onClick={() =>
                      setValue("back_image", "", {
                        shouldDirty: true,
                      })
                    }
                    theme={theme}
                    className="btn-close"
                    iconText={<Icon name="CloseBlack" />}
                  />
                  <img src={licenseImage(getValues("back_image") as any)} />
                </Box>
              </CustomImageUploader>
            ) : (
              <>
                <Controller
                  name={"back_image"}
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <FileUpload
                      {...field}
                      theme={theme}
                      id="back_id"
                      image={{ url: field.value }}
                      hoverLabel={PLACEHOLDERS.UPLOAD_FILE_CLICK_OR_DRAG_TEXT}
                      onChange={async (e) => {
                        onFileChange(e, "back_image");
                        verifyLicenseByMitek();
                      }}
                      onDrop={async (e) => {
                        onFileChange(e, "back_image");
                        verifyLicenseByMitek();
                      }}
                      onDeleteImage={(e) => {
                        setValue("back_image", "");
                      }}
                    />
                  )}
                />
                <Typography
                  theme={theme}
                  component="p"
                  variant="body2"
                  textAlign={"center"}
                  sx={{ color: theme.palette.grey[600] }}
                >
                  {`Upload the image of the back side of your driver's license.`}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Stack>
      <Stack marginTopXs={3} className="scroll-horizontal">
        <Typography theme={theme} component={"h4"} variant="h4">
          {PLACEHOLDERS.FINANCING_CONFIRM_DRIVER_LICENSE_DETAILS}
        </Typography>
        <Grid
          theme={theme}
          container
          item
          columnSpacing={2}
          rowSpacing={3}
          mt={0}
        >
          <Grid theme={theme} item xs={6}>
            <Controller
              name={"first_name"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: PLACEHOLDERS.LICENSE_DETAILS_FIRST_NAME_REQUIRED,
                },
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  {...field}
                  fullWidth
                  placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                  label={PLACEHOLDERS.FINANCING_FIRST_NAME_FIELD_LABEL}
                  type="text"
                  error={errors?.first_name?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={6}>
            <Controller
              name={"last_name"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: PLACEHOLDERS.LICENSE_DETAILS_LAST_NAME_REQUIRED,
                },
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  {...field}
                  fullWidth
                  placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                  label={PLACEHOLDERS.FINANCING_LAST_NAME_FIELD_LABEL}
                  type="text"
                  error={errors?.last_name?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name={"license_number"}
              control={control}
              rules={{
                maxLength: {
                  value: 50,
                  message: PLACEHOLDERS.LICENSE_DETAILS_MAX_CHAR_LIMIT,
                },
                required: {
                  value: true,
                  message: PLACEHOLDERS.LICENSE_DETAILS_LICENSE_NUMBER_REQUIRED,
                },
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  {...field}
                  placeholder={"000 - 00 - 0000"}
                  fullWidth
                  label={PLACEHOLDERS.FINANCING_LICENSE_NUMBER_FIELD_LABEL}
                  type="text"
                  error={errors?.license_number?.message}
                />
              )}
            />

          </Grid>
          <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name={"issue_state"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: PLACEHOLDERS.LICENSE_DETAILS_STATE_REQUIRED,
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  theme={theme}
                  {...field}
                  label={
                    PLACEHOLDERS.FINANCING_LICENSE_ISSUING_STATE_FIELD_LABEL
                  }
                  items={licenseStates?.map((x: { id: any; name: any }) => {
                    return {
                      value: x.id,
                      text: x.name,
                    };
                  })}
                  selectError={errors?.issue_state?.message}
                  sxProps={""}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name={"expiry_date"}
              key={licenseData?.expiry_date}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: PLACEHOLDERS.LICENSE_DETAILS_EXPIRY_DATE_REQUIRED,
                },
                validate: {
                  notPastOrCurrentDate: (value) => {
                    const selectedDate = dayjs(value).startOf("day");
                    const currentDate = dayjs().startOf("day");
                    if (
                      selectedDate.isBefore(currentDate) ||
                      selectedDate.isSame(currentDate)
                    ) {
                      return PLACEHOLDERS.LICENSE_DETAILS_EXPIRY_DATE_VALIDATION;
                    }
                    return true;
                  },
                },
              }}
              defaultValue={licenseData?.expiry_date}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={PLACEHOLDERS.FINANCING_EXPIRATION_FIELD_LABEL}
                  theme={theme}
                  value={value ?? ""}
                  onChange={(e: any) => {
                    if (
                      e instanceof Date &&
                      e.getFullYear() === 1970 &&
                      e.getMonth() === 0 &&
                      e.getDate() === 1
                    ) {
                      onChange(null);
                    } else {
                      onChange(e.toISOString());
                    }
                  }}
                  error={errors?.expiry_date?.message}
                  minDate={dayjs().add(1, "day")}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name={"date_of_birth"}
              key={licenseData?.dateOfBirth}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: PLACEHOLDERS.LICENSE_DETAILS_DATE_OF_BIRTH_REQUIRED,
                },
                validate: {
                  notFutureOrCurrentDate: (value) => {
                    const selectedDate = dayjs(value).startOf("day");
                    const currentDate = dayjs().startOf("day");
                    if (
                      selectedDate.isAfter(currentDate) ||
                      selectedDate.isSame(currentDate)
                    ) {
                      return PLACEHOLDERS.LICENSE_DETAILS_DATE_OF_BIRTH_VALIDATION;
                    }
                    return true;
                  },
                },
              }}
              defaultValue={licenseData?.date_of_birth}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={PLACEHOLDERS.FINANCING_DATE_OF_BIRTH_FIELD_LABEL}
                  theme={theme}
                  value={value ?? ""}
                  clearable
                  onChange={(e: any) => {
                    if (
                      e instanceof Date &&
                      e.getFullYear() === 1970 &&
                      e.getMonth() === 0 &&
                      e.getDate() === 1
                    ) {
                      onChange(null);
                    } else {
                      onChange(e.toISOString());
                    }
                  }}
                  error={errors?.date_of_birth?.message}
                  maxDate={dayjs().subtract(1, "day")}
                />
              )}
            />
          </Grid>
        </Grid>
      </Stack>
      {formStates?.['licence']?.isDirty ? (
      <Box theme={theme} mt={3}>
        <Button
          className={`${TaggingClasses.FINANCING}-licence-save`}
          theme={theme}
          primary
          disabled={updateLicenseByInfoReferenceIdisLoad || mitekLoading}
            text={
              <>
                {updateLicenseByInfoReferenceIdisLoad && <CircleLoader theme={theme} size="xs" />}

                {PLACEHOLDERS.FINANCING_SAVE_AND_CONTINUE}
              </>
            }
          fullWidth
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
      ) : null}
      <Snackbar
            theme={theme}
            message={<Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px">
            <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
        </Box> }
            open={snackbarState.open}
            onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
    </>
  );
};
