import { Toolbar, useTheme } from '@mui/material'
import { Box, Container, Icon, Typography } from '@ntpkunity/controls'
import clsx from 'clsx'
import { HeaderSearchProps } from './header-search.props'
import { HeaderSearchWrap } from './header-search.style'
import { Logo, NavLink, Search } from '@components'
import {useContext, useEffect, useState} from 'react'
import { AppContext } from '@app/context-provider'
import {useWorkflowContext} from "@app/router-workflow";
import {useAuthentication} from "../../hooks/useAuthenticationHook";


export const HeaderSearch = ({ searchOpen, onClose } : HeaderSearchProps) => {
  const theme = useTheme()
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;
  const { getOrderWorkflow } = useWorkflowContext()
  const { authenticationStatus } = useAuthentication()
  const [isAuthenticated, setIsAuthenticated] = useState(authenticationStatus)

  useEffect(() => { setIsAuthenticated(authenticationStatus) }, [authenticationStatus])
  const defaultValues = {
    newInventory: 'Inventory',
    preOwnedInventory: 'Pre-Owned Inventory',
    searchVehiclePlaceholder: 'Search vehicle by name or a keyword...'
  }

  return (
    <HeaderSearchWrap
        theme={theme}
        className={clsx('header-search-wrap', searchOpen ? '' : 'search-open')}
      >
          <Toolbar disableGutters className="u-toolbar">
            <Box
              className="app-logo"
              theme={theme}
              sx={{ flexGrow: { xs: 1 } }}
              display={{ xs: 'none', md: 'block' }}
            >
              <Logo onClick={() => {}} />
            </Box>
            <Box theme={theme} className="header-nav-wrap">
              <ul className="navbar-nav nav-center">
                {isAuthenticated && (
                    <li className="nav-item">
                  <NavLink
                    title={PLACEHOLDERS?.NEW_INVENTORY_TEXT ?? defaultValues.newInventory}
                    isActive
                    onClick={() => {getOrderWorkflow()}}
                    aria-label={PLACEHOLDERS?.NEW_INVENTORY_TEXT}
                  />
                </li>)}
                <li className="nav-item">
                  <NavLink
                    title={PLACEHOLDERS?.PRE_OWNED_INVENTORY_TEXT ?? defaultValues?.preOwnedInventory}
                    onClick={() => {}}
                    aria-label={PLACEHOLDERS?.PRE_OWNED_INVENTORY_TEXT}
                  />
                </li>
              </ul>
              <ul className="navbar-nav nav-right">
                <li className="nav-item icon-item">
                  <NavLink
                    icon={'CloseBlack'} 
                    onClick={onClose}
                    ariaLabel='Close Black'
                  />
                  {/* <a
                    className="nav-link close-search-btn"
                    href="javascript:void(0)"
                    onClick={onClose}
                  >
                    <Icon name="CloseBlack" />
                  </a> */}
                </li>
              </ul>
            </Box>
          </Toolbar>
      </HeaderSearchWrap>
  )
}
