import { QueryKeys } from "@constants";
import { DealerManagement } from "../apis";
import { useMutation, useQuery } from "react-query";

export const useGetDealerPreference = (dealerCode: string, slug: string) => {
  return useQuery(
    QueryKeys.GET_DEALER_PREFERENCE,
    () => {
      return DealerManagement.getDealerPreference({ dealerCode, slug });
    },
    { enabled: !!dealerCode }
  );
};

export const useGetDealerByDealerCode = (data) => {
  const { dealerCode, ...rest } = data;
  return useQuery(
    ["dealer-by-dealer-code", dealerCode],
    () => {
      return DealerManagement.getDealerByDealerCode(data);
    },
    { enabled: !!dealerCode && !!rest.headers.company_id }
  );
};
export const useGetDealerByDealerId = (data) => {
  const { dealerId, ...rest } = data;
  return useQuery(
    ["dealer-by-dealer-id", dealerId],
    () => {
      return DealerManagement.getDealerByDealerId(data);
    },
    { enabled: !!dealerId && !!rest.headers.company_id }
  );
};


export const useGetDealerChatConfig = (dealerCode: string) => {
  return useQuery(
    ["dealer-chat-config", dealerCode],
    () => {
      return DealerManagement.getDealerChatConfiguration(dealerCode);
    },
    { enabled: !!dealerCode }
  );
};

export const useGetDealerFeebyState = (slug: string): any => {
  return useMutation<any>((body: any) => {
    return DealerManagement.getDealerFeesByState({
      dealerCode: body?.dealerCode,
      stateCode: body?.stateCode,
      slug,
    });
  });
};

export const useOrderInfo = (
  slug: string,
  orderReference: string | undefined
): any => {
  return useQuery(
    [QueryKeys.ORDER_INFO, slug, orderReference],
    () => {
      return DealerManagement.getOrderInfo({
        order_reference: orderReference,
        slug,
      });
    },
    {
      enabled: !!orderReference,
    }
  );
};
export const useLanguages = (
  slug: string,
): any => {
  return useQuery(
    [QueryKeys.LANGUAGE, slug],
    () => {
      return DealerManagement.getLanguage({
        slug
      });
    },
    {
      enabled: !!slug,
    }
  );
};