import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-wrap": {
    ".custom-dropdown": {
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".u-form-group": {
        height: 20,
        ".u-form-control": {
          "&.u-select": {
            height: 20,
            color: theme.palette.grey[900],
            fontWeight: theme.customVariables.fontWeightSemiBold,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            "@media only screen and (min-width: 1599px)": {
              fontSize: theme.typography.body1.fontSize,
            },
            ".MuiInputBase-input": {
              padding: "0px 28px 0px 0px",
            },
            ".MuiSelect-icon": {
              right: 0,
              path: {
                stroke: "#6C6E7A",
              },
            },
            fieldset: {
              border: "none",
            },
          },
        },
      },
    },
  },
}));
