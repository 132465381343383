import { v4 as uuidv4 } from "uuid";
import {
  ChatMessage,
  ChatClientReturn,
  ChatClientConstructor,
  SetChannelEvent,
  SubscribeEvent,
  MessageCountEvent,
  MessageListEvent,
  MessagePayload,
  MessageCustomerId,
  MessageDealerId,
  SingleMessageEvent,
  ChannelBase,
  SubscribedEvent,
  MessageWho,
  ChannelString,
  UseChatServiceReturn,
  ChatVinState,
  UseChatServiceParams,
} from "../interfaces";
import {
  ChatClientEvents,
  MessagePerson,
  MessageType,
  MessageStatus,
  OnlineEvents,
} from "../constants/enums";
import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
} from "react";
import { getUser, getUserProfile } from "helpers/methods";
import { useParams } from "react-router-dom";

const CLIENT_HOST = process.env.CHAT_CLIENT_HOST || "";
const CLIENT_PORT = Number(process.env.CHAT_CLIENT_PORT) || 8080;
const MESSAGE_PAGE_SIZE = 10;
const CUSTOMER_MESSAGE_ID_REGEX = /^cu_\d+$/;
const DEALER_MESSAGE_ID_REGEX = /^dl_\d+$/;

declare global {
  interface Window {
    otoz: {
      Client: ChatClientConstructor;
    };
  }
}

const initiateChatClient = (
  username: string | undefined
): ChatClientReturn | undefined => {
  if (!window || !username || !window.otoz) return undefined;
  const { Client } = window.otoz;
  return new Client({
    username,
    host: CLIENT_HOST,
    port: CLIENT_PORT,
    password: "",
    reConnectivity: true,
  });
};

export const getMessageFromId = () =>
  `cu_${getUser()?.user_id || ""}` as MessageCustomerId;

export const ChatVinContext = createContext<ChatVinState>(null);

export const ChatVinProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [vin, setVin] = useState("");
  return (
    <ChatVinContext.Provider value={{ vin: vin, setVin }}>
      {children}
    </ChatVinContext.Provider>
  );
};

export const useChatVinContext = (): ChatVinState => {
  const vinContext = useContext(ChatVinContext);
  if (!vinContext)
    throw new Error(
      "useChatVINContext should be called inside the ChatVINProvider"
    );

  return vinContext;
};

export const VinSynchronizeWrap = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const params = useParams<{ vin: string }>();
  const { setVin } = useChatVinContext();

  useEffect(() => {
    setVin(params?.vin);

    return () => {
      setVin("");
    };
  }, [params.vin]);

  return <>{children}</>;
};

export const ChatServiceContext = createContext<UseChatServiceReturn | null>(
  null
);

export const ChatServiceProvider = ({
  children,
  ...props
}: UseChatServiceReturn & { children: React.ReactNode }) => {
  return (
    <ChatServiceContext.Provider value={props}>
      {children}
    </ChatServiceContext.Provider>
  );
};
export const useChatServiceContext = () => {
  const context = useContext(ChatServiceContext);
  if (!context) {
    throw new Error(
      "useChatServiceContext should be called inside ChatServiceProvider"
    );
  }
  return context;
};

export const useChatService = ({
  dealerCode,
  chatConfig,
  orderVin,
}: UseChatServiceParams): UseChatServiceReturn => {
  const [chatClient, setChatClient] = useState<ChatClientReturn | undefined>();
  const [chatConnected, setChatConnected] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<ChannelBase>();
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalMessageCount, setTotalMessageCount] = useState<number>();
  const [messages, setMessages] = useState<
    (ChatMessage & { who: MessagePerson })[]
  >([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [onlineDealerUsers, setOnlineDealerUsers] = useState<
    { id: string; num: number }[]
  >([]);
  const fromId = getMessageFromId();
  const cleanupCalled = useRef<boolean>();
  const user = getUser();

  const getMessagePayload = (message: string): MessagePayload => {
    return {
      messageId: uuidv4(),
      to: selectedChannel.channel,
      from: fromId,
      key: selectedChannel.key,
      type: MessageType.TEXT,
      content: message,
      status: MessageStatus.SENT,
      timestamp: Date.now(),
    };
  };

  const identifyWho = (from: MessageDealerId | MessageCustomerId) => {
    return CUSTOMER_MESSAGE_ID_REGEX.test(from)
      ? MessagePerson.ME
      : MessagePerson.OTHER;
  };

  const getListMessagesPayload = (channel: ChannelString) => {
    return {
      channel: channel,
      page: `${currentPage + 1}`,
      limit: MESSAGE_PAGE_SIZE.toString(),
    };
  };

  const setChannel = () => {
    const userProfile = getUserProfile();
    const payload = {
      firstName: userProfile?.first_name,
      lastName: userProfile?.last_name,
      phoneNumber: userProfile?.contact_number || "",
      userID: userProfile?.user_id,
      email: user.email,
      tenantId: user.tenant_id,
      vin: orderVin || "",
      configuration: {
        AutoGeneratedMessage: chatConfig?.offline_message || "",
        AutoTime: 1,
        SendEmailNotification: true,
        SendSMSNotification: true,
        WelcomeMessage: chatConfig?.welcome_chat || "",
      },
    };
    const channelPath = `${dealerCode}/${payload.userID}`;
    chatClient.SetChannel(channelPath, payload);
  };

  const sendMessage = (message: string) => {
    // This will triger ChatClientEvents.MESSAGE event
    chatClient.SendMessage(getMessagePayload(message));
  };

  const readMessages = () => {
    let readCount = 0;
    const updatedMessages = messages.map((message) => {
      const who = identifyWho(message.from);
      const isUnreadMessage =
        who === MessagePerson.OTHER && message.status === MessageStatus.SENT;
      if (isUnreadMessage) {
        chatClient.ReadMessage(message.messageId);
        readCount += 1;
      }
      return { ...message, status: MessageStatus.SEEN };
    });
    setMessages(updatedMessages);
    setUnreadCount(unreadCount - readCount);
  };

  const disconnetChat = () => {
    if (chatConnected) {
      chatClient.UnSubscribe(selectedChannel);
      chatClient.Disconnect();
      setSelectedChannel(undefined);
      setLoadingMessages(true);
      setOnlineDealerUsers([]);
      setMessages([]);
      setUnreadCount(0);
    }
  };

  const handleDealerOnlineOffline = (
    who: MessageWho,
    eventType: OnlineEvents
  ) => {
    if (DEALER_MESSAGE_ID_REGEX.test(who.username)) {
      setOnlineDealerUsers((onlineDealers) => {
        const onlineInstance = onlineDealers.find(
          (onlineDealer) => onlineDealer.id === who.username
        );
        const dealerUserAlreadyOnline = !!onlineInstance;
        if (dealerUserAlreadyOnline) {
          if (eventType === OnlineEvents.UNSUBSCRIBE) {
            const multipleUsersOnline = onlineInstance.num > 1;
            if (multipleUsersOnline) {
              return onlineDealers.map((dealer) => {
                if (dealer.id === who.username) {
                  return { ...dealer, num: dealer.num - 1 };
                }
                return dealer;
              });
            }
            return [
              ...onlineDealers.filter((dealer) => dealer.id !== who.username),
            ];
          } else {
            return onlineDealers.map((dealer) => {
              if (dealer.id === who.username) {
                return { ...dealer, num: dealer.num + 1 };
              }
              return dealer;
            });
          }
        }
        if (eventType === OnlineEvents.SUBSCRIBE) {
          return [...onlineDealers, { id: who.username, num: 1 }];
        }

        return [...onlineDealers];
      });
    }
  };

  const loadMoreMessages = () => {
    setIsLoadingMore(true);
    const payload = getListMessagesPayload(selectedChannel.channel);
    setCurrentPage(currentPage + 1);
    chatClient.GetMessages(payload);
  };

  const listenOnSetChannel = (event: SetChannelEvent) => {
    setSelectedChannel(event);
    chatClient.Subscribe(event);
  };

  const listenOnSubscribe = (event: SubscribeEvent) => {
    chatClient.GetMessageCount({ channel: event.channel, type: "dl_" });
    setLoadingMessages(true);
    chatClient.GetMessages(getListMessagesPayload(event.channel));
    setCurrentPage(currentPage + 1);
    event.who.forEach((who) => {
      handleDealerOnlineOffline(who, OnlineEvents.SUBSCRIBE);
    });
  };

  const listenOnMessageCount = (event: MessageCountEvent) => {
    setTotalMessageCount(event.totalCount);
    setUnreadCount(event.unreadCount);
  };

  const listenOnMessages = (event: MessageListEvent) => {
    if (!event.channel) return;

    setMessages((prevMessages) => {
      const newMessages = event.messages
        .map((msg) => ({
          ...msg,
          who: identifyWho(msg.from),
        }))
        .reverse();
      return [...newMessages, ...prevMessages];
    });
    setLoadingMessages(false);
    setIsLoadingMore(false);
  };

  const listenOnSingleMessage = (event: SingleMessageEvent) => {
    // Have to use callback function because initial state
    // is bind when the listener is attached

    const who = identifyWho(event.from);
    if (who === MessagePerson.OTHER) {
      setUnreadCount((count) => count + 1);
    }
    setTotalMessageCount((prev) => prev + 1);
    setMessages((prevMessages) => {
      return [
        ...prevMessages,
        { ...event, _id: uuidv4(), who: identifyWho(event.from) },
      ];
    });
  };

  const listenOnOnlineOffline = (event: SubscribedEvent) => {
    handleDealerOnlineOffline(event.who, event.event);
  };

  useEffect(() => {
    setChatClient(initiateChatClient(fromId));
  }, []);

  useEffect(() => {
    if (chatClient) {
      chatClient.Connect();
      chatClient.on(ChatClientEvents.CONNECT, () => {
        setChatConnected(true);
      });
      chatClient.on(ChatClientEvents.SET_CHANNEL, listenOnSetChannel);
      chatClient.on(ChatClientEvents.SUBSCRIBED, listenOnSubscribe);
      chatClient.on(ChatClientEvents.MESSAGE_COUNT, listenOnMessageCount);
      chatClient.on(ChatClientEvents.MESSAGE_LIST, listenOnMessages);
      chatClient.on(ChatClientEvents.MESSAGE, listenOnSingleMessage);
      chatClient.on(ChatClientEvents.ONLINE, listenOnOnlineOffline);
      chatClient.on(ChatClientEvents.OFFLINE, listenOnOnlineOffline);
      chatClient.on(ChatClientEvents.DISCONNECT, () => {
        setChatConnected(false);
      });
    }
  }, [chatClient]);

  useEffect(() => {
    if (chatClient) {
      chatClient.Connect();
      if (dealerCode && chatConnected && !selectedChannel) {
        cleanupCalled.current = false;
        setChannel();
      }
    }
    return () => {
      if (cleanupCalled.current) {
        return;
      }

      if (dealerCode && chatConnected && selectedChannel) {
        cleanupCalled.current = true;

        disconnetChat();
      }
    };
  }, [chatClient, dealerCode, chatConnected, selectedChannel, orderVin]);

  return {
    chatClient,
    messages,
    sendMessage,
    loadingMessages,
    unreadCount,
    dealerOnline: onlineDealerUsers.length > 0,
    readMessages,
    loadMoreMessages,
    isLoadingMore,
    shouldShowLoadMore: totalMessageCount > messages.length,
  };
};
