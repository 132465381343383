import { useTheme } from '@mui/material'
import { StepCountWrap } from './steps.count.style'
import { Box, Button, CircleProgress, Icon, Typography } from '@ntpkunity/controls'
import { StepCountProps } from './steps-count.props'
import clsx from 'clsx'
import { Fragment } from 'react'

export const StepsCount = ({ counter, CounterStepCurrent, CounterStepTotal, counterProgressValue, title, subtitle, actionArea, collapse, onClick, onClose, onBack, onSave }: StepCountProps) => {
    const theme = useTheme()

    return(
        <StepCountWrap theme={theme} className='step-count-wrap'>
            <Box theme={theme} className='card' >
                <Box theme={theme} display={'flex'} gap={'24px'}>
                    {onBack &&
                        <Box theme={theme} display={{ xs: 'none', sm: 'none', md: 'flex'}}>
                            <Button defaultBtn theme={theme} onClick={onBack} iconText={<Icon name="IconLeftArrow" className='icon-light' />} ariaLabel="left arrow icon" />
                        </Box>
                    }
                    <Box theme={theme} className='card-content' onClick={onClick}>
                        {counter &&
                            <Box theme={theme} className='counter'>
                                <CircleProgress value={counterProgressValue} theme={theme} thickness={2} ariaLabel='step count' />
                                <Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted'>
                                    {CounterStepCurrent}
                                    /
                                    {CounterStepTotal}
                                </Typography>
                            </Box>
                        }
                        <Box theme={theme} className={clsx({'title': title, 'title-with-subtitle': subtitle })}>
                            {title &&
                                <Typography theme={theme} variant='subtitle2' component={'span'} display={'block'}>{title}</Typography>
                            }
                            {subtitle &&
                                <Typography theme={theme} className='text-muted subtitle' variant='caption' component={'small'} display={'block'}>{subtitle}</Typography>
                            }
                        </Box>
                    </Box>
                </Box>
                {actionArea &&
                    <Box theme={theme} className='card-action'>
                        {onSave && 
                            <Button  defaultBtn theme={theme} onClick={onSave} iconText={<Icon name="SaveDiskMiniIcon" className='transparent' />} ariaLabel="Save Disk Mini Icon" />
                        }
                        {onClose && 
                            <Box theme={theme} className="action-icon">
                                <Button defaultBtn theme={theme} onClick={onClose} iconText={<Icon name="CloseBlack" className='desktop-icon icon-light' />} ariaLabel="Close Black" /> 
                                <Box theme={theme} display={{ xs: 'block', md: 'none'}}>
                                    {!collapse ?
                                        <Button defaultBtn theme={theme} onClick={onClose} iconText={<Icon name="CloseBlack" className='icon-light' />} ariaLabel="Close Black" /> :
                                        <Button defaultBtn theme={theme} onClick={onClick} iconText={<Icon name="ChevronDown" />} className='chevron-up-icon icon-light' />
                                    }
                                </Box>
                           </Box>
                        }
                    </Box>
                }
            </Box>
            {/* {progressBar &&
                <Box theme={theme} className='card-progress-bar'>
                    <LinearProgressBar title="40" value={progressValue} theme={theme} />
                </Box>
            } */}
        </StepCountWrap>
    )
}