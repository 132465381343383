import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Image,
  Typography,
} from "@ntpkunity/controls";
import {
  Popup,
  ProductAddBundleCard,
  ProductAddCard,
  ProductBundleDecriptionCard,
  ProductDecriptionCard,
  ResponsiveCarousel,
} from "@components";
import React, { FC, useContext, useEffect, useState } from "react";
import { IFniProduct } from "@interfaces";
import { Translations } from "@constants";
import { AppContext } from "@app/context-provider";
import { useParams } from 'react-router-dom';
import { useTags } from 'hooks/event-analytics';
import { Event } from 'apis/event-analytics';
import { Tags, PageType } from 'constants/enums';
import { user_session } from '@constants'
import img from "../../src/shared/assets/images/car.png"

export const BundleDetailPopup: FC<{
  open: boolean;
  setOpen: any;
}> = ({ open, setOpen }) => {
  const { tenant } = useParams();
  const { mutate: createTags } = useTags();
  const user_profile = JSON.parse(localStorage.getItem("user_profile"));
  const theme = useTheme();
  const appState = useContext(AppContext);
  const [selectedCoverage, setSelectedCoverage] = useState(-1);
  const PLACEHOLDERS = appState.state.language.placeholders;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    const event_detail: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.VIEW_DETAILS,
      user_id: user_profile?.user_id,
      page_type: PageType.FIPage,
    };
    createTags(event_detail);
  }, [])
  const isPriceAdded = (product, coverage): boolean => {
    return (
      (product.price == coverage.deductibles?.deductible?.retail_price) &&
      (product.term == coverage.term_months) &&
      product.is_added
    );
  };
  return (
    <Popup>
      <Dialog
        theme={theme}
        size="md"
        open={open}
        title={'Sample Bundle Name'}
        onCloseDialog={() => {
          setOpen(false);
        }}
        disablePortal
        customFooter={
          <Button
            theme={theme}
            primary
            text={"Add Bundle"}
            onClick={() => {
              setOpen(false);
            }}
          ></Button>
        }
      >
        <Grid theme={theme} container item spacing={2}>
          <Grid item xs={12} theme={theme}>
            <ResponsiveCarousel responsive={responsive} autoPlaySpeed={5000}>
              <Box theme={theme} key={1}>
                <Image
                  theme={theme}
                  src={img}
                  aspectRatio="16:9"
                  alt="image"
                />
              </Box>
              <Box theme={theme} key={2}>
                <Image
                  theme={theme}
                  src={img}
                  aspectRatio="16:9"
                  alt="image"
                />
              </Box>
              <Box theme={theme} key={3}>
                <Image
                  theme={theme}
                  src={img}
                  aspectRatio="16:9"
                  alt="image"
                />
              </Box>
              <Box theme={theme} key={4}>
                <Image
                  theme={theme}
                  src={img}
                  aspectRatio="16:9"
                  alt="image"
                />
              </Box>
            </ResponsiveCarousel>
          </Grid>
          <Grid item xs={12} theme={theme}>
            <ProductBundleDecriptionCard
              label={'30'}
              title={'Sample Bundle Name'}
              productPrice={'$25/mo.'}
              secondaryText={'Total Price $0,000.00 '}
              desc={'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus.'}
              link={[
                {
                  title: PLACEHOLDERS.FNI_VIEW_PRODUCT_BROCHURE,
                  onClick: () => {},
                },
              ]}
            />
          </Grid>
            <Grid item xs={12} theme={theme}>
              <ProductAddBundleCard
                title={'Sample F&I Product Name'}
                primaryText={'$00/Mo.'}
                secondaryText={PLACEHOLDERS.FNI_TOTAL_PRICE_TEXT}
                productDetail={[
                  {
                    title: 'Term:',
                    subTitle: '60 Months'
                  },
                  {
                    title: 'Mileage:',
                    subTitle: '50,000'
                  },
                  {
                    title: 'Deductible:',
                    subTitle: '$0.00'
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} theme={theme}>
              <ProductAddBundleCard
                title={'Sample F&I Product Name'}
                primaryText={'$00/Mo.'}
                secondaryText={PLACEHOLDERS.FNI_TOTAL_PRICE_TEXT}
                productDetail={[
                  {
                    title: 'Term:',
                    subTitle: '60 Months'
                  },
                  {
                    title: 'Mileage:',
                    subTitle: '50,000'
                  },
                  {
                    title: 'Deductible:',
                    subTitle: '$0.00'
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} theme={theme}>
              <ProductAddBundleCard
                title={'Sample F&I Product Name'}
                primaryText={'$00/Mo.'}
                secondaryText={PLACEHOLDERS.FNI_TOTAL_PRICE_TEXT}
                productDetail={[
                  {
                    title: 'Term:',
                    subTitle: '60 Months'
                  },
                  {
                    title: 'Mileage:',
                    subTitle: '50,000'
                  },
                  {
                    title: 'Deductible:',
                    subTitle: '$0.00'
                  },
                ]}
              />
            </Grid>
          <Grid item xs={12} theme={theme}>
            <Typography
              theme={theme}
              variant="caption"
              component={"small"}
              display={"block"}
              className="text-muted text-center"
            >
              {PLACEHOLDERS.FNI_FOOTER_TEXT}
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </Popup>
  );
};
