import { Link, useTheme } from '@mui/material'
import React from 'react'
import { ProgramNotFoundWrap } from './program-not-found.style'
import { Button, NoDataPreview } from '@ntpkunity/controls'

export const ProgramNotFound = ({ title, subTitle, showActionBtn }: { title: string; subTitle?: string; showActionBtn?: boolean }) => {
	const theme = useTheme()
	return (
		<ProgramNotFoundWrap theme={theme} className='program-not-found-wrap'>
			<NoDataPreview
				key={'program-not-found'}
				theme={theme}
				title={title}
				subTitle={subTitle}
				{...(showActionBtn && { actionArea: <Button theme={theme} primary text={'Back to Inventory'} /> })}
			/>
		</ProgramNotFoundWrap>
	)
}
