import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductBundleCardWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-bundle-card-wrap': {
        height: '100%',
        '.cj-card': {
            padding: '16px',
            height: '100%',
            position: 'relative',
            '.cj-card-content': {
                padding: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                '.cp': {
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'auto',
                    gap: 8,
                    '.title': {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                    },
                    ".label-wrap": {
                        display: 'flex',
                        borderRadius: 8
                    },
                    '.link': {
                        cursor: 'pointer',
                        display: 'inline-block'
                    },
                    
                    ".bundle": {
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 10,
                        gap: 6,
                        ".bundle-list": {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 6,
                        }
                    }
                },
                ".coverage-item-wrap": {
                    ".cj-card": {
                        ".MuiTypography-subtitle1": {
                            fontSize: theme.typography.body2,
                            fontWeight: theme.typography.body2.fontWeight
                        }
                    }
                },
                '.listing': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#F5F5F7',
                    borderRadius: '8px',
                    padding: '12px 16px',
                    gap: '8px',
                    '.nav-link': {
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        span: {
                            fontSize: theme.typography.subtitle1.fontSize,
                            fontWeight: theme.typography.subtitle1.fontWeight,
                            color: theme.palette.primary.main
                        }
                    },
                }
            }
        }
    }
}))
