import React from 'react'
import { useTheme } from '@mui/material'
import { Box, Icon, Typography } from '@ntpkunity/controls'
import clsx from 'clsx'
import { NavLinkProps } from './nav-link.props'
import { NavLinkWrap } from './nav-link.style'

export const NavLink = ({ title, icon, startIcon, selected, onClick, isActive, image, ariaLabel = title } : NavLinkProps) => {
    const theme = useTheme()
  return (
    <NavLinkWrap theme={theme} href="javascript:void(0)" className={clsx({ 'nav-link': true, 'active': isActive})} onClick={onClick} aria-label={ariaLabel}>
        <Box theme={theme} className='link'>
            <Box theme={theme} className='start-icon'>
                {startIcon &&
                    <Icon name={startIcon as any} />
                }
                {
                    image &&
                    <img className="menu-icon" src={image} />
                }
                {title &&
                    <Typography theme={theme} variant="body2" component="span">
                        {title}
                    </Typography>
                }
                {icon &&
                    <Icon name={icon as any} /> 
                }
            </Box>
            {selected &&
                <Icon name='TickIcon' /> 
            }
        </Box>
        {isActive &&
            <span className='active-indicator'></span>
        }
    </NavLinkWrap>
  )
}
