import React, { useContext, useEffect, useState, useRef } from 'react'
import { useTheme } from '@mui/material'
import { HeaderMenuRightWrap } from './header-menu-right.style'
import { HeaderSearch, NavLink } from '@components'
import { Box, Icon, Menu } from '@ntpkunity/controls'
import { Navigation } from '@constants'
import {  changeUserLoggedInState, ILanguageCode, loadDefaultCode, loadPlaceholder, updateHeaderFooter, useAppContext } from '@app/context-provider'
import { useAuthentication } from 'hooks/useAuthenticationHook'
import { useWorkflowContext } from '@app/router-workflow'
import clsx from 'clsx'
import { useQueryClient } from "react-query";
import { useUpdateLanguage } from 'hooks/tenant-management'

import { logout } from '@ntpkunity/controls-ums'
import { useNavigate } from 'react-router-dom'

const defaultValues = {
  logout: 'Logout',
  profile: 'Profile',
  login: 'Login',
  createAccount: 'Create Account',
}

export const HeaderMenuRight = ({ menuOptions }: { menuOptions: any }) => {
  const theme = useTheme()
  const { navigateNext } = useWorkflowContext()
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { authenticationStatus } = useAuthentication()
  const {mutate:updateLanguage } = useUpdateLanguage()
  const [openSearch, setOpenSearch] = useState(true)
  const { state: {
    slug,
    tenant_id,
    allLanguage: { languages }
  }, dispatch
  } = useAppContext()

  const updatePlaceholder =  (data:ILanguageCode)=>{
    updateLanguage({
      slug:slug , tenant_id: tenant_id, language_id:data.id
    },{
      onSuccess:(response:any)=>{
      dispatch(loadDefaultCode(data.id))
      dispatch(loadPlaceholder({placeholders:response?.placeholders}))
      }
    })
  }

  return (
    <>
      <HeaderMenuRightWrap theme={theme} className='header-menu-right-wrap'>
        <ul className="navbar-nav nav-right">
          <li className={clsx('nav-item icon-item dropdown', authenticationStatus ? 'is-login' : 'is-logout')}>
            <Menu
              theme={theme}
              disablePortal={true}
              options={menuOptions}
              render={(cb) => (
                <>
                  <NavLink
                    icon={'PersonIcon'}
                    onClick={cb as any}
                    ariaLabel='Person Icon'
                  />
                </>
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              handleOptionClick={(e, key, value) => {
                switch (key) {
                  case 'Login':
                    navigateNext(Navigation.AUTHENTICATION.SIGN_IN_PAGE)
                    break;
                  case 'Create Account':
                    navigateNext(Navigation.AUTHENTICATION.SIGN_UP_PAGE)
                    break;
                  case 'Profile':
                    navigateNext(Navigation.PROFILE.USER_PROFILE)
                    break;
                  case 'deals':
                    navigateNext(Navigation.PROFILE.ORDERS)
                    break;
                  case 'Logout':
                    navigate(`${Navigation.AUTHENTICATION.LOG_IN_PAGE.replace(':tenant', slug)}`)
                    localStorage.clear();
                    dispatch(changeUserLoggedInState(false))
                    dispatch(updateHeaderFooter({}, [], [], ''));
                    queryClient.clear();
                    logout((`${Navigation.AUTHENTICATION.LOG_IN_PAGE.replace(':tenant', slug)}`))
                    break;
                  case 'Language':
                    updatePlaceholder(value)
                    break;
                }
              }}
            />
          </li>
        </ul>

      </HeaderMenuRightWrap>
      <HeaderSearch
        searchOpen={openSearch}
        onClose={() => { setOpenSearch(!openSearch) }}
      />
    </>
  )
}
