import {IconButton, useTheme} from '@mui/material'
import {
    Box,
    Button,
    Dialog,
    Grid,
    Icon,
    Input,
    Select,
    Typography,
} from '@ntpkunity/controls'
import { Popup, Stack, Textbox } from '@components'
import React, {FC, useContext, useEffect, useState} from 'react'
import { PageWrap } from './strip-payment.style'
import {Controller, useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {CardIcon} from "../../constants/card-type";
import {convertToAmountWithDecimals, determineCardType, getSubstring} from "../../helpers/methods";
import {useStripeCreateToken, useStripePaymentIntent} from "../../hooks/stripe-payments";
import {useQueryClient} from "react-query";
import {OrderStatus, QueryKeys} from "@constants";
import {SnackbarUtility} from "@utilities";
import DisableLoader from "../../public/assets/images/loader-disabled-btn.gif";
import { AppContext } from '@app/context-provider'
import {EstimateProvider, PaymentErrors, TaggingClasses} from "../../constants/enums";
import clsx from 'clsx'
import {IDealerProfile} from "@interfaces";
import {useGetConfiguration} from "../../hooks/trade-in-management";
import {LenderIntegrationType} from "../../constants/providers";

export const StripPaymentCardDetailPopup:FC<{
    openPopup: boolean,
    setOpenPopup: any,
    signingAmount: number,
    referenceNumber: string,
    paymentDetails: any,
    handleCardDetailBackButton: any,
    snackbardispatch: any,
    selectedPaymentType: any,
    plaidConfig: any,
    paymentAllowdLimitDetails:any
}> = ({openPopup, setOpenPopup, signingAmount, referenceNumber, paymentDetails, handleCardDetailBackButton, snackbardispatch, selectedPaymentType, plaidConfig, paymentAllowdLimitDetails}) => {
    const theme = useTheme()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders;
    const default_currency_code = appState.state?.default_currency_code;
    const defaultCurrency = appState.state.default_currency_symbol;
    const [cardType, setCardType] = useState<string>('')
    const { mutate: createToken, isLoading: creatingToken } = useStripeCreateToken()
    const { mutate: paymentIntent, isLoading: makingPayment } = useStripePaymentIntent()
    const queryClient = useQueryClient();
    const [existingCard, setExistingCard] = useState([])
    const [hideCardDetail, setHideCardDetail] =  useState(false)
    const [cardTextInput, setCardTextInput] =  useState(false)
    const [maxAllowedLimit, setMaxAllowedLimit] = useState<any>(0)
    const dealer_profile = JSON.parse(localStorage.getItem("dealer_information"))?.dealer
    const [stripeConnectDealerId, setStripeConnectDealerId]= useState(null)
    const stripeConnect: any = queryClient.getQueryData([
      QueryKeys.GET_CONFIGURATION_PROVIDER, EstimateProvider.STRIPE]
    )
    const dealerProfile: IDealerProfile = queryClient.getQueryData([
        QueryKeys.DEALER_PROFILE, dealer_profile?.dealer_code]
    )
    const schema = yup.object().shape({
        payment_method_id: yup.string().nullable()
            .test('number_check', PLACEHOLDERS.STRIPE_PAYMENT_CARD_NUMBER_REQUIRED, function(value) {
                const { number } = this.parent;
                return number  || selectedPaymentType === 'ach_debit' ? true : value ? true : false;
            }),
        number: yup.string()
            .matches(/^[0-9]+$/, PLACEHOLDERS.STRIPE_PAYMENT_DIGITS_ONLY)
            .min(16, PLACEHOLDERS.STRIPE_PAYMENT_EXACTLY_16_DIGITS)
            .max(16, PLACEHOLDERS.STRIPE_PAYMENT_EXACTLY_16_DIGITS)
            .test('payment_method_id_check', PLACEHOLDERS.STRIPE_PAYMENT_CARD_NUMBER_REQUIRED, function (value) {
                const { payment_method_id } = this.parent;

                return payment_method_id  || selectedPaymentType === 'ach_debit' ? true : value ? true : false;
            }),
        card_holder_name: yup.string()
            .test('payment_method_id_check', PLACEHOLDERS.STRIPE_PAYMENT_CARD_HOLDER_NAME_REQUIRED, function (value) {
                const { payment_method_id } = this.parent;
                return payment_method_id  || selectedPaymentType === 'ach_debit' ? true : value ? true : false;
            }),
        expiry_date: yup.string()
            .max(4, PLACEHOLDERS.STRIPE_PAYMENT_INVALID_EXPIRATION_DATE)
            .min(4, PLACEHOLDERS.STRIPE_PAYMENT_INVALID_EXPIRATION_DATE)
            .test('payment_method_id_check', PLACEHOLDERS.STRIPE_PAYMENT_EXPIRY_DATE_REQUIRED, function (value) {
                const { payment_method_id } = this.parent;
                return payment_method_id  || selectedPaymentType === 'ach_debit' ? true : value ? true : false;
            }),
        cvc: yup.string()
            .matches(/^[0-9]+$/, PLACEHOLDERS.STRIPE_PAYMENT_DIGITS_ONLY)
            .min(3, PLACEHOLDERS.STRIPE_PAYMENT_EXACTLY_3_DIGITS)
            .max(3, PLACEHOLDERS.STRIPE_PAYMENT_EXACTLY_3_DIGITS)
            .test('payment_method_id_check', PLACEHOLDERS.STRIPE_PAYMENT_CVC_REQUIRED, function (value) {
                const { payment_method_id } = this.parent;
                return payment_method_id  || selectedPaymentType === 'ach_debit' ? true : value ? true : false;
            }),
            amount: yup.string()
            .nullable()
            .transform((value) => isNaN(value) ? null : value)
            .test(
              'custom-validation',
              PLACEHOLDERS.STRIPE_PAYMENT_AMOUNT_TO_PAY_REQUIRED,
              (value) => {
                if (value === "") return false;
                const numericValue = Number(value);
                return value === null || !isNaN(numericValue) && numericValue > 0;
              })
            });



    const messages = {
        title: PLACEHOLDERS.STRIPE_PAYMENT_ADD_NEW_CARD,
        label: {
            cardHolderName: PLACEHOLDERS.STRIPE_PAYMENT_CARD_HOLDER_NAME,
            cardNumber: PLACEHOLDERS.STRIPE_PAYMENT_CARD_NUMBER,
            expiryDate: PLACEHOLDERS.STRIPE_PAYMENT_EXPIRY_DATE,
            cvc: PLACEHOLDERS.STRIPE_PAYMENT_CVC,
            amount: PLACEHOLDERS.STRIPE_PAYMENT_AMOUNT_PAYMENT_FROM_CARD,
            payment_method_id: PLACEHOLDERS.STRIPE_PAYMENT_CARD_NUMBER
        },
        name: {
            cardNumber: 'number',
            cardHolderName: 'card_holder_name',
            expiryData: 'expiry_date',
            cvc: 'cvc',
            amount: 'amount',
            payment_method_id: 'payment_method_id'
        },
        validation: {
            cardNumber: 'Card number',
            cardHolderName: 'Card holder name',
            expiryData: 'Expiry date',
            amount: 'Amount',
            payment_method_id: 'Card Number'
        },
        button: {
            save: 'Save Card',
            wait: 'Wait...',
            cancel: 'Cancel'
        }
    }

    const {
        control,
        clearErrors,
        handleSubmit,
        reset,
        setError,
        formState: { errors }} =
        useForm({resolver: yupResolver(schema)});
    const handleCloseDialog = () => {
        setHideCardDetail(false)
        setCardTextInput(false)
        reset()
        setOpenPopup(false)
        setCardType('')
    }

    const user_settings = JSON.parse(localStorage.getItem("settings"))

    useEffect(() => {
        if(stripeConnect?.is_active && dealerProfile?.stripe_dealer_id){
            setStripeConnectDealerId(dealerProfile?.stripe_dealer_id)
        }
    }, [stripeConnect, dealerProfile?.stripe_dealer_id,stripeConnectDealerId])

    useEffect(() => {
        const existingCards = [];
        existingCards.push({
            text: PLACEHOLDERS.STRIPE_PAYMENT_ADD_NEW_CARD,
            value: null,
        })

        paymentDetails?.card_details?.map(item => {
            let cardType = item?.card_details?.brand === 'mastercard' ? 'masterCard' : item?.card_details?.brand;
            cardType = cardType === 'ach' ? false : cardType;
            existingCards.push({
                text: (
                    <>
                        <span className="option-text">************ {item?.card_details?.last4}</span>
                        <span className="currency-icon">
                        {cardType && <Icon name={CardIcon[cardType]} />}
                    </span>
                    </>
                ),
                value: item?.pm_id,
            })
            const cardDetails = paymentDetails?.card_details?.[0]?.card_details;
            const funding = cardDetails?.funding;
            const fundingLimits = {
                credit: paymentAllowdLimitDetails?.credit_card_limit,
                debit: paymentAllowdLimitDetails?.debit_card_limit,
                bank_account: paymentAllowdLimitDetails?.bank_account_limit,
            }
            const limit = fundingLimits[funding] || false;
            setMaxAllowedLimit(limit);
        });

        setExistingCard(existingCards);

    }, [paymentDetails,paymentAllowdLimitDetails]);


    const onSubmit = async (data: { number: any; expiry_date: any; cvc: any; amount: any, payment_method_id: any }) => {
        const { number, expiry_date, cvc, amount, payment_method_id } = data;
        let totalPaidAmountFloat = parseFloat(paymentDetails?.total_paid_amount);

        totalPaidAmountFloat = isNaN(totalPaidAmountFloat) ? 0 : totalPaidAmountFloat;
        const remainingAmount = totalPaidAmountFloat ? (signingAmount - totalPaidAmountFloat) : signingAmount;
        const paymentDifference = remainingAmount - amount;
        const roundedRemainingAmount = parseFloat(remainingAmount?.toFixed(2));
        const roundedPaymentDifference = parseFloat(paymentDifference?.toFixed(2));

        if (amount > roundedRemainingAmount) {
            setError('amount', {
                type: 'manual',
                message: `${PLACEHOLDERS.STRIPE_PAYMENT_MAX_AMOUNT_LIMIT} ${defaultCurrency}${roundedRemainingAmount?.toFixed(2)}${PLACEHOLDERS.DOT_TEXT}`,
            });
            return;
        }
        else if (maxAllowedLimit && amount > maxAllowedLimit) {
            setError('amount', {
                type: 'manual',
                message: `${PLACEHOLDERS.STRIPE_PAYMENT_MAXIMUM_ALLOWED_LIMIT} ${defaultCurrency}${maxAllowedLimit?.toFixed(2)}${PLACEHOLDERS.DOT_TEXT}`,
            });
            return;
        }
        else if (amount <= 0.50) {
            setError('amount', {
                type: 'manual',
                message: 'Amount must be greater than 0.50',
            });
            return;
        }
        else if ((Math.abs(roundedPaymentDifference) <= 0.50) && roundedPaymentDifference !== 0) {
            setError('amount', {
                type: 'manual',
                message: `${PLACEHOLDERS.STRIPE_PAYMENT_REMAINING_AMOUNT} ${roundedPaymentDifference?.toFixed(2)} ${PLACEHOLDERS.STRIPE_PAYMENT_BELOW_MINIMUM}, ${PLACEHOLDERS.STRIPE_PAYMENT_MAKE_FULL_PAYMENT} ${roundedRemainingAmount?.toFixed(2)}${PLACEHOLDERS.DOT_TEXT}`,
            });
            return;
        }
        const handlePaymentSuccess = () => {
            handleCloseDialog();
            queryClient.invalidateQueries({ queryKey: [QueryKeys.STRIPE_PAYMENT_DETAILS, referenceNumber] });

            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.STRIPE_PAYMENT_PAYMENT_SUCCESSFULLY_PROCESSED))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
        };
        const handleCancelledDeal = () =>{
            handleCloseDialog()
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.CANCELLED_DEAL_SUBTITLE_TEXT))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000)
            queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, referenceNumber]);
        }
        const handlePaymentError = (errorCode) => {
            const errorMessage = PaymentErrors[errorCode] || 'Unknown error code.';
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(errorMessage))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
        };

        const paymentIntentData = {
            reference_number: referenceNumber,
            amount: amount,
            email: user_settings?.email ?? "",
            customer_name: user_settings?.user_name ?? "",
            token: null,
            payment_method_id: null,
            payment_method_type: "card",
            account_id: null,
            receiver_account: stripeConnectDealerId
        }
        if(selectedPaymentType === 'card') {

            paymentIntentData.payment_method_type =  'card'
            if (!payment_method_id) {
                delete paymentIntentData['payment_method_id']
                const expiryMonth = getSubstring(expiry_date, 0, 2);
                const expiryYear = getSubstring(expiry_date, 2, 4);


                const createTokenPayload = {
                    card: {
                        number,
                        exp_month: expiryMonth,
                        exp_year: expiryYear,
                        cvc
                    },
                    stripe_connect_id: stripeConnectDealerId
                };
                createToken({createTokenPayload}, {
                    onSuccess(response) {
                        const {id} = response;
                        paymentIntentData.token = id;
                        paymentIntent(paymentIntentData, {
                            onSuccess(response: any) {
                                const errorCode = response?.detail?.code;
                                const status = response?.detail?.status;
                                if(errorCode){
                                    handlePaymentError(errorCode)
                                    return;
                                }
                                if (status === OrderStatus.CANCELLED) {
                                    handleCancelledDeal();
                                    return;
                                }
                                handlePaymentSuccess()
                            },
                            onError(res){
                                if (res?.detail?.status === OrderStatus.CANCELLED) {
                                    handleCancelledDeal()
                                }
                            }
                        });
                    },
                    onError(error: any) {
                        if (error) {
                            const {param, message} = (error as any);
                            if (param === undefined) {
                                snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.STRIPE_PAYMENT_TOKEN_CREATION_FAILED))
                                setTimeout(() => {
                                    snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                                }, 5000);
                            } else if (param === 'exp_month' || param === 'exp_year') {

                                setError('expiry_date', {
                                    type: 'manual',
                                    message: message,
                                });
                            } else {

                                setError(param, {
                                    type: 'manual',
                                    message: message,
                                });
                            }
                        }
                    },
                })
            } else {
                if(payment_method_id.startsWith('ba')){
                    paymentIntentData.payment_method_type =  'ach_debit'
                }
                paymentIntentData.payment_method_id = payment_method_id
                paymentIntent(paymentIntentData, {
                    onSuccess() {
                        handlePaymentSuccess()
                    },
                    onError(res) {
                        if (res?.detail?.status === OrderStatus.CANCELLED) {
                            handleCancelledDeal()
                        }
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.STRIPE_PAYMENT_ERROR_OCCURRED))
                        setTimeout(() => {
                            snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                        }, 5000);
                    }
                });
            }
        }
        else{
            paymentIntentData.payment_method_type =  'ach_debit'
            paymentIntentData.account_id = plaidConfig?.metadata?.account_id
            paymentIntentData.token = plaidConfig?.publicToken
            paymentIntent(paymentIntentData, {
                onSuccess(response) {
                    handlePaymentSuccess()
                },
                onError(error) {
                    if(error?.detail?.status === OrderStatus.CANCELLED) {
                      handleCancelledDeal()
                    }
                    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.STRIPE_PAYMENT_ERROR_OCCURRED))
                    setTimeout(() => {
                        snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                    }, 5000);
                }
            });
        }


    };


    return(
        <Popup>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Dialog
                    theme={theme}
                    size="sm"
                    open={openPopup}
                    title={PLACEHOLDERS.STRIPE_PAYMENT_ADD_CARD_DETAILS}
                    onCloseDialog={() => handleCloseDialog()}
                    onBackBtn={() => handleCardDetailBackButton()}
                    disablePortal
                    customFooter={
                        <>
                            <Button
                                className={clsx({[`${TaggingClasses.PAYMENT}-pay-now`]:true})}
                                theme={theme}
                                primary
                                text={PLACEHOLDERS.STRIPE_PAYMENT_PAY_NOW}
                                type={"submit"}
                                disabled={makingPayment || creatingToken || !stripeConnect || !dealerProfile}
                                startIcon={
                                    (makingPayment || creatingToken) &&
                                    <img src={DisableLoader} alt="Loader" />
                                }
                            />

                        </>
                    }
                >
                    <PageWrap className='page-wrap dialog-wrap' theme={theme}>
                        <Stack bgLight paddingMd={3} dFlex justifyBetween>
                            <Typography theme={theme} variant='subtitle2' className='' component={'p'}>
                                {PLACEHOLDERS.STRIPE_PAYMENT_OUTSTANDING_BALANCE}
                            </Typography>
                            <Typography theme={theme} variant='subtitle2' className='text-primary'
                                        component={'p'}>{convertToAmountWithDecimals(signingAmount - (paymentDetails?.total_paid_amount !== null ? paymentDetails?.total_paid_amount: 0), default_currency_code)}</Typography>
                        </Stack>
                        <Stack bgLight paddingMd={3} marginTopMd={2} marginTopXs={2}>
                                <Grid
                                    theme={theme}
                                    container
                                    rowSpacing={{xs: 3}}
                                    columnSpacing={{xs: 2}}
                                >
                                    {plaidConfig?.publicToken ?
                                        <Grid item xs={12} theme={theme}>
                                            <Controller
                                                control={control}
                                                name="plaidConfig"
                                                render={
                                                    ({field}) => (
                                                        <Textbox>
                                                            <Input
                                                                theme={theme}
                                                                fullWidth
                                                                label={`**** **** **** ${plaidConfig?.metadata?.account?.mask}`}
                                                                value={`**** **** **** ${plaidConfig?.metadata?.account?.mask}`}
                                                                type="text"
                                                                {...field}
                                                                disabled={true}
                                                            />
                                                        </Textbox>
                                                    )}
                                            />

                                        </Grid>
                                        :
                                        cardTextInput ?

                                            <Grid item xs={12} theme={theme}>
                                                <Controller
                                                    control={control}
                                                    name={messages.name.cardNumber}
                                                    render={
                                                        ({field}) => (
                                                            <Textbox>
                                                                <Input
                                                                    maskNumeric
                                                                    masking
                                                                    format="#### #### #### ####"
                                                                    theme={theme}
                                                                    fullWidth
                                                                    label={messages.label.cardNumber}
                                                                    type="text"
                                                                    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                                                                    min={9999999999999999}
                                                                    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                                                                    max={9999999999999999}
                                                                    {...field}
                                                                    placeholder="0000 0000 0000 0000"
                                                                    error={errors?.[messages.name.cardNumber]?.message?.toString()}
                                                                    onChange={(e) => {
                                                                        field.onChange(e)
                                                                        determineCardType(e, setCardType)
                                                                        clearErrors()

                                                                    }}

                                                                    endAdornment={
                                                                        <>{(cardType.length > 0) &&
                                                                            <Icon name={CardIcon[cardType]}/>}
                                                                            <IconButton className="drop-icon"  onClick={() => {
                                                                                setCardTextInput(false)
                                                                                reset({
                                                                                    payment_method_id: undefined,
                                                                                });
                                                                            }} >
                                                                                <Icon name="ChevronDown" />
                                                                            </IconButton>
                                                                        </>
                                                                    }
                                                                    disabled={makingPayment || creatingToken}
                                                                />
                                                            </Textbox>
                                                        )}
                                                />

                                            </Grid>
                                            :

                                            <Grid item xs={12} theme={theme}>
                                                <Box
                                                    theme={theme}
                                                    className="card-select"
                                                >
                                                    <Controller
                                                        control={control}
                                                        name={messages.name.payment_method_id}
                                                        render={
                                                            ({field}) => (
                                                                <Select
                                                                    defaultValue={''}
                                                                    disablePortal={true}
                                                                    theme={theme}
                                                                    items={existingCard}
                                                                    fullWidth
                                                                    label={messages.label.payment_method_id}
                                                                    {...field}
                                                                    placeholder="0000 0000 0000 0000"
                                                                    selectError={errors?.[messages.name.payment_method_id]?.message?.toString()}
                                                                    onChange={(e) => {
                                                                        if(e.target.value === null){
                                                                            setHideCardDetail(false)
                                                                            setCardTextInput(true)
                                                                            reset({
                                                                                number: undefined,
                                                                            });
                                                                        }
                                                                        else{
                                                                            setHideCardDetail(true)
                                                                        }
                                                                        field.onChange(e)
                                                                        clearErrors()
                                                                    }}
                                                                    disabled={makingPayment || creatingToken || plaidConfig?.publicToken}
                                                                />
                                                            )}
                                                    />
                                                </Box>


                                            </Grid>
                                    }
                                    {hideCardDetail || plaidConfig?.publicToken ? false:
                                    <>
                                        <Grid item xs={12} theme={theme}>
                                            <Controller
                                                control={control}
                                                name={messages.name.cardHolderName}
                                                render={
                                                    ({field}) => (
                                                        <Textbox>
                                                            <Input
                                                                theme={theme}
                                                                type='text'
                                                                label={messages.label.cardHolderName}
                                                                fullWidth
                                                                {...field}
                                                                placeholder={PLACEHOLDERS.STRIPE_PAYMENT_TYPE_HERE}
                                                                error={errors?.[
                                                                    messages.name.cardHolderName
                                                                    ]?.message?.toString()}
                                                                onChange={(e) => {
                                                                    field.onChange(e)
                                                                    clearErrors()
                                                                }}
                                                                disabled={makingPayment || creatingToken}
                                                            />
                                                        </Textbox>
                                                    )}
                                            />

                                        </Grid>
                                        <Grid item xs={6} theme={theme}>
                                            <Controller
                                                control={control}
                                                name={messages.name.expiryData}
                                                render={
                                                    ({field}) => (
                                                        <Textbox>
                                                            <Input
                                                                theme={theme}
                                                                type='text'
                                                                label={messages.label.expiryDate}
                                                                fullWidth
                                                                {...field}
                                                                maskNumeric
                                                                masking
                                                                format="##/##"
                                                                placeholder={PLACEHOLDERS.STRIPE_PAYMENT_EXPIRY_DATE_FORMAT}
                                                                error={errors?.[
                                                                    messages.name.expiryData
                                                                    ]?.message?.toString()}
                                                                onChange={(e) => {
                                                                    field.onChange(e)
                                                                    clearErrors()
                                                                }}
                                                                disabled={makingPayment || creatingToken}
                                                            />
                                                        </Textbox>
                                                    )}
                                            />

                                        </Grid>
                                        <Grid item xs={6} theme={theme}>
                                            <Controller
                                                control={control}
                                                name={messages.name.cvc}
                                                render={
                                                    ({field}) => (
                                                        <Textbox>
                                                            <Input
                                                                theme={theme}
                                                                type='text'
                                                                label={messages.label.cvc}
                                                                fullWidth
                                                                {...field}
                                                                min={999}
                                                                max={999}
                                                                {...field}
                                                                placeholder="000"
                                                                error={errors?.[messages.name.cvc]?.message?.toString()}
                                                                onChange={(e) => {
                                                                    field.onChange(e)
                                                                    clearErrors()
                                                                }}
                                                                disabled={makingPayment || creatingToken}
                                                            />
                                                        </Textbox>
                                                    )}
                                            />
                                        </Grid>
                                    </>
                                    }

                                    <Grid item xs={12} theme={theme}>
                                        <Controller
                                            control={control}
                                            name={messages.name.amount}
                                            render={
                                                ({field}) => (
                                                    <Textbox>
                                                        <Input
                                                            theme={theme}
                                                            type='text'
                                                            label={messages.label.amount}
                                                            maskNumeric
                                                            masking
                                                            placeholder={'0'}
                                                            helperText={!errors?.[messages.name.amount] && maxAllowedLimit ? `${PLACEHOLDERS.STRIPE_PAYMENT_MAXIMUM_ALLOWED_LIMIT}: ${defaultCurrency}${maxAllowedLimit}` : ''}
                                                            startAdornment={<><Typography theme={theme}
                                                                                          className='text-muted'
                                                                                          pr={2}
                                                                                          component={'p'}
                                                                                          variant='body1'>{defaultCurrency}</Typography></>}
                                                            fullWidth

                                                            error={errors?.[messages.name.amount]?.message?.toString()}
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e)
                                                                clearErrors()
                                                            }}
                                                            disabled={makingPayment || creatingToken}
                                                        />
                                                    </Textbox>
                                                )}
                                        />
                                    </Grid>
                                </Grid>

                        </Stack>
                    </PageWrap>
                </Dialog>
            </form>

        </Popup>
)
}


