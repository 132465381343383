import { Link, useTheme } from '@mui/material'
import { Box, Button, Icon, Menu, Tooltip, Typography } from '@ntpkunity/controls'
import { ProductOrderCardProps } from './product-order-card.props'
import { ProductOrderCardWrap } from './product-order-card.style'
import clsx from 'clsx'
import { Status } from 'constants/enums'
import { useAppContext } from '@app/context-provider'
import { Image } from '@ntpkunity/controls'
import { Stack } from '@components'
import { camelCase } from 'lodash'
export const ProductOrderCard = ({
	taggingClass,
	secondaryTextOne,
	setWithdrawOrder,
	secondaryTextTwo,
	primaryTextOne,
	primaryTextTwo,
	orderId,
	productImg,
	onBookMarkClick,
	status,
	viewBtnVisibility,
	vin,
	onClick,
}: ProductOrderCardProps) => {
	const theme = useTheme()
	const { state: appState } = useAppContext()
	const PLACEHOLDERS = appState.language.placeholders

	return (
		<ProductOrderCardWrap className={clsx({ [`${taggingClass}-order`]: !!taggingClass, 'product-order-card': true })} theme={theme}>
			<Box theme={theme} display={'none'}>
				{Status.Approved === status ? (
					false
				) : (
					<Button
						defaultBtn
						theme={theme}
						onClick={onBookMarkClick}
						className='bookmark'
						iconText={<Icon name='BookmarkOutlineIcon' className='' />}
						ariaLabel='bookmark icon '
					/>
				)}
				{Status.Approved === status ? (
					<Button
						defaultBtn
						theme={theme}
						onClick={onBookMarkClick}
						className='bookmarked'
						iconText={<Icon name='BookmarkFilledIcon' className='' />}
						ariaLabel='bookmarked icon '
					/>
				) : (
					false
				)}
			</Box>
			<Box theme={theme} className='card-head'>
				{status && (
					<Typography
						theme={theme}
						variant='caption'
						component={'small'}
						display={'block'}
						className={clsx({
							'fw-sbold status': true,
							'in-progress':
								status == Status.InProgress ||
								status == Status.Approved ||
								status == Status.Submitted ||
								status == Status.ReSubmitted ||
								status == Status.Reviewed,
							completed: status == Status.Completed,
							draft: status == Status.Draft,
							conditioned: status == Status.Conditioned || status == Status.PendingConfirmation,
							cancelled: status == Status.Cancelled || status == Status.Declined || status == Status.Expired || status == Status.REJECTED,
						})}
					>
						{' '}
						{status}
					</Typography>
				)}
				{productImg ? (
					<Box theme={theme} className='card-img'>
						<Image theme={theme} src={productImg} aspectRatio='16:9' alt='product image' />
					</Box>
				) : (
					<Box theme={theme} className='card-img'>
						<Stack bgLight dFlex alignItemsCenter className='no-image'>
							<Icon name='ImageIcon' />
						</Stack>
					</Box>
				)}
			</Box>
			<Box theme={theme} className='card-content'>
				<Box theme={theme} className='title'>
					{primaryTextOne && (
						<Box theme={theme} className='f-row'>
							<Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted fw-medium'>
								{secondaryTextOne}
							</Typography>
							<Tooltip theme={theme} title={primaryTextOne} placement={'top'}>
								<Typography theme={theme} variant='subtitle2' component={'p'} display={'block'} className='text-truncated'>
									{primaryTextOne}
								</Typography>
							</Tooltip>
						</Box>
					)}
					{primaryTextTwo && (
						<Box theme={theme} className='s-row'>
							<Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted fw-medium'>
								{secondaryTextTwo}
							</Typography>
							<Tooltip theme={theme} title={primaryTextTwo} placement={'top'}>
								<Typography theme={theme} variant='subtitle2' component={'p'} display={'block'} className='text-truncated'>
									{primaryTextTwo}
								</Typography>
							</Tooltip>
						</Box>
					)}
				</Box>
				<Box theme={theme} className='id-vin'>
					{orderId && (
						<Typography theme={theme} variant='caption' component={'small'} mb={0.5} className='order-id'>
							<span className='fw-sbold'>{PLACEHOLDERS.ORDER_ID_TEXT} </span> <span className='text-muted'>{orderId}</span>
						</Typography>
					)}
					{vin && (
						<Typography theme={theme} variant='caption' component={'small'} className='order-id'>
							<span className='fw-sbold'>{PLACEHOLDERS.VIN_TEXT} </span> <span className='text-muted'>{vin}</span>
						</Typography>
					)}
				</Box>
			</Box>

			<Box theme={theme} className='card-action'>
				{!viewBtnVisibility && (
					<Menu
						theme={theme}
						options={[
							{
								optionText: PLACEHOLDERS.DEAL_VIEW_BUTTON_TEXT,
								optionkey: camelCase(PLACEHOLDERS.DEAL_VIEW_BUTTON_TEXT),
								optionValue: camelCase(PLACEHOLDERS.DEAL_VIEW_BUTTON_TEXT),
							},
							{
								optionText: PLACEHOLDERS.WITHDRAW_DEAL_TEXT,
								optionkey: camelCase(PLACEHOLDERS.WITHDRAW_DEAL_TEXT),
								optionValue: camelCase(PLACEHOLDERS.WITHDRAW_DEAL_TEXT),
							},
						]}
						handleOptionClick={(e, key, value) => {
							switch (key) {
								case camelCase(PLACEHOLDERS.DEAL_VIEW_BUTTON_TEXT):
									onClick(PLACEHOLDERS.ORDER_VIEW_BUTTON_TEXT, vin, orderId)
									break
								case camelCase(PLACEHOLDERS.WITHDRAW_DEAL_TEXT):
									setWithdrawOrder(orderId)
									break
							}
						}}
						render={(onMenuSelection) => (
							<Button secondary theme={theme} iconText={<Icon name='MoreIcon' />} ariaLabel='More Icon' onClick={onMenuSelection} />
						)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					/>
				)}
				<Button
					className={clsx({ [`${taggingClass}-order`]: !!taggingClass })}
					theme={theme}
					primary
					fullWidth
					onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClick((e.target as HTMLButtonElement).textContent, vin, orderId)}
					text={viewBtnVisibility ? PLACEHOLDERS?.DEAL_VIEW_BUTTON_TEXT : PLACEHOLDERS?.CONTINUE_ORDER_TEXT}
				/>
			</Box>
		</ProductOrderCardWrap>
	)
}
