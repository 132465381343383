import { openChatBox, useAppContext } from "@app/context-provider";
import { NeedHelp, Stack } from "@components";
import { OrderStatus } from "@constants";
import { formatUSPhoneNumberWithCountryCode } from "@helpers";
import { useVerifyAddress } from "@hooks";
import { IDealerProfile, IVehicle } from "@interfaces";
import { MapContainer, PageWrap } from "@libraries";
import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Typography } from "@ntpkunity/controls";
import { useOrderContext } from "@pages";
import { IntegrationProvider, LenderIntegrationType } from "constants/providers";
import { useGetIntegrationByProviderName } from "hooks/order-management";
import { useEffect, useState } from "react";


export const NeedHelpOrder = ({
    vehicle, setOpenPopup, dealerProfile, dealerByDealerCode, handleDealerNameLink, handleCallButtonClick, handleGetDirectionsClick
}: { vehicle: IVehicle, setOpenPopup: any, dealerProfile: IDealerProfile, dealerByDealerCode: any, handleDealerNameLink: any, handleCallButtonClick: any, handleGetDirectionsClick: any }) => {
    const theme = useTheme();
    const { state: { order } } = useOrderContext()
    const { state: appState,dispatch } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders;
    const company_id = appState.tenant
    const { openChat } = appState;
    const { mutate: verifyAddress, isLoading: isVerifyAddressLoading } = useVerifyAddress();
    const { mutate: getProvider } = useGetIntegrationByProviderName(IntegrationProvider.GOOGLE,
        LenderIntegrationType.ADDRESS_VALIDATION, company_id, appState?.slug);

    const [addressLocation, setAddressLocation] = useState<any>()
    const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)

    const getVerifyGoogleAddress = (address: string) => {
        verifyAddress(
            {
                address: address,
            },
            {
                onSuccess: (data) => {
                    setAddressLocation(data.results[0]?.geometry?.location)
                },
            }
        );
    };
    useEffect(() => {
        if (dealerProfile?.dealer_address?.address_line_1) {
            getVerifyGoogleAddress(dealerProfile?.dealer_address?.address_line_1 + " " + dealerProfile?.dealer_address?.address_line_2 + " " + dealerProfile?.dealer_address?.city + " " + dealerProfile?.dealer_address?.state_name)
        }
    }, [dealerProfile])

    useEffect(() => {
        if (company_id) {
            getProvider(
                {
                    provider_name: IntegrationProvider.GOOGLE,
                    integration_type: LenderIntegrationType.ADDRESS_VALIDATION,
                    company_id: company_id
                },
                {
                    onSuccess(response: any) {
                        setIsGoogleEnabled(response?.is_active)
                    }
                }
            )
        }
    }, [company_id])
    return (
        <Grid theme={theme} item xs={12} md={6}>
            <Stack paddingMd={2} paddingXs={2}>
                {order?.status !== OrderStatus.COMPLETED && (
                    <Box
                        theme={theme}
                        className="product-info"
                        display={"flex"}
                        flexDirection={"column"}
                        gap={3}
                        p={"16px 24px"}
                    >
                        <Box theme={theme}>
                            <Typography
                                theme={theme}
                                className="subtitle text-muted fw-medium"
                                variant="body1"
                                component={"span"}
                                display={"block"}
                            >
                                {`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}
                            </Typography>
                            <Box
                                theme={theme}
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                            >
                                {vehicle?.trim_description && (<Typography theme={theme} component={"h3"} variant="h3">
                                    {vehicle?.trim_description}
                                </Typography>)}
                                <Button
                                    defaultBtn
                                    theme={theme}
                                    onClick={() => {
                                        setOpenPopup(true);
                                    }}
                                    iconText={
                                        <Icon
                                            className="text-primary"
                                            name="CicleLinkIcon"
                                        />
                                    }
                                    ariaLabel="circle link icon"
                                />
                            </Box>
                        </Box>
                        <Box theme={theme}>
                            <Typography
                                theme={theme}
                                component={"span"}
                                variant="body2"
                                className="text-muted"
                                display={"block"}
                            >
                                {`${order?.contract_term} ${PLACEHOLDERS.SUBMIT_ORDER_MONTHS} ${order?.finance_type} ${PLACEHOLDERS.FOR_LOWERCASE_TEXT}`}
                            </Typography>
                            <Typography
                                theme={theme}
                                component={"h3"}
                                variant="h3"
                                className="text-primary"
                            >
                                {
                                    (order?.estimated_monthly_payment
                                        ? `$${(order?.estimated_monthly_payment).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}/${PLACEHOLDERS.PER_MONTH}${PLACEHOLDERS.DOT_TEXT}`
                                        : '$0.00')
                                }
                            </Typography>
                        </Box>
                    </Box>
                )}
                <PageWrap theme={theme} className="google-map-empty-div">
                    <NeedHelp
                        title={PLACEHOLDERS.SUBMIT_ORDER_NEED_HELP}
                        mapAddress={`${(dealerProfile?.dealer_address?.address_line_1 || '').trim()}, ${(dealerProfile?.dealer_address?.city || '').trim()}, ${(dealerProfile?.dealer_address?.state_name || '').trim()}, ${(dealerProfile?.dealer_address?.zip_code || '').trim()}, ${PLACEHOLDERS.UNITED_STATES_TEXT}`}
                        mapAddressLinkText={`${dealerByDealerCode?.dealer_name},   `}
                        onMapAddressClick={handleDealerNameLink}
                        bgLight
                        onChatClick={() => {dispatch(openChatBox(!openChat)) }}
                        chatBtnText={PLACEHOLDERS.SUBMIT_ORDER_START_CHAT}
                        onCallClick={handleCallButtonClick}
                        callBtnText={formatUSPhoneNumberWithCountryCode(
                            dealerProfile?.customer_support_number?.replace(/(.{3})(?=.)/g, '$1 ')
                        )}
                        onDirectionClick={isGoogleEnabled ? handleGetDirectionsClick : undefined}
                        directionBtnText={isGoogleEnabled ? PLACEHOLDERS.SUBMIT_ORDER_GET_DIRECTIONS : undefined}

                        map={
                            <>
                                {isGoogleEnabled && addressLocation ? (<MapContainer location={addressLocation} />) : null}
                            </>
                        }
                    />
                </PageWrap>
            </Stack>
        </Grid>
    )
}