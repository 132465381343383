// TODO:: Need to be done in followup appointment story
// import { ProductDetailCard } from "@libraries"
// import { Accordion, Box } from "@ntpkunity/controls"
// import { AppointmentsWrap } from './appointments.style'
import { useContext } from 'react'
import { AppContext } from '@app/context-provider'
import { useTheme } from '@mui/material'
import { Grid, Skeleton, NoDataPreview } from '@ntpkunity/controls'
export const AppointmentsPage = () => {
	const theme = useTheme()
	const appState = useContext(AppContext)
	const PLACEHOLDERS = appState.state.language.placeholders
	return (
		<>
			<Grid theme={theme} item xs={12}>
				<NoDataPreview icon title={PLACEHOLDERS?.NO_APPOINTMENT_TITLE} subTitle={PLACEHOLDERS?.NO_APPOINTMENT_SUBTITLE} theme={theme} />{' '}
			</Grid>
			{/* TODO:: Need to be done in followup appointment story 
        <AppointmentsWrap theme={theme} className="appointment-wrap">
            <ProductDetailCard 
                title={'In-Store Pickup'}
                subTtile={'Free'}
                caption={'at 18800 Hawthorne Blvd, Torrance, CA 90504, United States'}
                listItems={[
                    {
                        title: 'Scheduled On',
                        value: 'July 00, 0000, 00:00 PM'
                    },
                    {
                        title: 'Contact Number',
                        value: '000 000 0000'
                    }
                ]}
                exportToCalendarText={'Time zone GMT-8'}
                onExportCalendarClick={()=>{}}
                rescheduleText="Need a different date or time"
                onRescheduleClick={()=>{}}
                mediaContent={<><iframe src="https://maps.google.com/maps?q=america&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no"></iframe></>}

            />
            <Box theme={theme} mt={3}>
                <ProductDetailCard 
                    title={'Delivery'}
                    subTtile={'Free'}
                    caption={'at 18800 Hawthorne Blvd, Torrance, CA 90504, United States'}
                    listItems={[
                        {
                            title: 'Scheduled On',
                            value: 'July 00, 0000, 00:00 PM'
                        },
                        {
                            title: 'Contact Number',
                            value: '000 000 0000'
                        }
                    ]}
                    exportToCalendarText={'Time zone GMT-8'}
                    onExportCalendarClick={()=>{}}
                    rescheduleText="Need a different date or time"
                    onRescheduleClick={()=>{}}
                    mediaContent={<><iframe src="https://maps.google.com/maps?q=america&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no"></iframe></>}

                />
            </Box>
            <Box theme={theme} mt={3} className="appointment-history">
                <Accordion
                    theme={theme}
                    items={[
                        {
                        content: (
                            <>
                                <ProductDetailCard 
                                    title={'Test Drive'}
                                    subTtile={'Free'}
                                    caption={'at 18800 Hawthorne Blvd, Torrance, CA 90504, United States'}
                                    listItems={[
                                        {
                                            title: 'Scheduled On',
                                            value: 'July 00, 0000, 00:00 PM'
                                        },
                                        {
                                            title: 'Contact Number',
                                            value: '000 000 0000'
                                        }
                                    ]}
                                    exportToCalendarText={'Time zone GMT-8'}
                                    onExportCalendarClick={()=>{}}
                                    rescheduleText="Need a different date or time"
                                    onRescheduleClick={()=>{}}
                                    mediaContent={<><iframe src="https://maps.google.com/maps?q=america&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no"></iframe></>}

                                />
                            </>
                        ),
                        title: "Your Appointments History",
                        key: "string-1",
                        isExpanded: false,
                        id: "string-1",
                        ariaControls: "string-1",
                        },
                    ]}
                    shouldCollapse
                />
            </Box>
        </AppointmentsWrap> */}
		</>
	)
}
