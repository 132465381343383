export const Endpoint = {
  GET_TENANT_DISCLAIMERS: "/config/configure/disclaimer-by-name",
  CUSTOMER_MANDATORY_DISCLAIMERS: "/signup/customer-mandatory-disclaimers",
  GET_DISCLAIMER_BY_NAME: "/configure/disclaimer-by-name",
  DISCLAIMER_AGREEMENT: "/configure/disclaimer-agreement",
  CUSTOMER: "/customer/",
  DEALER_PLACHOLDER:"config/get-tenant-languages/",
  USER_API_PUBLIC_DMS_URL: "/signup/dms",
  DMS_CREATE_CUSTOMER: "dms/customer/",
  DMS_CUSTOMER_DISCLAIMER_AGREEMENT: "dms/configure/disclaimer-agreement",
  DEALER: "/dealer",
  DEALERS: "/dealers",
  ACTIVE_TERMS: "config/contract-term/active",
  DEALER_CHAT_CONFIG: "/dealers-chat",
  VEHICLE_BY_VIN: "/vehicle",
  DMS_VEHICLE_BY_VIN: "config/vehicle",
  CONFIG_VEHICLE_DETAIL_BY_VIN: "config/vehicle-detail",
  PUBLIC_ORDER_INFO: "dms/order/order_information",
  GET_SETUP_DATA: "/get-setup-data",
  GET_DMS_SETUP_DATA: "dms/get-setup-data",
  OPTION_TRIM_CODE: "/dealer/option-trim-code",
  DMS_OPTION_TRIM_CODE: "config/dealer/option-model-name",
  CUSTOMER_CHECK: "customer/prequalification_customer",
  DEALER_PREFERENCE_BY_DEALER_CODE: "/dealer/preference-by-dealer-code",
  DMS_DEALER_PREFERENCE_BY_DEALER_CODE: "config/dealer/preference-by-dealer-code",
  EP_GET_STATES: "state",
  EP_DMS_GET_STATES: "config/state",
  EP_GET_COUNTRIES: "countries",
  EP_GET_DMS_COUNTRIES: "config/countries",
  VEHICLE_BY_TRIM_CODE: "configure/assets-by-trim-code",
  DMS_VEHICLE_BY_TRIM_CODE: "dms/configure/assets-by-trim-code",
  GET_ALL_PROGRAMS: "programs/all/",
  GET_DMS_ALL_PROGRAMS: "config/programs/all/",
  CALCULATE_ORDER_QUOTATION: "quote/calculation/V2",
  DMS_CALCULATE_ORDER_QUOTATION: "dms/quote/calculation/V2",
  CUSTOMER_EMAIL_CHECK: "check-customer-by-email",
  EP_GET_CUSTOMER: "customer",
  EP_UPDATE_CONTACT_DETAILS: "customer",
  EP_GET_CURRENCY: "currencies",
  EP_GET_DMS_CURRENCY: "config/currencies",
  PRE_QUALIFICATION: "customer/prequalification_check",
  EP_GET_COMPANY_INTEGRATIONS: "integration/company",
  INTEGRATION_BY_PROVIDER_NAME: "integration/by_provider_name",
  DMS_INTEGRATION_BY_PROVIDER_NAME: "dms/integration/by_provider_name",
  INTEGRATION_CONFIGURATION_BY_PROVIDER_NAME: "integration/configuration",
  FNI_PRODUCT: "/financial-insurances/filter",
  DMS_FNI_PRODUCT: "config/financial-insurances/filter",
  GET_RATES: "get-rates",
  CONFIG_GET_RATES: "config/get-rates",
  VERIFY_ADDRESS: "api/verify-address",
  DEALER_PROFILE: "dealer/profile",
  DMS_DEALER_PROFILE: "config/dealer/profile",
  EP_GET_ORDER_INFORMATION: "order/order_information",
  GET_CUSTOMER_BY_EMAIL: "/customer-by-email",
  SAVE_ORDER_QUOTATION: "/configure/save-quotation",
  GET_DEALER_FEE_BY_STATE: "/dealer/fee/dealer-code",
  GET_DMS_DEALER_FEE_BY_STATE: "config/dealer/fee/dealer-code",
  CONVERT_QUOTATION_TO_APPLICATION: "/configure/quotation-to-order",
  CONFIGURE_QUOTATION: "configure/quotation",
  LANGUAGE_CHANGE:"config/get-tenant-configured-placeholders",
  CUSTOMER_LICENSE_INFO: "customer/license-info",
  GET_ALL_LANGUAGE:"config/get-active-languages",
  LICENSE_VERIFICATION: "customer/license-verification",
  CALCULATE_NFA: "quote/calculate-nfa",
  DMS_CALCULATE_NFA: "dms/quote/calculate-nfa",
  EP_SAVE_EMPLOYMENT_DETAILS: "customer/employment-info",
  EP_UPDATE_EMPLOYMENT_DETAILS: "customer/employment-info",
  EP_GET_EMPLOYMENT_INFO: "customer/employment-info",
  EP_GET_PERSONAL_FINANCE: "customer/personal-finance",
  MAKE_STRIPE_PAYMENT: "create-payment/",
  GET_STRIPE_PAYMENT: "get-payment-methods",
  EP_PERSONAL_FINANCE: "customer/personal-finance",
  EP_SUBMIT_ORDER: "submit-deal",
  EP_RE_SUBMIT_ORDER: "order/re-submit/",
  EP_UPDATE_PERSONAL_FINANCE_DETAILS: "customer/personal-finance",
  EP_CREATE_CUSTOMER_DETAILS: "customer",
  EP_APPOINTMENT: "/configure/appointment",
  EP_UPDATE_ORDER_STATUS: "configure/order/update-status",
  GET_REFERENCE_BY_DEALER_CODE: "inventory/reference-data-by-dealer-code",
  UPDATE_ORDER_STATUS: "/configure/order/update-status",
  EP_GET_LENDER_CONFIGURATION_RADIUS: "/radius",
  EP_GET_DMS_CONFIGURATION_RADIUS: "config/radius",
  GET_DEALER_DISTANCE_BY_CUSTOMER: "/dealer/all-dealer-distance",
  GET_DMS_DEALER_DISTANCE_BY_CUSTOMER: "dms/dealer/all-dealer-distance",
  GET_CHECKLIST_QUESTIONS: "config/get_event_associated_checklist/",
  GET_TRADE_IN_PROVIDER_LOGO: "configbucket/providers",
  CHECK_VEHICLE_ESTIMATION_PROVIDER: "/integration/by_provider_name/",
  GET_DMS_VEHICLE_DETAILS_BY_VIN: "dms/get-vehicle-detail-by-vin/",
  EP_GET_TRADE_IN_YEAR: "/get-trade-in-year/",
  EP_GET_DMS_TRADE_IN_YEAR: "dms/get-trade-in-year/",
  EP_GET_TRADE_IN_MAKE: "/get-trade-in-make/",
  EP_GET_DMS_TRADE_IN_MAKE: "dms/get-trade-in-make/",
  EP_GET_TRADE_IN_MODEL: "/get-trade-in-model/",
  EP_GET_DMS_TRADE_IN_MODEL: "dms/get-trade-in-model/",
  EP_GET_TRADE_IN_TRIM: "/get-trade-in-trim/",
  EP_GET_DMS_TRADE_IN_TRIM: "dms/get-trade-in-trim/",
  EP_GET_TRADE_IN_VEHICLE_DETAIL: "/get-trade-in-vehicle_detail/",
  EP_GET_DMS_TRADE_IN_VEHICLE_DETAIL: "dms/get-trade-in-vehicle_detail/",
  EP_GET_TRADE_IN_VEHICLE_VALUE: "/get-trade-in-vehicle_value/",
  EP_GET_DMS_TRADE_IN_VEHICLE_VALUE: "dms/get-trade-in-vehicle_value/",
  EP_GET_TRADE_IN_VEHICLE_OPTIONS: "/get-tradein-options/",
  EP_GET_DMS_TRADE_IN_VEHICLE_OPTIONS: "dms/get-tradein-options/",
  GET_WORKQUEUE: "/configure/workqueue/search",
  EP_DMS_UPDATE_VEHICLE_DETAIL: "config/vehicle-detail",
  EP_GET_PLAID_TOKEN: "/plaid-link-token/",
  UMS_DMS_CALL: "/signup/dms",
  EP_EVENT_ANALYTICS: "/analytics/event_analytic",
  GET_PAYMENT_ALLOWED_LIMIT: "/config/dealer/payment-detail_by_dealer_code",
  EP_CREDIT_RESPONSE: "order/credit-decision",
  EP_UPLOAD_DOCUMENT: "customer/document",
  EP_UPLOAD_DOCUMENT_by_CUSTOMER_REFERENCE: "customer/documents",
  EP_STIPULATION: "stipulation/stipulation-by-lender",
  GET_TRADE_IN_HISTORY: "get-trade_in_history",
  GET_ALL_DOCUMENT_PACKAGES: "/configure/all-document-packages-by-reference-id",
  GET_PRE_SIGNED_URL: "configure/document-pre-signed-url",
  CUSTOMER_SIGNATURE: "customer/signature",
  SIGN_DOCUMENT_BY_ROLE: "configure/by-role",
  GET_LENDER_BY_SLUG: "config/company/by-tenant-slug/",
  GET_TENANT_THEME_BY_EMAIL: "config/theme-builder/",
  EP_LEAD: "lead",
  EP_DMS_LEAD: "dms/lead",
  CHECK_DMS_CUSTOMER: "check-dms-customer",
  UPDATE_DMS_CUSTOMER: "update-dms-customer",
  DEFAULT_LENDER_CONFIGURATION: "config/default/lender",
  ADD_WAY_INSURANCE: "insurance/create",
  ADD_TRANSACTION_ID: "insurance/add-insurance",
  INSURANCE_STATUS: "insurance/status",
  LENDER_CONFIG: "config/lender",
  ORDER_DOCUMENT: "order-document",
  ORDER_DOCUMENT_FNI: "/order-document-fni",
  CONTRACT_DOCUMENT: "/contract-document",
  UPDATE_FINANCIAL_INDICATOR:"configure/update-financial-indicator",
  EP_FNI_MONTHLY_IMPACT: "dms/quote/calculation/monthlyImpact",
  GET_CONFIG_DEALER_TRADIN: "config/dealer/tradein-by-dealer-code",
  GET_ORDER_STATUS: "/order/get-order-status",
  INSURANCE_COMPANIES: "/insurance-companies"
};

