import { useMutation, useQuery } from "react-query";
import { InsuranceManagement } from "../apis";
import { QueryKeys } from "@constants";

export const useAddWayInsurance = (): any => {
  return useMutation<any>((body: any) => {
    return InsuranceManagement.addWayInsurance(body);
  });
};

export const useAddTransactionId = (): any => {
  return useMutation<any>((body: any) => {
    return InsuranceManagement.addTransactionId(body);
  });
};

export const useGetInsuranceCompanies = (companyId: number): any => {
  return useQuery(
    QueryKeys.GET_INSURANCE_COMPANIES,
    () => {
      return  InsuranceManagement.getInsuranceCompanies({ companyId })
    },
    { enabled: !!companyId }
  )
};
