import { IFniProduct } from "@interfaces";
import { useTheme } from "@mui/material";
import { Box, Grid, Select, Typography } from "@ntpkunity/controls";
import React, { FC, useState } from "react";
import { FniProductDetailCard } from "./fni-product-detail-card";
import { useOrderContext } from 'pages'

export const FniProductCard: FC<{
  products: Array<IFniProduct>;
  rates: any;
}> = ({ products, rates }) => {
  const theme = useTheme();
  const { state } = useOrderContext()

  return (
    <>
      <Grid
        theme={theme}
        container
        item
        spacing={{ xs: 3, md: 2 }}
        alignItems={"stretch"}
      >
        <Grid theme={theme} item xs={12} pt={0} mb={1}>
          <Box theme={theme} className="custom-dropdown">
            <Typography
              theme={theme}
              className="text-muted"
              variant="body2"
              component={"span"}
              display={"block"}
            >
              Category:
            </Typography>
            <Select
              theme={theme}
              disablePortal={false}
              items={[
                { text: "All", value: "all" },
              ]}
              onChange={()=>{}}
              value={'All'}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          </Box>
        </Grid>
        {products?.map((item, index) => (
          <Grid theme={theme} item xs={12} lg={6} key={index}>
            <FniProductDetailCard fniProduct={item} rates={rates} selectedTerm={state?.order?.contract_term} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
