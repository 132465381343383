import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const InsuranceFormWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.insurance-form-wrap': {
    '.insurance-file-upload': {
        '.cj-file-uploader-wrap': {
            '.u-custom-file-upload': {
                marginBottom: 0,
                '.root-drag-file': {
                    height: 243
                }
            }
        }
    },
    '.u-custom-autocomplete': {
      marginBottom: 0,
    },
    '.insurance-address': {
      '.MuiGrid-container': {
        '.MuiGrid-item': {
          '&:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6)': {
            maxWidth: '50%',
            flexBasis: '50%'
          }
        }
      }
    }
  }
}))
