import { useTheme, InputAdornment, IconButton } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Stack, Textbox } from '@components'
import {FC, useEffect, useState, useReducer} from 'react'
import { Box, Button, Grid, Icon, Input, Select, Typography, Switch, CircleLoader, Snackbar } from '@ntpkunity/controls'
import { useUpdateCustomerContact, useCreateCustomerContact } from 'hooks'
import { validation, isEmpty } from '../../../helpers/index'
import { useAppContext} from '@app/context-provider'
import { SnackbarUtility } from '@utilities'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { FinanceTab } from '@constants'
import {useFormContext} from "../../../pages/(order-management)/order/form-context-provider";
import { TaggingClasses } from 'constants/enums'
import {prefixOptions,messages} from '../../../helpers/financing_data'

interface ICustomerContractData {
    prefix: string
    first_name: string
    middle_name: string
    last_name: string
    suffix: string
    ssn: string
    home_number: string
    mobile_number: string
    email: string
}

export const ContactDetailsComponent: FC<{
    setIsContact?: (value: boolean) => void
    customerReferenceId?: any
    setIsContactErrros?: (value: boolean) => void
    contactData?: any
    setTab: (value: number) => void
    form:any
}> = ({ customerReferenceId, contactData, setIsContact, setTab, setIsContactErrros, form}) => {

    const theme = useTheme()
    const { dispatch: pageDispatch } = useJourneyLayoutContext()
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
    const { mutate: updateCustomerByReferenceId, isLoading: updateCustomerLoading } = useUpdateCustomerContact()
    const { mutate: createCustomerByReferenceId } = useCreateCustomerContact()
    const customer_email = JSON.parse(localStorage.getItem('settings'))
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    const { formStates, resetFormState } = useFormContext()
    const [showSSN, setShowSSN] = useState(!!contactData?.customer_profile?.ssn)
        const {
        control,
        formState: { errors, isDirty },
        setValue,
        handleSubmit,
        setError,getValues
    } = form
    useEffect(() => {
        if (contactData?.ssn) {
            setShowSSN(true)
        }
    }, [contactData?.ssn])
    const handleShowSSN  = () => {
        setShowSSN(!showSSN)
    }
    const onSubmit = async (data: any) => {
        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Saving personal details'))
        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
        const {
            prefix,
            first_name,
            middle_name,
            last_name,
            suffix,
            ssn,
            home_number,
            mobile_number
        } = data
        const payload = {
            customer_profile: {
                prefix,
                first_name,
                middle_name,
                last_name,
                suffix,
                ssn,
                home_number,
                mobile_number,
                updated_by: customer_email?.email
            }
        }

        if (isEmpty(contactData)) {
            createCustomerByReferenceId(
                {
                    ...payload,
                    reference_id: customerReferenceId
                },
                {
                    onSuccess() {
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Personal details Saved'))
                        setIsContact?.(true)
                        setIsContactErrros?.(true)
                        resetFormState('ContactDetails')
                        setTab(FinanceTab['Residence-Details'])
                    }
                }
            )
        }
        else if (isDirty) {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Personal details Updating...'))
            updateCustomerByReferenceId(
                {
                    ...payload,
                    reference_id: customerReferenceId
                },
                {
                    onSuccess() {
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Personal details Updated'))
                        setIsContact?.(true)
                        setIsContactErrros?.(true)
                        resetFormState('ContactDetails')
                        setTab(FinanceTab['Residence-Details'])
                        pageDispatch({
                            type: pagesActionType.UPDATE_PERSONAL_DETAILS,
                            payload: {
                                success: true,
                                error: false
                            }
                        })
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Contract Details Saved successfully'))
                        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
                    },
                    onError(error) {
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Error while updating'))
                        if(error?.detail?.message?.includes('ssn')){
                            setError(messages.name.ssn, {
                                message: messages.validation.ssn
                            })
                        }
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Contract Details Fail.'))
                        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
                      }
                }
            )
        }
        else {
            pageDispatch({
                type: pagesActionType.UPDATE_PERSONAL_DETAILS,
                payload: {
                    success: true,
                    error: false
                }
            })
        }
    }
    useEffect(() => {
        if (Object.keys(errors).length) {
            pageDispatch({
                type: pagesActionType.UPDATE_PERSONAL_DETAILS,
                payload: {
                    success: false,
                    error: true
                }
            })
        }
    }, [errors])
    return (
        <>
            <Stack marginTopXs={3} className='scroll-horizontal'>
                <Typography theme={theme} component={'h4'} variant='h4'>{PLACEHOLDERS.FINANCING_CUSTOMER_DETAILS_TEXT}</Typography>
                <Grid theme={theme} container item columnSpacing={2} rowSpacing={3} mt={0}>
                    <Grid theme={theme} item xs={6} sm={2.4} md={6} lg={2.4}>
                        <Controller
                            name={messages.name.prefix as any}
                            defaultValue={''}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    theme={theme}
                                    label={PLACEHOLDERS.FINANCING_PREFIX_OPTIONAL}
                                    items={prefixOptions}
                                    sxProps={''}
                                    value={value}
                                    selectError={(errors as any)?.customer_profile?.prefix?.message?.toString()}
                                    onChange={(e: any) => {
                                        onChange(e)
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid theme={theme} item xs={6} sm={2.4} md={6} lg={2.4}>
                        <Textbox>
                            <Controller
                                name={messages.name.firstName as any}
                                control={control}
                                rules={validation(PLACEHOLDERS.FINANCING_FIRST_NAME_FIELD_LABEL, true)}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                        label={PLACEHOLDERS.FINANCING_FIRST_NAME_FIELD_LABEL}
                                        type="text"
                                        {...field}
                                        error={errors?.first_name?.message.toString()}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={6} sm={2.4} md={6} lg={2.4}>
                        <Textbox>
                            <Controller
                                name={messages.name.middle_name as any}
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                        label={PLACEHOLDERS.FINANCING_MIDDLE_NAME_OPTIONAL_TEXT}
                                        type="text"
                                        {...field}

                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={6} sm={2.4} md={6} lg={2.4}>
                        <Textbox>
                            <Controller
                                name={messages.name.lastName as any}
                                control={control}
                                rules={validation(PLACEHOLDERS.FINANCING_LAST_NAME_FIELD_LABEL, true)}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                        label={PLACEHOLDERS.FINANCING_LAST_NAME_FIELD_LABEL}
                                        type="text"
                                        {...field}
                                        error={errors?.last_name?.message.toString()}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={6} sm={2.4} md={12} lg={2.4}>
                        <Textbox>
                            <Controller
                                name={messages.name.suffix as any}
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                        label={PLACEHOLDERS.FINANCING_SUFFIX_OPTIONAL_FIELD_LABEL}
                                        type="text"
                                        {...field}
                                        error={errors?.suffix?.message.toString()}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={12} sm={6} md={12} lg={6}>
                        <Textbox>
                            <Controller
                                name={messages.name.ssn as any}
                                control={control}
                                rules={{
                                    ...validation(PLACEHOLDERS.FINANCING_SOCIAL_SECURITY_NUMBER_FIELD_LABEL, true),
                                    minLength: {
                                        value: 9,
                                        message: PLACEHOLDERS.FINANCING_SOCIAL_SECURITY_NUMBER_FORMAT,
                                    },
                                }}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        masking
                                        format="### - ## - ####"
                                        {...field}
                                        placeholder={'000 - 00 - 0000'}
                                        label={PLACEHOLDERS.FINANCING_SOCIAL_SECURITY_NUMBER_FIELD_LABEL}
                                        type={(showSSN) ? 'password' : 'text'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label={'eye icon'} onClick={handleShowSSN}>
                                                    {showSSN
                                                        ? <Icon name='IcView' />
                                                        : <Icon name='IcViewOff' />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        helperText={PLACEHOLDERS.FINANCING_PRIVACY_POLICY_SSN_ENCRYPTION}
                                        error={errors?.ssn?.message}
                                        maskNumeric={true}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setShowSSN(false);
                                        }}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={12} sm={6} md={6} lg={6}>
                        <Textbox>
                            <Controller
                                name={messages.name.home_number as any}
                                defaultValue={''}
                                control={control}
                                rules={{
                                    minLength: {
                                      value: 10,
                                      message: PLACEHOLDERS.FINANCING_PHONE_NUMBER_LENGTH,
                                    },
                                  }}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        maskNumeric
                                        masking
                                        format="### - ### - ####"
                                        placeholder={'000 - 000 - 0000'}
                                        label={PLACEHOLDERS.FINANCING_HOME_PHONE_NUMBER_OPTIONAL_FIELD_LABEL}
                                        type="text"
                                        {...field}
                                        error={errors?.home_number?.message.toString()}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={12} sm={6} md={6} lg={6}>
                        <Textbox>
                            <Controller
                                name={messages.name.mobile_number as any}
                                defaultValue={''}
                                control={control}
                                rules={{...validation(PLACEHOLDERS.FINANCING_MOBILE_PHONE_NUMBER_FIELD_LABEL, true),
                                    minLength: {
                                        value: 10,
                                        message: PLACEHOLDERS.FINANCING_PHONE_NUMBER_LENGTH,
                                      },
                                }}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        maskNumeric
                                        masking
                                        format="### - ### - ####"
                                        placeholder={'000 - 000 - 0000'}
                                        label={PLACEHOLDERS.FINANCING_MOBILE_PHONE_NUMBER_FIELD_LABEL}
                                        type="text"
                                        {...field}
                                        error={errors?.mobile_number?.message.toString()}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={12} sm={6} md={6} lg={6}>
                        <Textbox>
                            <Controller
                                name={messages.name.email as any}
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                        label={PLACEHOLDERS.FINANCING_EMAIL_TEXT}
                                        type="text"
                                        {...field}
                                        disabled={true}
                                        error={errors?.email?.message.toString()}

                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                </Grid>
            </Stack>

            {formStates?.['ContactDetails']?.isDirty? (
                <Box theme={theme} mt={3}>
                    <Button theme={theme} primary
                        className={`${TaggingClasses.FINANCING}-contact-details-save`}
                        text={
                            <>
                                {updateCustomerLoading && <CircleLoader theme={theme} size="xs" />}
                                {PLACEHOLDERS.FINANCING_SAVE_AND_CONTINUE}
                            </>
                        }
                        disabled={updateCustomerLoading}
                        fullWidth
                        type='submit'
                        onClick={handleSubmit(onSubmit)}
                        />
                </Box>
            ) : null}
        <Snackbar
            theme={theme}
            message={<Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px">
            <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
        </Box> }
            open={snackbarState.open}
            onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
        </>
    )
};
