import {
  pagesActionType,
  useJourneyLayoutContext
} from "./journey-layout-context-provider";
import { JourneyLayout } from "@layouts";
import { Typography } from "@ntpkunity/controls";
import { JourneyFooter, PaymentBreakDownPopup } from "@libraries";
import { getNextTabTitle, getTabsClass } from "@helpers";
import {Navigation, QueryKeys, Tabs} from "@constants";
import {
  Contracting,
  InsurancePage,
  PaymentWithStripePage, SchedulePage, useOrderContext
} from "../../pages";
import {useEffect, useState} from "react";
import { useTheme } from "@mui/material";
import { updateCollapseState, useAppContext } from "@app/context-provider";
import {useGetStripePaymentDetails} from "../../hooks/stripe-payments";
import {useParams} from "react-router-dom";
import {useScrollOnTop} from "../../hooks/order-management";
import {ContractingTypes, DocumentPackageTypes, EstimateProvider, TaggingClasses} from "constants/enums";
import {useGetAllDocumentPackages} from "@hooks";
import {isEmpty} from "lodash";
import {useGetConfiguration} from "../../hooks/trade-in-management";
import {LenderIntegrationType} from "../../constants/providers";
import {IDealerProfile} from "@interfaces";
import {useQueryClient} from "react-query";

export const PostOrderJourneyLayoutComponent = ({ vehicle,
  showPopup,
  orderTabs,
  nextTab,
  handleLinkClick,
  handleCloseDialog,
  onNextTabClick,
  children }) => {
  const theme = useTheme()
  const { state: appState, } = useAppContext()
  const { state: orderState } = useOrderContext()
  const [activeTab, setActivetab] = useState(orderTabs?.[0] || Tabs?.INSURANCE)
  const queryClient = useQueryClient();
  const { state: pageState, dispatch: pageDispatch } = useJourneyLayoutContext()
  const { orderId } = useParams()
  const dealer_profile = JSON.parse(localStorage.getItem("dealer_information"))?.dealer
  const { data: stripeConnect, isLoading: stripeConnectLoading } = useGetConfiguration(dealer_profile?.dealer_code,LenderIntegrationType.PAYMENT_PROCESSING,EstimateProvider.STRIPE, appState?.tenant, appState?.slug)
  const dealerProfile: IDealerProfile = queryClient.getQueryData([
    QueryKeys.DEALER_PROFILE, dealer_profile?.dealer_code]
  )
  const {data: paymentDetails } = useGetStripePaymentDetails(orderState?.order?.reference_number ? orderState?.order.reference_number: orderId, stripeConnect, dealerProfile )
  const { data: documentPackages } = useGetAllDocumentPackages({
      referenceId: orderState?.order?.reference_id,
      orderId: orderState?.order?.reference_number,
      documentType: ['Contract', 'General']
  })
  const PLACEHOLDERS = appState.language.placeholders;
  const { dispatch } = useAppContext();

  function allDocumentsSignedByApplicant(contractsData: any): boolean {
  if (isEmpty(contractsData)) {
    return false;
  }

  for (const contract of contractsData) {
    if (contract.type === "Contract") {
      const allSigned = contract.documents.every(doc =>
        doc.signatures.some(
          signature => signature.signer === "Applicant" && signature.status === "Signed"
        )
      );

      if (!allSigned) {
        return false;
      }
    }
  }
  return true;
}

  const isContractSigned = allDocumentsSignedByApplicant(documentPackages)
  const hasContractDocuments = documentPackages?.some(
    (item) => item.type === DocumentPackageTypes.CONTRACT && item?.documents?.length > 0
  );

  const isTotalPaidMatching = paymentDetails?.total_paid_amount === Math.round(orderState?.order?.due_at_signing * 100) / 100;

  useScrollOnTop(activeTab)
  useEffect(() => {
    if(isTotalPaidMatching || appState?.default_lender?.contracting_type == ContractingTypes.NOT_REQUIRED) {
            setActivetab(Tabs.SCHEDULE)
    }
    else if(isContractSigned){
      setActivetab(Tabs.STRIPE_PAYMENTS)
    }
    else if(hasContractDocuments){
      setActivetab(Tabs.CONTRACTING)
    }
    else {
      setActivetab(Tabs.INSURANCE)
    }
  }, [isTotalPaidMatching, isContractSigned, hasContractDocuments, appState?.default_lender]);

  useEffect(() => {
    if (paymentDetails?.total_paid_amount) {

      const dueAtSigning = Math.round(orderState?.order?.due_at_signing * 100 ) / 100 ;
      pageDispatch({
        type: pagesActionType.UPDATE_STRIPE_PAYMENT,
        payload: {
          success: paymentDetails.total_paid_amount == dueAtSigning,
          error: paymentDetails.total_paid_amount != dueAtSigning,
        },
      });
    }
  }, [paymentDetails, orderState]);

  const getActiveTab = (tab: string) => {
    switch (tab) {
      case Tabs.STRIPE_PAYMENTS:
        return <PaymentWithStripePage />
      case Tabs.CONTRACTING:
        return <Contracting />
      case Tabs.SCHEDULE:
        return <SchedulePage />
      case Tabs.INSURANCE:
        return <InsurancePage />
      default:
        return children
    }
  }

  const getActiveTabTitle = () => {
    switch (activeTab) {
      case Tabs.STRIPE_PAYMENTS:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE;
      case Tabs.CONTRACTING:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_CONTRACTING_TITLE
      case Tabs.SCHEDULE:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE
      case Tabs.INSURANCE:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE
    }
  }

  const onTabClick = (tab, setState) => {
    setActivetab(tab),
    setState(true)
    dispatch(updateCollapseState());
  }

  const createOrderStepObject = (tab: string,setState:(value: boolean) => void) => {

    switch (tab) {
      case Tabs.STRIPE_PAYMENTS:
        return {
          class: TaggingClasses.PAYMENT,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE,
          subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
          success: activeTab !== Tabs.STRIPE_PAYMENTS ? pageState?.stripePayment.success : false,
          error: activeTab !== Tabs.STRIPE_PAYMENTS ? pageState?.stripePayment.error : false,
          isActive: activeTab === Tabs.STRIPE_PAYMENTS,
          onClick: () => {
                  if(isContractSigned) {
                    onTabClick(Tabs.STRIPE_PAYMENTS, setState)
                }
          }
        }
      case Tabs.CONTRACTING:
        return {
          class: TaggingClasses.CONTRACTING,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_CONTRACTING_TITLE,
          subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
          success: activeTab !== Tabs.CONTRACTING ? pageState?.contracting.success : false,
          error: activeTab !== Tabs.CONTRACTING ? pageState?.contracting.error : false,
          isActive: activeTab === Tabs.CONTRACTING,
          onClick: () => {
                if(hasContractDocuments) {
                onTabClick(Tabs.CONTRACTING, setState)
              }
          }
        }
      case Tabs.SCHEDULE:
        return {
          class: TaggingClasses.SCHEDULE,
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE,
          subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
          success: activeTab !== Tabs.SCHEDULE ? pageState?.appointment.success : false,
          error: activeTab !== Tabs.SCHEDULE ? pageState?.appointment.error : false,
          isActive: activeTab === Tabs.SCHEDULE,
          onClick: () => {
                if(isTotalPaidMatching || appState?.default_lender?.contracting_type == ContractingTypes.NOT_REQUIRED ) {
                onTabClick(Tabs.SCHEDULE, setState)
              }
          }
        }
      case Tabs.INSURANCE:
        return {
            class: TaggingClasses.INSURANCE,
            title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE,
            subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
            isActive: activeTab === Tabs.INSURANCE,
            success: activeTab !== Tabs.INSURANCE ? pageState.insurance.success : false,
            error: activeTab !== Tabs.INSURANCE ? pageState.insurance.error : false,
            onClick: () => onTabClick(Tabs.INSURANCE, setState)
        }
    }
  }
  const isContractingTabActive = activeTab === Tabs.CONTRACTING;
  const isInsuranceTabActive = activeTab === Tabs.INSURANCE;
  const isStripePaymentsTabActive = activeTab === Tabs.STRIPE_PAYMENTS;



  const isActive =
    (isContractingTabActive && isContractSigned) ||
    (isInsuranceTabActive && orderState?.order?.insurance?.identifier) ||
    (isStripePaymentsTabActive && isTotalPaidMatching);


  return (
    <JourneyLayout
      orderTabs={orderTabs}
      nextTab={nextTab}
      getActiveTab={getActiveTab}
      getActiveTabTitle={getActiveTabTitle}
      createOrderStepObject={createOrderStepObject}
      activeTab={activeTab}
      setActivetab={setActivetab}

    >
      <Typography theme={theme} className='text-muted disclaimer' variant='caption' component={'small'} display={'block'} textAlign={'center'} mt={3}>
        {PLACEHOLDERS.PRICE_CHANGE_DISCLAIMER}
      </Typography>
      {
        isActive ?
       <JourneyFooter
        title={PLACEHOLDERS.FOOTER_TITLE}
        subTitle={`${(orderState?.order.estimated_monthly_payment )?.toLocaleString("en-US", {
          style: "currency",
          currency: appState?.default_currency_code || 'USD',
        })}/${PLACEHOLDERS.FOOTER_SUBTITLE}`}
        linkText={PLACEHOLDERS.FOOTER_LINK_TEXT}
        onLinkClick={handleLinkClick}
        buttonText={getNextTabTitle(orderTabs, orderState.activeTab, PLACEHOLDERS) !== '' ? `${PLACEHOLDERS.NEXT_TEXT}: ${getNextTabTitle(orderTabs, orderState.activeTab, PLACEHOLDERS)}` : ''}
        onButtonClick={onNextTabClick}
        taggingClass={getTabsClass(orderTabs,activeTab)}
      />: null
      }

      {showPopup && <PaymentBreakDownPopup onCloseDialog={handleCloseDialog} order_info={orderState?.order} />}
    </JourneyLayout>
  )

}